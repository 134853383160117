@use "../../../styles/variables";

.stories-call-to-action {
  position: relative;
  background-color: variables.$projectBlue;
  color: white;
  height: auto !important;

  .content {
    position: static;
    width: 100%;
    height: 95%;
    padding: 8rem 0;
    z-index: 2;

    h3 {
      &.title {
        margin-bottom: 10rem;

        &.short {
          margin-bottom: 12rem;
        }
      }
    }

    .large {
      font-size: 3rem;
      line-height: 4.2rem;
      max-width: 75rem;

      a {
        color: variables.$projectWhite;
        text-decoration: none;

        &:focus,
        &:hover {
          background-image: linear-gradient(
            90deg,
            variables.$projectInteractiveNavy,
            variables.$projectInteractiveNavy
          );
          background-repeat: no-repeat;
          background-position: 0 100%;
          background-size: 100% 0.1rem;
          padding-bottom: 0.4rem;
        }
      }
    }

    .bottom {
      margin-top: 6rem;
      display: flex;

      > div {
        &:first-child {
          position: relative;
          display: flex;
          align-items: center;
        }

        &:last-child {
          margin-left: auto;
        }
      }
    }

    :global(.cta) {
      width: auto;
      margin-right: 4rem;
    }

    :global(.message-wrapper) {
      position: absolute;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);

      :global(.message) {
        right: 0;

        &::after {
          bottom: -1.9rem;
        }
      }
    }
  }

  @media #{variables.$tablet} {
    .content {
      h3 {
        &.title {
          margin-bottom: 8rem;

          &.short {
            margin-bottom: 8rem;
          }
        }
      }
    }
  }

  @media #{variables.$mobile}, #{variables.$tablet-portrait} {
    .content {
      padding: 0;

      h3 {
        &.title {
          margin-bottom: 6rem;

          &.short {
            margin-bottom: 4rem;
          }
        }
      }

      .large {
        font-size: 2rem;
        line-height: 3rem;
      }

      .bottom {
        display: block;

        > div {
          &:first-child {
            flex-direction: column;
          }
        }

        :global(.cta) {
          margin-right: 0;
          margin-bottom: 3rem;

          &:not(:global(.tertiary)) {
            width: 100%;
          }
        }

        :global(.message-wrapper) {
          bottom: 2rem;
          top: auto;
        }
      }

      .social-sharing {
        display: none;
      }
    }
  }
}
