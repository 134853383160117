@use "../../styles/variables";

// COMPONENT - C211 Summary

// DEFAULT CLASS - .summary

// CLASS VARIANTS -

// WIDE WEB

.summary {
  background: variables.$projectWhite;
  color: variables.$projectBlue;
  margin: 0 -2rem;
  padding: 8rem 2rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    max-width: 66.66667%;
  }

  h2 {
    @include variables.line-height(60, 48);
    font-size: 4.8rem;
    margin-bottom: 2.4rem;
  }

  ul {
    display: flex;
    margin-left: 0;
    line-height: 2.2rem;

    li {
      flex-basis: 25%;
      list-style-type: none;
      min-width: 25%;
      padding-right: 2rem;
      padding-left: 2.5rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid variables.$projectRed;
        border-radius: 50%;
        left: 0;
        top: 0.5rem;
      }

      a {
        color: variables.$projectBlue;
        text-decoration: none;
        font-weight: normal;

        &:focus,
        &:hover {
          background-image: linear-gradient(
            90deg,
            variables.$projectInteractiveNavy,
            variables.$projectInteractiveNavy
          );
          background-repeat: no-repeat;
          background-position: 0 100%;
          background-size: 100% 0.1rem;
          padding-bottom: 0.4rem;
        }
      }
    }
  }

  h3 {
    @include variables.line-height(38, 30);
    font-size: 3rem;
    margin-bottom: 2.4rem;
  }

  :global(.cta) {
    margin-top: 2.3rem;
    width: auto;
  }

  .base4 & {
    padding: 4rem;
    margin: 0 0 2rem -2rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      max-width: none;
    }

    h2,
    h3 {
      @include variables.line-height(36, 28);
      font-size: 2.8rem;
    }

    ul {
      display: block;
    }

    li {
      margin-bottom: 2.4rem;
      padding-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    :global(.cta) {
      width: 100%;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .summary {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .summary {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .summary {
    padding: 4rem 2rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      max-width: none;
    }

    h2,
    h3 {
      @include variables.line-height(36, 28);
      font-size: 2.8rem;
    }

    ul {
      display: block;
    }

    li {
      margin-bottom: 2.4rem;
      padding-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    :global(.cta) {
      width: 100%;
    }

    :global(.base4) & {
      padding: 3rem;
      margin: 2rem -2rem;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .summary {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .summary {
  }
}
