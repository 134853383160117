@use "../../styles/variables.scss";
// COMPONENT - Download Brochure Form

// DEFAULT CLASS - .brochure-form

// CLASS VARIANTS -

// WIDE WEB
.component {
  margin-top: 4rem;
}

.failure-section {
  margin: 5rem 0;
}

.back {
  max-width: 300px;
}

.spinner {
  display: flex;
  justify-content: center;
  max-width: 66.66667%;
  margin-bottom: 50px;
}

.brochure-form {
  .fields-floated {
    .field-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.6rem;

      label {
        flex-basis: 25%;
        max-width: 25%;
        color: variables.$projectBlue;
        font-family: var(--lbs-base-font-family), sans-serif;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 700;
        padding-right: 1rem;

        + p {
          flex-basis: 75%;
          max-width: 75%;
          margin-bottom: 0;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .brochure-form {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .brochure-form {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .brochure-form {
    .fields-floated {
      .field-row {
        flex-direction: column;

        label {
          flex-basis: 100%;
          max-width: 100%;

          + p {
            flex-basis: 100%;
            max-width: 100%;
          }
        }

        &.edit-details {
          a {
            width: 100%;
          }
        }
      }
    }
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
    .brochure-form {
    }
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
    .brochure-form {
    }
  }
}
