@use "../../styles/variables.scss";

.socialIcon {
  width: 40px;
  height: 40px;
}

.site-footer {
  padding: 7.5rem 2rem;
  background-color: variables.$projectDarkBlue;
  color: white;

  a {
    @include variables.line-height(24, 16);
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    position: relative;

    &:not(.cta) {
      &:focus,
      &:hover {
        background-image: linear-gradient(
          90deg,
          variables.$projectInteractiveNavy,
          variables.$projectInteractiveNavy
        );
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: 100% 0.1rem;
        padding-bottom: 0.4rem;
      }
    }

    &.external {
      svg {
        fill: white;
        width: 2rem;
        position: absolute;
        margin-left: -3rem;
        margin-top: 2px;
      }
    }
  }

  .ctas {
    p {
      margin-bottom: 2rem;
    }

    .cta {
      + p {
        margin-top: 4.5rem;
      }
    }
  }

  .top {
    .large {
      margin-bottom: 3rem;
    }

    li {
      margin-bottom: 2rem;
    }
  }

  .social {
    margin-top: 4rem;
    padding-bottom: 6.5rem;
    border-bottom: 1px solid variables.$projectBone;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 3rem -1rem -2rem;

      li {
        margin: 0 1rem 2rem;

        a {
          display: block;
          opacity: 1;
          transition: opacity 0.15s ease-in-out;

          &:hover,
          &:focus {
            padding: 0;
            opacity: 0.5;
          }
        }
      }
    }

    svg {
      width: 4.3rem;
      height: 4.3rem;
      fill: variables.$projectBone;
    }

    a {
      &:focus,
      &:hover {
        background: none;

        svg {
          fill: variables.$projectInteractiveNavy;
        }
      }
    }
  }

  .bottom {
    margin-top: 5rem;

    > div {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          padding-right: 6rem;
          min-width: 20%;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .site-footer {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .site-footer {
  }
}
// MOBILE
@media #{variables.$mobile} {
  .site-footer {
    padding: 4rem 2rem;

    a {
      &:not(.cta) {
        font-size: 1.2rem;
      }

      &.external {
        svg {
          width: 1.5rem;
          margin-left: -2.5rem;
          margin-top: 5px;
        }
      }
    }

    .large {
      font-size: 1.6rem;
    }

    ul {
      margin-bottom: 3rem;

      li {
        margin-left: 2.5rem;
      }
    }

    .top {
      .large {
        margin-bottom: 1.5rem;
      }
    }

    .social {
      padding-bottom: 0;

      ul {
        margin: 1.5rem -1rem 1rem;
      }
    }

    .bottom {
      margin-top: 3rem;

      > div {
        ul {
          flex-direction: column;

          li {
            margin-left: 0;
          }
        }

        &:last-child {
          text-align: left;

          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
