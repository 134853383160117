@use "../../styles/variables.scss";

// COMPONENT - Modal EMBA Merge Form

// DEFAULT CLASS - .modal-emba-merge-form

//wrapper

.modal-emba-merge-form-wrapper {
  opacity: 1;
  pointer-events: all;
  transition: 0.4s ease;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
}

// Main container for the modal

.modal-emba-merge-form {
  background-color: variables.$projectBone;
  padding: 2rem;
  width: 400px;
  height: 350px;
  margin: 0 auto;
  text-align: center;
  font-family: var(--lbs-base-font-family), sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 6px 18px rgba(0.5, 0.5, 0.5, 0.5);
  box-sizing: border-box;
  z-index: 150;
}

// Close button styling
.close-button {
  display: block;
  position: absolute;
  text-decoration: none;
  top: 1px;
  right: 1px;
  color: black;
  height: 40px;
  width: 40px;
  font-size: 500%;
  text-align: center;
  line-height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

// Header styling
.modal-header {
  margin: 2rem 0;
  margin-left: 1rem;
  font-family: var(--lbs-base-font-family), sans-serif;
  font-size: 2.1rem;
  color: variables.$projectBlue;
  line-height: 1.6;
  font-weight: bold;
  text-align: left;
}

// Radio button group styling
.radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  label {
    display: flex;
    align-items: center;
    line-height: 1.5;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    color: #001e62 !important;
    cursor: pointer;
    padding-left: 8px;
    padding-top: 4px;

    &:hover {
      font-weight: bold;
    }

    input[type="radio"] {
      appearance: none;
      margin-right: 1rem;
      width: 20px;
      height: 20px;
      border: 1.5px solid #001e62;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      background: transparent;
      position: relative;

      &:checked {
        background: #001e62;
        border-color: #001e62;
        box-shadow: inset 0 0 0 1.8px #fff;
      }
    }
  }
}

// Error message styling
.error-message {
  position: relative;
  margin-bottom: -2.8rem;
  color: variables.$projectDarkRed;
  font-size: 1.2rem;
  line-height: 2rem;
  padding-left: 0.88rem;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 0.8rem;
    background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

// Divider styling
.divider {
  width: calc(100% + 4rem);
  height: 1px;
  background-color: #d7d2cb;
  margin: 4rem 0 0;
  position: relative;
  left: -2rem;
}

// Button and text wrapper styling
.btn-and-text-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.btn-and-text-wrapper button {
  width: 350px;
  max-width: 100%;
}
