@use "../../styles/variables";

// COMPONENT - C241 Upcoming programmes

// DEFAULT CLASS - .upcoming-programmes

// CLASS VARIANTS -

// WIDE WEB

.upcoming-programmes {
  margin: 3rem -2rem 0;
  background-color: variables.$projectBone;
  padding: 2rem 2rem 8rem;

  &.blue {
    background-color: variables.$projectBlue;

    .row {
      color: white;
    }

    p.label {
      color: variables.$projectSantaGray !important;
    }
  }

  p {
    &.large {
      margin-top: 1.5rem;
    }

    &.tag {
      color: variables.$projectGrey;
    }
  }

  :global(.swiper) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .programmes {
    margin-top: 5rem;
    position: relative;

    &::after {
      position: absolute;
      height: calc(100% + 8rem);
      width: 1px;
      right: -4.5rem;
      content: "";
      background-color: variables.$projectBlue;
      top: -4rem;
    }

    .programme {
      background-color: white;
      box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.14);
      padding: 2.5rem;
      position: relative;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 450px;

      .new {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #7d8cb3;
        padding: 0.9rem 1.3rem;
        font-size: 13px;
        color: white;
      }

      h3 {
        font-size: 3rem;

        a {
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        + p {
          margin-bottom: 8rem;
        }
      }

      .bottom {
        margin-top: auto;

        p {
          border-top: 1px solid variables.$projectBlue;
          padding-top: 1rem;
          margin-top: 2.5rem;
          font-size: 1.2rem;
        }

        ul {
          li {
            display: flex;

            i {
              margin-right: 1.5rem;
            }
          }
        }
      }

      i {
        display: block;

        svg {
          fill: variables.$projectInteractiveNavy;
          width: 2.4rem;
        }
      }
    }

    .next {
      position: absolute;
      right: -13rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // COMPACT WEB
  @media #{variables.$compact-web}, #{variables.$tablet} {
    .programmes {
      .next {
        right: -3rem;
      }

      &::after {
        right: -0.75rem;
      }
    }
  }

  @media all and (max-width: 1180px) {
    .programmes {
      .next {
        right: -2rem;
      }

      &::after {
        right: 0.5rem;
      }
    }
  }

  // MOBILE
  @media #{variables.$mobile} {
    margin: 3rem -2rem 0;
    padding: 2rem 2rem 7rem;

    .large {
      font-size: 1.6rem;
    }

    .programmes {
      .programme {
        margin: 0;
        padding: 2rem;
        box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.14);
      }

      &::after {
        content: none;
      }
    }
  }
}
