@use "../../styles/variables.scss";
// COMPONENT - C63.1 My profile

// DEFAULT CLASS - .my-profile

// CLASS VARIANTS -

// WIDE WEB

.component {
  margin-top: 4rem;
}

.tabs--my-profile {
  .tabs-item {
    padding: 0 1rem;

    a {
      text-align: center;
      padding: 1rem;
    }
  }
}

.my-profile {
  &.form {
    .section-heading,
    .sub-heading {
      color: variables.$projectBlue;
      font-family: var(--lbs-heading-font-family), serif;
      font-size: 3rem;
      line-height: 3.8rem;
      margin-bottom: 2.4rem;
    }

    .fields-floated {
      .field-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.6rem;

        &.edit-details {
          padding-bottom: 3.4rem;
          margin-bottom: 3.9rem;
          margin-top: 3.4rem;
          border-bottom: 0.1rem solid variables.$projectLightGrey;

          a {
            width: 22.1rem;
          }
        }

        label {
          flex-basis: 25%;
          max-width: 25%;
          color: variables.$projectBlue;
          font-family: var(--lbs-base-font-family), sans-serif;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 700;
          padding-right: 1rem;

          + p {
            flex-basis: 75%;
            max-width: 75%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .my-profile {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .my-profile {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .tabs--my-profile {
    .tabs-item {
      padding: 0;

      a {
        padding: 2rem;
        text-align: center;
      }
    }

    .js-tablist {
      border-bottom-width: 0;
    }

    .js-tablist-link {
      background-color: transparent;
      color: variables.$projectBlue;

      &[aria-selected="true"] {
        background-color: variables.$projectBlue;
        color: variables.$projectWhite;
        font-weight: 500;
        border-bottom-width: 0;
      }
    }
  }

  .my-profile {
    &.form {
      .fields-floated {
        .field-row {
          flex-direction: column;

          label {
            flex-basis: 100%;
            max-width: 100%;

            + p {
              flex-basis: 100%;
              max-width: 100%;
            }
          }

          &.edit-details {
            a {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .my-profile {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .my-profile {
  }
}
