@use "../../styles/variables.scss";

.profile-preferences {
  .section-title {
    color: variables.$projectBlue;
    font-family: var(--lbs-heading-font-family), serif;
    font-size: 3rem;
    line-height: 3.8rem;
  }

  section {
    + section {
      margin-top: 6rem;
    }
  }

  &:global(.form) {
    :global(.form-row) {
      :global(.checkbox) {
        margin-bottom: 2rem;
        margin-right: 0;
      }
    }
  }

  .button-wrapper {
    margin-top: 2.5rem;
    margin-bottom: 2.3rem;
  }

  .btn-and-text-wrapper {
    display: flex;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .profile-preferences {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .profile-preferences {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .profile-preferences {
    section {
      + section {
        margin-top: 4rem;
        margin-bottom: 4rem;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .profile-preferences {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .profile-preferences {
  }
}
