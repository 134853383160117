@use "../../styles/variables.scss";

.rich-text-triple {
  margin: 0 -2rem;
  padding: 0 2rem;

  .base8 {
    max-width: 100%;
    flex-basis: 100%;
    width: 100%;

    .article-blockquote {
      margin-top: 0;

      span {
        padding: 0;
      }
    }
  }

  &:last-child {
    padding-bottom: 8rem;
  }

  .h3 {
    @include variables.line-height(60, 48);
    font-size: 4.8rem;
    margin-bottom: 3.6rem;
  }

  // sass-lint:disable force-element-nesting
  .rich-text,
  .rich-text p,
  .article-blockquote {
    margin-bottom: 2.4rem;
  }
  // sass-enable:disable force-element-nesting

  .intro {
    margin-top: 3rem;
  }

  .article-blockquote {
    span {
      border-left: 0;
      padding: 0;
    }

    blockquote {
      @include variables.line-height(38, 30);
      font-size: 3rem;
      padding-left: 1rem;

      &::first-letter {
        margin-left: -1rem;
      }
    }

    .quote-position,
    .quote-name {
      padding-left: 0;
    }
  }

  &.blue {
    background-color: variables.$projectBlue;
    color: variables.$projectWhite;

    &:last-child {
      padding-bottom: 4rem;
      margin-bottom: 4rem;
    }

    .h3 {
      &:first-child {
        margin-top: 2rem;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .rich-text-triple {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .rich-text-triple {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .rich-text-triple {
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .rich-text-triple {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .rich-text-triple {
    &:last-child {
      padding-bottom: 4rem;
    }

    .h3 {
      @include variables.line-height(36, 28);
      font-size: 2.8rem;
    }

    .intro {
      margin-top: 0;
    }

    .article-blockquote {
      span {
        border-left: 0;
        padding: 0;
      }

      blockquote {
        @include variables.line-height(36, 28);
        font-size: 3rem;
        padding-left: 0;

        &::first-letter {
          margin-left: 0;
        }
      }

      .quote-position,
      .quote-name {
        padding-left: 0;
      }
    }
  }
}
