@use "../../styles/variables.scss";

.popular-topics {
  padding: 8rem 0;

  &.open {
    .view-all {
      display: none;
    }

    .all-item {
      display: inline-block;
    }
  }

  h2 {
    @include variables.line-height(38, 30);
    font-size: 3rem;
  }

  ul {
    padding-right: 18rem;
    position: relative;

    .popular-topic {
      display: inline-block;
      margin-right: 2.4rem;

      a {
        text-decoration: none;
        font-weight: bold;
        color: variables.$projectBlue;
        border-bottom: 0.1rem solid variables.$projectBlue;
        transition: color 300ms ease, border 300ms ease;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }

        &:hover,
        &:focus {
          color: variables.$projectBlue;
          border-bottom: 0.2rem solid variables.$projectBlue;
        }

        &:active {
          color: variables.$projectBlue;
          border-bottom: 0.2rem solid variables.$projectBlue;
        }
      }
    }

    .related-topic {
      display: inline-block;
      margin: 0 1.6rem 2.6rem 0;

      a {
        text-decoration: none;
        font-weight: 400;
        color: variables.$projectBlue;
        border: 1px solid #d7d2cb;
        transition: color 300ms ease, border 300ms ease;
        padding: 8px;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }

        &:hover,
        &:focus {
          background-color: variables.$projectBlue;
          color: white;
        }
      }
    }

    .all-item {
      display: none;
    }

    .view-all {
      position: absolute;
      right: 0;
      top: 0;

      a {
        @include variables.line-height(26, 18);
        font-size: 1.8rem;
        text-decoration: none;
        border: 0;

        // &:hover,
        // &:focus,
        // &:active {
        //     border: none;
        // }
      }

      i {
        display: inline-block;
        height: 1.2rem;
        margin-right: 1rem;
        transform: rotate(45deg);
        width: 1.2rem;

        svg {
          display: block;
          fill: variables.$projectBlue;
          height: 100%;
          width: 100%;
        }
      }
    }

    &.open {
      .view-all {
        display: none;
      }

      .all-item {
        display: inline-block;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .popular-topics {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .popular-topics {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .popular-topics {
    padding: 4rem 0;

    h2 {
      @include variables.line-height(36, 28);
      font-size: 2.8rem;
    }

    ul {
      padding-right: 0;

      .view-all {
        display: block;
        margin-top: 2.4rem;
        position: static;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .popular-topics {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .popular-topics {
  }
}
