@use "../../styles/variables.scss";
// COMPONENT - Country

// DEFAULT CLASS - .countryCodeAndPhone

// CLASS VARIANTS -

// WIDE WEB
.countryCodeAndPhone {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -2rem;
  margin-bottom: 5px;

  &.form-row {
    margin: 0 0 1rem;
  }

  div.countryCodeLabel {
    top: 2px;
    height: calc(2em - 1px);
    font-size: 1.5rem;
    position: relative;
  }

  .base6 {
    flex-basis: 50%;
    max-width: 50%;

    &.countrycodefield {
      padding: 0 2rem 0 0;

      .countryDialSelect {
        text-overflow: ellipsis;
      }
    }

    &.telfield {
      padding: 0 0 0 2rem;

      .form-row {
        top: 30px;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .countryCodeAndPhone {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .countryCodeAndPhone {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .countryCodeAndPhone {
    .base6 {
      flex-basis: 100%;
      max-width: 100%;

      &.countrycodefield {
        padding: 0;
      }

      &.telfield {
        padding: 0;

        .form-row {
          top: 10px;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .countryCodeAndPhone {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .countryCodeAndPhone {
  }
}
