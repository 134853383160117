@import "../../styles/variables.scss";

.row {
  display: flex;
  flex-flow: row wrap;
  height: inherit;
  margin: 0 -1rem;

  @include media(">tablet") {
    margin: 0 -2rem;
  }

  &.expanded {
    max-width: none;
  }
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  flex-direction: row;
}

.flex-start {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-flex-start {
  align-items: flex-start;
}

.align-flex-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.align-baseline {
  align-items: baseline;
}

.column {
  flex: 1 1 100%;
  box-sizing: border-box;
  padding: 0 1rem;

  @include media(">tablet") {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;

    > .row {
      margin-left: -$mobile-padding;
      margin-right: -$mobile-padding;
    }
  }
}

.Slim {
  color: red;

  .column {
    @include media(">tablet") {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      > .row {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }
    }
  }
}

.Thick {
  max-width: 100%;
  .column {
    @include media(">tablet") {
      padding-left: 10rem;
      padding-right: 10rem;

      > .row {
        margin-left: -10rem;
        margin-right: -10rem;
      }
    }
  }
}

.sm-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.sm-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.sm-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.sm-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.sm-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.sm-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.sm-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.sm-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.sm-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.sm-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.sm-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@include media(">tablet") {
  .md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include media(">desktop") {
  .lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// White Blue Sublayout styling
:global(.outer-wrap):global(.white-blue) {
  .row {
    .column {
      padding-top: 4rem;
      padding-bottom: 4rem;

      &:first-child {
        background-color: white;
      }

      &:last-child {
        background-color: $projectBlue;
      }
    }
  }
}

// Publication specific styling
:global(.publication-individual-page) {
  .row {
    margin-bottom: 2rem;
  }
}

// MOBILE PORTRAIT */
@media #{$mobile} {
  :global(.outer-wrap):global(.white-blue) {
    .row {
      .column {
        padding: 4rem 2rem;
      }
    }
  }
}
