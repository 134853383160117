@use "../../styles/variables.scss";

.article-authors {
  background-color: variables.$projectBone;
  margin: 8rem 0 7.1rem;

  p {
    font-size: 2rem;
  }
}

@media #{variables.$mobile} {
  .article-authors {
    margin: 3.2rem 0 3.9rem;

    h1 {
      margin-bottom: 1.5rem;
    }
  }
}
