@use "../../styles/variables.scss";

.article-blockquote {
  margin-bottom: 3rem;
  margin-top: 2rem;

  span {
    border-left: 0.5rem solid variables.$projectDarkRed;
    padding: 0 4rem 0 1.5rem;
    display: inline-block;
    margin-bottom: 1.3rem;

    blockquote {
      @include variables.line-height(38, 30);
      font-size: 3rem;
      font-family: var(--lbs-heading-font-family), serif;
      margin: -0.5rem 0 2.1rem;
    }

    p {
      &.quote-name {
        margin-bottom: -0.7rem;
      }
    }
  }

  p {
    &.quote-position {
      @include variables.line-height(20, 13);
      font-size: 1.3rem;
      padding-left: 1.8rem;
    }
  }

  &.large-quote {
    span {
      padding: 0 4rem 0 2.1rem;

      blockquote {
        @include variables.line-height(60, 48);
        font-size: 4.8rem;
        margin-top: -1rem;
      }
    }

    p {
      &.quote-position {
        padding-left: 2.5rem;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .article-blockquote {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .article-blockquote {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .article-blockquote {
    span {
      padding-left: 0;
      border-left: 0;

      blockquote {
        margin-top: -0.4rem;
        line-height: 1.3;
      }
    }

    p {
      &.quote-position {
        padding-left: 0;
      }
    }

    &.large-quote {
      span {
        padding-left: 1.9rem;
        border-left: 0.5rem solid variables.$projectDarkRed;

        blockquote {
          @include variables.line-height(38, 30);
          font-size: 3rem;
          margin-top: -0.4rem;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .article-blockquote {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .article-blockquote {
  }
}
