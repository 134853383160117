@use "../../styles/variables.scss";

.change-password {
  margin-top: 2.4rem;
  margin-bottom: 5.6rem;
  p {
    font-size: 3rem;
    font-weight: 700;
  }

  input {
    width: 20rem;
    margin-right: 4.8rem;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .change-password {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .change-password {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .change-password {
    margin-top: 2.4rem;
    margin-bottom: 5.6rem;
    p {
      font-size: 3rem;
      font-weight: 700;
    }

    input {
      width: 20rem;
      margin-right: 4.8rem;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .change-password {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .change-password {
  }
}
