@use "../../../styles/variables.scss";

.contributor-details {
  padding: 3rem 0;

  h2 {
    margin-bottom: 2.4rem;
  }

  &__role {
    @include variables.line-height(30, 20);
    color: variables.$projectDarkGrey;
    font-family: var(--lbs-base-font-family), sans-serif;
    font-size: 2rem;
    margin-bottom: 2.4rem;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 3rem;
  }

  &__card {
    background: variables.$projectWhite;
    padding: 0;

    img {
      width: 100%;
    }

    ul {
      padding: 2.4rem;
    }

    a {
      text-decoration: none;
    }

    li {
      margin-bottom: 2.4rem;
      padding-left: 3rem;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }
    }

    i {
      left: 0;
      position: absolute;

      svg {
        fill: variables.$projectInteractiveNavy;
        vertical-align: middle;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .contributor-details {
    .base3 {
      &[data-push-left*="off-1"] {
        margin: 0;
      }
    }
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .contributor-details {
    .base3 {
      &[data-push-left*="off-1"] {
        margin-left: 0;
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .contributor-details {
    padding: 4rem 0;

    h2 {
      margin-bottom: 1.6rem;
    }

    &__role {
      @include variables.line-height(18, 14);
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
    }

    .cta {
      margin-bottom: 2.4rem;
      text-align: left;
    }

    .base3 {
      margin: 0;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .contributor-details {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .contributor-details {
  }
}
