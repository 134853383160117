@use "../../styles/variables";

.save-to-my-profile {
  @include variables.line-height(24, 16);
  font-size: 1.6rem;
  display: flex;
  justify-content: flex-end;
  margin-left: 0.8rem;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .save-to-my-profile {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .save-to-my-profile {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .save-to-my-profile {
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .save-to-my-profile {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .save-to-my-profile {
  }
}
