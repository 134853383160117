@use "../../styles/variables.scss";

// COMPONENT - C280 Article Divider
// DEFAULT CLASS - .article-divider

.article-divider {
  border-bottom: 0.1rem solid variables.$projectDarkBone;
  margin: 2.8rem auto 3.6rem;
  max-width: 114rem;
}
