@use "../../styles/variables.scss";

.faculty-details {
  padding-bottom: 6.9rem;

  h1 {
    @include variables.line-height(68, 60);
    font-size: 6rem;
  }

  h2 {
    display: none;
  }

  .intro {
    margin-top: -0.2rem;

    p {
      font-size: 2rem;

      &:first-of-type {
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .qualifications {
      padding: 2rem 0 2.8rem;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .faculty-details {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .faculty-details {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .faculty-details {
    padding: 2.4rem 0 2.9rem;

    h2 {
      padding-right: 1rem;
      margin-bottom: 1.7rem;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .faculty-details {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .faculty-details {
  }
}
