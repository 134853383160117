@use "../../styles/variables.scss";

.block {
  width: 100%;
  display: flex;
  justify-content: center;

  h2 {
    font-size: 4.8rem;
  }

  & fieldset {
    flex-basis: 40%;
  }

  .textWrapper {
    padding-right: 4rem;
  }
}

.block {
  background-color: variables.$projectBlue;
  color: variables.$projectWhite;
  h2 {
    font-size: 3.6rem;
  }
}

.fieldset {
  border: 0;
  padding: 0;
}
.form-row {
  :global(.field) {
    position: relative;
  }
}

.link {
  margin-left: 0;
}

.selectAlt {
  :global(.form) {
    :global(.select) {
      display: inline-block;

      select {
        padding-right: 5rem;
        padding-left: 0;
        width: auto;
        background-color: transparent;
        color: variables.$projectWhite;
        font-weight: bold;
        option {
          color: variables.$projectBlack;
        }
      }

      :global(.tick) {
        svg {
          fill: variables.$projectWhite;
        }
      }
    }
  }
}

.searchButton {
  background: transparent;
  border: 0;
  position: relative;
  float: right;
  padding-right: 15px;
  top: -6.9rem;

  & svg {
    fill: variables.$projectBlue;
    width: 2.7rem !important;
    height: 2.7rem !important;
  }
}

// DESKTOP
@media #{variables.$compact-web} {
  .block {
    display: flex;
    justify-content: center;

    h2 {
      font-size: 4.8rem;
    }

    .textWrapper {
      padding-right: 4rem;
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .block {
    display: block;
    h2 {
      font-size: 3.6rem;
    }
  }

  .textWrapper {
    width: 100%;
  }
}

@media #{variables.$mobile-smallscreen} {
  .block {
    display: block;
    h2 {
      font-size: 3.6rem;
    }
  }

  .textWrapper {
    width: 100%;
  }

  .searchbox {
    width: auto;
    max-width: 95% !important;
  }
}
