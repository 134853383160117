.radio-buttons {
  label {
    line-height: 1.5;
    font-size: 1.6rem;
    margin-bottom: 4rem;
    color: #001e62 !important;
    padding-left: 40px !important;

    &:hover {
      font-weight: bold;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #001e62;
    border-radius: 100%;
    background: transparent !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 14px;
    height: 14px;
    background: #001e62;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .error {
    padding: 0;
    padding-left: 30px;
    position: relative;
    color: #c8103e;

    p {
      margin: 3px 0;
      font-size: 1.2rem;
      margin-bottom: 20px;
    }

    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      display: inline-block;
      left: 0px;
      top: 0px;

      position: absolute;
      background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
    }
  }
}
