@use "../../styles/variables.scss";
.cu-text {
  text-align: left;
}

.electives {
  .elective {
    &-accordion {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      > li {
        border-top-width: 0;
        flex: 1 1 0;
        margin: 0 0 2.2rem;
        overflow: visible;
        width: 100%;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          border-bottom-width: 0;
          margin-right: 0;
        }
      }
    }

    &-details {
      background-color: variables.$projectWhite;
      display: flex;
      flex-direction: column;
      height: 31.8rem;

      padding: 2.4rem;

      > .toggle-action {
        border-top: 0.1rem solid variables.$projectInteractiveNavy;
        margin-top: auto;
        padding: 1.7rem 0 0;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 1.2rem;
          height: 1.8rem;
          background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23001e62%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
          right: 1rem;
          background-repeat: no-repeat;
          top: 50%;
          transform: rotate(90deg);
        }

        > span {
          color: variables.$projectBlue;
        }

        .show-more {
          display: block;
        }

        .show-less {
          display: none;
        }

        &[aria-expanded="true"] {
          border-color: variables.$projectSantaGray;

          &::after {
            background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23001e62%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
            transform: rotate(-90deg);
          }

          > span {
            color: variables.$baseFontColour;
          }

          .show-more {
            display: none;
          }

          .show-less {
            display: block;
          }
        }
      }
    }

    &-accordion-drop {
      height: 0;
      padding: 0;
      transition: height 0.3s;
      position: relative;

      &[aria-hidden="true"] {
        height: 0;
        visibility: hidden;
      }

      .elective-content {
        background-color: variables.$projectWhite;
        opacity: 0;
        overflow: visible;
        padding: 2.4rem;
        width: 100%;
      }
    }

    &-profile {
      &-title {
        color: variables.$projectPineCone;
        font-family: var(--lbs-base-font-family), sans-serif;
        font-size: 1.4rem;
        letter-spacing: 0.88px;
        line-height: 1.2rem;
        margin-bottom: 1.6rem;
        margin-top: 0;
        text-transform: uppercase;
      }

      &-list {
        flex-direction: column;
        margin: 0;
        margin-top: 2rem;

        > li {
          align-items: center;
          border-top: 0.1rem solid variables.$projectAthensGray;
          display: flex;
          flex-direction: row;
          flex: 1 0 auto;
          margin: 0 !important;
          padding: 1.4rem 0 !important;

          &:first-child {
            border-top: 0;
          }

          &::before {
            display: none;
          }
        }

        .profile-image {
          margin-right: 2.4rem;
          width: 6rem;

          img {
            width: 6rem;
            height: 6rem;
          }
        }

        .profile-name {
          a {
            text-decoration: none;
            font-weight: normal;
            color: variables.$projectBlue;
            border-bottom: 0.1rem solid variables.$projectBlue;
            transition:
              color 300ms ease,
              border 300ms ease;

            &:hover,
            &:focus,
            &:active {
              text-decoration: none;
            }

            &:hover,
            &:focus {
              color: variables.$projectBlue;
              border-bottom: 0.2rem solid variables.$projectBlue;
            }

            &:active {
              color: variables.$projectBlue;
              border-bottom: 0.2rem solid variables.$projectBlue;
            }
          }
        }
      }
    }
  }

  .item {
    height: 100%;

    .toggle-action--mobile {
      display: none;
    }

    &.is--active {
      box-shadow: 0 0.2rem 1rem 0.3rem rgba(0, 0, 0, 0.1);

      .elective-details {
        background-color: variables.$projectWhite;
      }

      .title,
      p,
      li {
        color: variables.$baseFontColour;
      }

      ul {
        margin-top: 2rem;
        margin-left: 0;
        li {
          &::before {
            left: -1.8rem;
          }
        }
      }

      .elective-accordion-drop {
        box-shadow: 0 2rem 1rem 0 rgba(0, 0, 0, 0.1);

        .elective-content {
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }
      }
    }
  }

  .title {
    @include variables.line-height(38, 30);
    color: variables.$projectBlue;
    font-family: var(--lbs-heading-font-family), serif;
    font-size: 3rem;
  }

  .sub-title {
    color: variables.$projectPineCone;
    font-family: var(--lbs-base-font-family), sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.88px;
    line-height: 1.2rem;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
    margin-top: 4rem;
  }

  ul {
    &.styled-list {
      li {
        position: relative;
        font-weight: 700;
        color: variables.$projectBlue;

        &::before {
          display: none;
        }
      }

      .icon-arrow {
        position: absolute;
        left: -2rem;
        top: 0.3rem;
        width: 2rem;

        path {
          fill: variables.$projectBlue;
        }
      }
    }
  }

  p {
    color: variables.$projectBlue;
  }

  &[data-row-items="1"] {
    .elective-accordion {
      > li {
        flex: 0;
        min-width: calc(70% - 1rem);
        margin-right: 1.9rem;

        &:nth-child(even) {
          margin-right: 0;

          .elective-accordion-drop {
            left: calc(-100% - 2rem);
          }
        }
      }
    }
  }

  &[data-row-items="2"] {
    .elective-accordion-drop {
      width: calc(200% + 2rem);
    }

    .elective-accordion {
      > li {
        flex: 0;
        min-width: calc(50% - 1rem);
        margin-right: 1.9rem;

        &:nth-child(even) {
          margin-right: 0;

          .elective-accordion-drop {
            left: calc(-100% - 2rem);
          }
        }
      }
    }
  }

  &[data-row-items="3"] {
    .elective-accordion-drop {
      width: calc(300% + 3.9rem);
    }

    .elective-accordion {
      > li {
        flex: 0;
        margin-right: 1.9rem;
        min-width: calc(33.3% - 1.33rem);

        &:nth-child(3n + 2) {
          .elective-accordion-drop {
            left: calc(-100% - 2rem);
          }
        }

        &:nth-child(3n) {
          margin-right: 0;

          .elective-accordion-drop {
            left: calc(-200% - 3.9rem);
          }
        }
      }
    }
  }

  &[data-row-items="4"] {
    .elective-accordion-drop {
      width: calc(400% + 5.9rem);
    }

    .elective-accordion {
      > li {
        flex: 0;
        margin-right: 1.9rem;
        min-width: calc(25% - 1.5rem);

        &:nth-child(4n + 2) {
          .elective-accordion-drop {
            left: calc(-100% - 2rem);
          }
        }

        &:nth-child(4n + 3) {
          .elective-accordion-drop {
            left: calc(-200% - 4rem);
          }
        }

        &:nth-child(4n) {
          margin-right: 0;

          .elective-accordion-drop {
            left: calc(-300% - 5.9rem);
          }
        }
      }
    }
  }

  &.single-item {
    .wrapper-wide {
      margin-left: -2rem;
    }

    .elective {
      &-details {
        height: auto;
        min-height: 0;
        padding: 4rem 2.4rem 2rem;
      }
    }

    .item {
      &.is--active {
        box-shadow: none;

        .elective-accordion-drop {
          box-shadow: none;
        }
      }
    }

    .title {
      margin-bottom: 0;
      font-size: 4rem;
    }
  }

  &:global(.blue) {
    .elective {
      &-details {
        background-color: variables.$baseFontColour;

        > .toggle-action {
          &::after {
            background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23ffffff%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
          }

          > span {
            color: variables.$projectWhite;
          }

          &[aria-expanded="true"] {
            > span {
              color: variables.$projectBlue;
            }

            &::after {
              background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23001e62%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
            }
          }
        }
      }
    }

    .title,
    p {
      color: variables.$projectWhite;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .electives {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .electives {
  }
}

// TABLET LANDSCAPE
@media #{variables.$tablet} {
  .electives {
    .elective {
      &-details {
        height: 45rem;
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .electives {
    margin-left: -2.4rem;
    margin-right: -2.4rem;

    .elective {
      &-accordion {
        flex-wrap: nowrap;
        justify-content: unset;
        flex-direction: column;
      }

      &-accordion-drop {
        .elective-content {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      &-content {
        padding-bottom: 0;
      }

      &-details {
        min-height: auto;
      }

      &-profile {
        margin-top: 2.4rem;
      }
    }

    .item {
      height: 100%;

      .toggle-action--mobile {
        display: none;
        background-color: variables.$projectWhite;
        padding: 2.4rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 1.2rem;
          height: 1.8rem;
          background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23001e62%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
          right: 3.4rem;
          background-repeat: no-repeat;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
        }
      }

      &.is--active {
        .elective-details {
          min-height: auto;

          > .toggle-action {
            display: none;
          }
        }

        .toggle-action--mobile {
          display: block;
        }
      }
    }
    &[data-row-items="1"],
    &[data-row-items="2"],
    &[data-row-items="3"],
    &[data-row-items="4"] {
      .elective-accordion-drop {
        width: 100%;
        left: 0;
      }

      li {
        flex: unset;
        width: 100%;
      }
    }

    &[data-row-items="1"] {
      .elective-accordion {
        > li {
          &:nth-child(even) {
            .elective-accordion-drop {
              left: 0;
            }
          }
        }
      }
    }

    &[data-row-items="2"] {
      .elective-accordion {
        > li {
          &:nth-child(even) {
            .elective-accordion-drop {
              left: 0;
            }
          }
        }
      }
    }

    &[data-row-items="3"] {
      .elective-accordion {
        > li {
          &:nth-child(3n + 2),
          &:nth-child(3n) {
            .elective-accordion-drop {
              left: 0;
            }
          }
        }
      }
    }

    &[data-row-items="4"] {
      .elective-accordion {
        > li {
          &:nth-child(4n + 2),
          &:nth-child(4n + 3),
          &:nth-child(4n) {
            .elective-accordion-drop {
              left: 0;
            }
          }
        }
      }
    }

    &.single-item {
      .elective {
        &-details {
          padding-top: 0;
        }

        &-accordion {
          li {
            width: 100%;
          }
        }
      }

      .title {
        font-size: 2.8rem;
        line-height: 3.6rem;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .electives {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .electives {
  }
}
