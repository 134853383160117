@use "../../styles/variables.scss";

// COMPONENT - C27.01 Navigational quote

// DEFAULT CLASS - .navigational-quote

// CLASS VARIANTS -

// WIDE WEB

.navigational-quote {
  margin-bottom: 15rem;
  width: calc(100%);
  color: variables.$white;

  .mainCta {
    margin-bottom: 1.5rem;
  }

  .block-container {
    display: flex;
    max-width: 1445px;
    margin: 0 auto;

    .text-content {
      z-index: 1;
      width: 61.1%;
      background-color: variables.$projectBlue;
      padding: 12rem 2rem 35rem 15rem;
      position: relative;
      transform: translateY(2.5rem);
      transition: transform 1s;

      .label {
        color: variables.$projectSantaGray;
        margin-bottom: 2rem;
        margin-top: 0;
      }

      blockquote {
        @include variables.line-height(65, 60);
        font-family: var(--lbs-heading-font-family), serif;
        font-size: 6rem;
        padding-right: 6.5rem;
      }

      .line {
        position: absolute;
        width: 0.5rem;
        height: 0;
        background-color: variables.$projectRed;
        z-index: 2;
        transform: translate(8px, 20px);
        transition: height 1s;
        transition-delay: 0.5s;
      }

      .footnote {
        background-color: variables.$projectDarkBlue;
        position: absolute;
        bottom: 0;
        padding: 4rem 9rem;
        max-width: 1080px;
        width: calc(100vw - 12rem);
        margin-left: -4.5rem;
        display: flex;
        align-items: center;
        transform: translateY(12rem);
        transition: transform 1s;

        > div {
          &:first-child {
            margin-right: 3rem;
          }

          &:last-child {
            &:not(:first-child) {
              margin-left: auto;
              flex-shrink: 0;
            }
          }
        }

        p {
          line-height: 1.4;
          font-size: 1.2rem;

          &.name {
            @include variables.line-height(44, 30);
            font-size: 3rem;
          }

          &:last-child {
            color: variables.$white;
            margin-top: -1rem;
            margin-bottom: 1.7rem;
          }
        }
      }

      .image {
        display: none;
      }
    }

    .image {
      width: 55.4%;
      margin-left: -16.5%;

      picture {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.animate {
    .block-container {
      .text-content {
        transform: translateY(8.5rem);

        .footnote {
          transform: translateY(0);
        }
      }
    }
  }

  &.half {
    .mainCta {
      width: fit-content;
    }

    .block-container {
      .text-content {
        padding: 6rem 6rem 3.5rem;
        width: 100%;
        transform: none;

        blockquote {
          font-size: 3.6rem;
        }
      }

      .footnote {
        position: relative;
        background-color: transparent;
        margin-top: 6rem;
        padding: 0 0 0 8rem;
        display: block;
        width: 100%;
        transform: translateY(3rem);

        a {
          &.cta {
            width: auto;
          }
        }

        p {
          &.name {
            font-size: 2rem;

            + p {
              margin-bottom: 3rem;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) {
  .navigational-quote {
    &.no-img {
      .block-container {
        max-width: 100%;

        .text-content {
          width: 100%;

          .footnote {
            max-width: 100%;
          }
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .navigational-quote {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet} {
  .navigational-quote {
    .block-container {
      .text-content {
        padding: 12rem 2rem 24rem 10rem;

        blockquote {
          font-size: 4rem;
        }

        .footnote {
          width: calc(100vw - 7rem);
        }
      }
    }

    &.half {
      .block-container {
        .text-content {
          .footnote {
            width: 100%;
          }
        }
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .navigational-quote-carousel {
  }

  .navigational-quote {
    overflow: hidden;
    margin-bottom: 4rem;

    .block-container {
      flex-direction: column;

      .text-content {
        width: 100%;
        padding: 3.5rem 2rem 0;
        transform: translateY(0);

        .label {
          @include variables.line-height(20, 14);
          font-size: 1.4rem;
        }

        blockquote {
          @include variables.line-height(34, 28);
          font-size: 2.8rem;
          margin-bottom: 1.5rem;
        }

        .line {
          height: 9rem;
          width: 0.2rem;
          transform: translate(17.5px, -35px);
        }

        .footnote {
          position: relative;
          flex-direction: column;
          align-items: flex-start;
          transform: translateY(0);
          z-index: -1;
          padding: 11rem 6rem 5rem;
          margin-left: -2rem;
          width: calc(100% + 4rem);
          margin-top: -7rem;

          p {
            font-size: 1.2rem;

            &.name {
              font-size: 2rem;
            }
          }

          > div {
            &:last-child {
              &:not(:first-child) {
                margin-left: 0;
                margin-top: 1rem;
              }
            }
          }
        }

        .image {
          padding-top: 2rem;
          display: block;
        }
      }

      .image {
        display: none;
        width: 100%;
        margin-left: 0;
      }
    }

    &.animate {
      .block-container {
        .text-content {
          transform: translateY(0);

          .footnote {
            transform: translateY(0);
          }
        }
      }
    }

    &.half {
      margin-left: 0;
      width: calc(100% + 2rem);

      .block-container {
        .text-content {
          transform: translateY(0);

          .line {
            height: 6rem;
            transform: none;
            margin-top: 0.5rem;
          }

          blockquote {
            margin-bottom: 0;
            font-size: 2.8rem;
          }

          .footnote {
            z-index: 0;
            transform: none;
            padding: 6rem 2rem 1rem;
            margin-top: 1rem;
          }
        }
      }
    }

    &.no-img {
      .block-container {
        .text-content {
          .line {
            height: 6rem;
            width: 0.2rem;
            transform: translate(17.5px, -11px);
          }

          .footnote {
            margin-top: 0;
            padding-top: 4rem;
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .navigational-quote {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .navigational-quote {
  }
}

// COMPONENT - C27.3 Navigational quote carousel

// DEFAULT CLASS - .navigational-quote-carousel

// CLASS VARIANTS -

// WIDE WEB

.overlay-content {
  height: 100vh;

  .qt-line {
    position: absolute;
    width: 0.5rem;
    height: 0;
    background-color: variables.$projectRed;
    z-index: 2;
    transform: translate(0px, 20px);
    transition: height 1s;
    transition-delay: 0.5s;
    height: 70px;
  }

  .qt-second-line {
    position: absolute;
    width: 0.5rem;
    height: 0;
    background-color: variables.$projectRed;
    z-index: 2;
    transform: translate(0px, 20px);
    transition: height 1s;
    transition-delay: 0.5s;
    height: 70px;
    transform: translateY(-80px);
  }
}

.popup {
  margin: 70px auto;
  padding: 3rem;
  padding-top: 0.5rem;
  background: variables.$projectDarkBlue;
  position: relative;
  transition: all 1s ease-in-out;

  .cancel {
    display: block;
    position: absolute;
    top: 5px;
    right: 2px;
    color: variables.$white;
    height: 50px;
    width: 50px;
    font-size: 250%;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
  }

  .content {
    max-height: 30%;
    overflow: auto;
  }

  .left {
    margin-left: unset;
  }

  p {
    color: variables.$white;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  a {
    line-height: unset;

    &:focus,
    &:hover {
      background-image: none;
    }
  }
}

.navigational-quote-carousel {
  // margin: 0 0 ;
  margin: 0 -2rem;
  margin-bottom: 3rem;
  position: relative;

  :global(.slick-slide) {
    overflow: hidden;

    > div {
      padding: 0;
      margin: 0;
    }
  }

  :global(.slick-next),
  :global(.slick-prev) {
    // necessary due to the use of !important in the global slider.scss file
    display: block !important;
    z-index: 1;
    background: url("../../public/assets2019/img/svg/arrow-carousel-white.svg")
      no-repeat 50% transparent;
    background-size: cover;
    border: 0;
    box-shadow: none;
    display: block;
    height: 4.8rem;
    padding: 0;
    pointer-events: all;
    width: 3.5rem;

    &::before {
      display: none;
      content: "";
    }

    &:focus {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
      outline-width: 5px;
    }
  }

  :global(.slick-next) {
    right: 4rem;
  }

  :global(.slick-prev) {
    transform: translate(0, -50%) rotate(-180deg);
    left: 4rem;
  }

  .tns-nav {
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    left: 0;
    position: absolute;
    bottom: 2rem;
    z-index: 2;
  }

  .tns-nav {
    bottom: 0;
    left: 0;
    margin: 2rem 0;
    position: absolute;

    span {
      position: relative;
      width: 1.2rem;
      height: 1.2rem;
      padding: 1rem;
      background: none;
      border: 0;
      cursor: pointer;
      pointer-events: all;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 1px solid variables.$projectBlue;
        background-color: transparent;
        width: 1.2rem;
        height: 1.2rem;
        padding: 0;
        border-color: variables.$projectBlue;
        z-index: 2;
      }
    }
  }

  :global(.slick-active) {
    .tns-nav {
      span {
        &::after {
          background: variables.$projectBlue;
        }
      }
    }
  }

  .navigational-quote {
    margin-left: 0;
    width: 100%;

    .block-container {
      .text-content {
        z-index: 1;
        transform: translateY(8.5rem);
        transition: none;

        .line {
          transition: none;
          transition-delay: 0;
          max-height: 239px;
        }

        .footnote {
          transform: translateY(0);

          > div {
            &:last-child {
              &:not(:first-child) {
                text-align: center;
              }
            }
          }

          .cta {
            &.secondary {
              display: block;
              margin-bottom: 1.6rem;
              width: auto;
            }
          }
        }

        .desktop-blockquote {
          padding-bottom: 2rem;
        }
      }
    }
  }

  .read-more-btn {
    display: inline;
    position: relative;
    top: 1rem;
    font-size: 2rem;
    color: variables.$white;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  .no-img {
    max-width: none;
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .navigational-quote-carousel {
    .navigational-quote-carousel {
      :global(.slick-next),
      :global(.slick-prev) {
        height: 2.4rem;
        width: 1.6rem;
        bottom: 24rem;
        top: unset;
      }

      :global(.slick-next) {
        right: 45%;
      }

      :global(.slick-prev) {
        left: 45%;
      }
    }

    .no-img {
      bottom: 30rem;
      width: 100%;
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .navigational-quote-carousel {
    :global(.slick-next),
    :global(.slick-prev) {
      bottom: unset;
      top: 50%;
      height: 2.4rem;
      width: 1.6rem;
    }

    :global(.slick-next) {
      right: 4rem;
    }

    :global(.slick-prev) {
      left: 4rem;
    }

    .navigational-quote {
      margin-bottom: 6rem;

      .block-container {
        .text-content {
          transform: translateY(0);

          blockquote {
            font-size: 2.1rem;
          }

          .footnote {
            transform: translateY(0);
          }
        }
      }

      &.no-img {
        .block-container {
          .text-content {
            blockquote {
              margin-bottom: 9rem;
            }
          }
        }
      }
    }

    .read-more-btn {
      display: block;
      font-size: 16px;
      padding-bottom: 20px;
    }

    .desktop-blockquote {
      display: none;
    }

    .mobile-blockquote {
      display: inline;
    }

    .picturefill {
      padding-top: 2rem;
    }
  }
}
