@use "../../styles/variables.scss";

.deactivate-account {
  margin-top: 2.4rem;
  margin-bottom: 5.6rem;

  input {
    width: 20rem;
    margin-right: 4.8rem;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .deactivate-account {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .deactivate-account {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .deactivate-account {
    margin-top: 2.4rem;
    margin-bottom: 5.6rem;
    p {
      font-size: 3rem;
      font-weight: 700;
    }

    input {
      width: 20rem;
      margin-right: 4.8rem;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .deactivate-account {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .deactivate-account {
  }
}
