@import "../../styles/variables.scss";

// COMPONENT - C03 Sub navigation
// DEFAULT CLASS - .sub-navigation
// CLASS VARIANTS -
// WIDE WEB

.icon-list {
  text-align: center;
  padding: 4rem 0;

  h3 {
    max-width: 740px;
    margin: 0 auto 2rem;
  }

  h4,
  .h4,
  h6,
  .h6 {
    span {
      display: block;
      margin: 0 auto;
    }
  }

  h6 {
    font-family: var(--lbs-heading-font-family), serif;
    font-size: 2.4rem;

    &.h4 {
      font-size: 3.6rem;
    }
  }

  p {
    &.large {
      max-width: 740px;
      margin: 0 auto 1rem;
    }
  }

  a {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    display: inline-block;
  }

  p + a {
    margin-top: 1.5rem;
  }

  .point-container {
    margin: 4rem 0 7rem;
    display: flex;
    justify-content: space-around;

    .point {
      max-width: 26.5rem;
      margin: 0 0.5rem;
    }

    .icon {
      margin-bottom: 2.5rem;
      height: 102px;

      img {
        display: inline;
        height: inherit;
        width: auto;
      }
    }

    .content {
      height: calc(100% - 102px);

      &:only-child {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
      }

      a {
        &:last-child {
          margin-top: 1rem;
        }
      }
    }
  }

  :global(.cta) {
    width: auto;
    max-width: 30rem;
  }

  &[data-items="2"] {
    .point-container {
      .point {
        max-width: 37rem;
      }
    }
  }

  .tns-outer {
    display: flex;
    flex-direction: column-reverse;
  }

  .tns-nav {
    @include carousel-buttons;
    margin-top: -4rem;
    margin-bottom: 4rem;

    button {
      pointer-events: none;
    }
  }

  &.solo {
    padding: 0;
    margin-left: 20.5%;

    .point-container {
      margin: 0;
    }
  }

  &.blue {
    margin: 0 -2rem;
    padding: 4rem 2rem;
    background-color: $projectBlue;
    color: white;

    .tns-outer {
      .tns-nav {
        button {
          &::after {
            border: 1px solid white;
          }

          &.tns-nav-active {
            &::after {
              background: white;
            }
          }
        }
      }
    }

    a {
      color: white;
    }
  }
}

// COMPACT WEB
@media #{$compact-web} {
  .icon-list {
  }
}

// TABLET PORTRAIT
@media #{$tablet-portrait} {
  .icon-list {
  }
}

// MOBILE
@media #{$mobile} {
  .icon-list {
    .point-container {
      display: block;

      .point {
        max-width: 100%;
        margin: 0;
      }
    }

    &.solo {
      padding: 0;
      margin: 4rem 0;

      .point-container {
        margin: 0;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .icon-list {
  }
}

// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .icon-list {
  }
}
