@use "../../../styles/variables.scss";

.faculty-profile-carousel {
  margin: 4rem 0;

  // sass-lint:disable class-name-format
  .ps__rail-y {
    .ps__thumb-y {
      background-color: variables.$projectBlue;
    }

    &::after {
      background-color: variables.$projectBlue;
      z-index: 0;
    }
  }

  .container {
    display: flex;
  }

  .cta {
    &.tertiary {
      &:hover,
      &:focus {
        padding-right: 1rem;
      }

      .icon-external {
        background: none;
        height: 1.6rem;
        position: absolute;
        right: -1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1.6rem;
      }
    }
  }

  .swiper {
    padding-bottom: 80px;
    padding-right: 20px;

    .swiper-slide {
      height: auto;
    }
  }

  .promo-cta {
    box-sizing: border-box;
  }

  > .wrapper {
    position: relative;
  }

  .profile-wrapper {
    height: 100%;

    .pw-inner-wrapper {
      display: flex;
      background: white;
      padding-top: 3rem;
      height: 100%;

      p {
        margin-bottom: 3rem;
      }

      .text-wrapper {
        padding: 2rem 3rem;
        &.video {
          top: -350px;
          position: relative;
        }
      }

      .programme-type {
        font-weight: 700;
        margin-bottom: 0;
      }

      .sub-heading {
        font-size: 1rem;
        text-transform: uppercase;
        color: #6e6259;
        margin-bottom: 1.3rem;
      }

      .image-and-text-wrapper {
        width: 50%;
        flex-basis: 50%;
        padding-right: 2rem;
        position: relative;
        //padding-bottom: 5rem;

        .image-wrapper {
          position: relative;
          padding-top: 62.5%;
          width: calc(100% - 2rem);
          &.video {
            top: -334px;
          }
        }

        .button-wrapper {
          position: absolute;
          bottom: 2rem;
          width: calc(100% - 4rem);

          .tertiary {
            margin-bottom: 1rem;
          }
        }
      }

      .bottom {
        border-top: 1px solid variables.$projectLightGrey;
        margin-top: 2rem;
        padding-top: 2rem;
      }

      .profile-details {
        width: 50%;
        flex-basis: 50%;
        padding-right: 3rem;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 6rem;

        .button-wrapper {
          margin-bottom: 3.6rem;

          a {
            span:last-child {
              font-size: 2rem;
              font-family: "GT-Sectra-Fine-Bold", serif;
              font-weight: 400;
            }
          }
        }

        .dividing-line {
          display: block;
          height: 1px;
          background: variables.$projectInteractiveNavy;
          margin-bottom: 4rem;
        }
      }

      .pd-inner-wrapper {
        position: relative;
        height: 100%;
        overflow: auto;

        .overflow-container {
          position: absolute;
          top: 0;
          left: 0;
          padding-right: 2rem;
          width: 100%;
        }
      }
    }
  }

  &.compact {
    .pw-inner-wrapper {
      .image-and-text-wrapper {
        width: 100%;
        flex-basis: 100%;
        padding-right: 0;
      }

      .programme-type {
        margin-bottom: 3rem;
      }

      .button-wrapper {
        max-width: 22.5rem;
      }
    }
  }

  &.two {
    h3 {
      font-size: 3.6rem;
      margin-bottom: 0.5rem;
    }

    .pw-inner-wrapper {
      p {
        font-size: 1.2rem;
        margin-bottom: 2rem;
      }

      .programme-type {
        margin-bottom: 2rem;
      }

      .text-wrapper {
        padding: 2rem 2rem 3rem;
      }

      .button-wrapper {
        max-width: none;
      }
    }
  }

  &.three {
    .profile-wrapper {
      .pw-inner-wrapper {
        padding-top: 2rem;

        p {
          font-size: 1.2rem;
          margin-bottom: 2rem;
        }

        .programme-type-label {
          margin-bottom: 1rem;
        }

        .programme-type {
          font-size: 1.2rem;
          margin-bottom: 2.5rem;
        }

        .image-and-text-wrapper {
          .image-wrapper {
            .image {
              padding-right: 2rem;
            }
          }
        }

        .text-wrapper {
          padding: 2rem;
        }

        .profile-name {
          font-size: 2.6rem;
          margin-bottom: 0.8rem;
        }
      }
    }

    .pagination {
      @include variables.carousel-pagination;
      right: -2rem;
      display: flex;
      flex-direction: row;
      margin-top: -3rem;
      padding: 2.2rem 3.8rem 3.1rem 1.3rem;
      border: 0.1rem solid variables.$projectBlue;

      .icon-arrow-long {
        margin-left: 0.7rem;
        margin-right: -2rem;
        width: 2.2rem;
        height: 2rem;

        svg {
          width: 2.2rem;
          height: 2rem;
        }
      }

      .slide {
        + strong {
          margin: 0 0.4rem 0 0.6rem;
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }

  &.full-profile-container {
    padding: 2rem;
    max-width: 100%;
    width: 1180px;

    .profile-wrapper {
      .pw-inner-wrapper {
        max-height: calc(100vh - 4rem);
        position: relative;
        min-height: 60vh;

        .icon-cross {
          top: 2rem;
          right: 2rem;
          position: absolute;
          cursor: pointer;

          svg {
            fill: variables.$projectBlue;
          }
        }
      }
    }
  }
}

// sass-lint:disable class-name-format
.basicLightbox {
  .faculty-profile-carousel {
    &.full-profile-container {
      display: block;
      max-width: 100%;
      width: 1180px;
      padding: 2rem;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .faculty-profile-carousel {
    &.three {
      .swiper {
        padding-right: 20px;
      }

      .pagination {
        transform: translate(0%, -50%);
        right: -2.4rem;
      }
    }
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .faculty-profile-carousel {
    &.three {
      .swiper {
        padding-right: 20px;
      }

      .pagination {
        transform: translate(0%, -50%);
        display: flex;
        right: -2.4rem;
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .faculty-profile-carousel {
    &.compact {
      margin: 0 -2rem;
    }

    .swiper {
      padding-bottom: 40px;
      padding-right: 0;
      margin: 0 30px 20px 20px;
      overflow: visible;

      .swiper-slide {
        height: 100%;
      }
    }

    .profile-wrapper {
      .pw-inner-wrapper {
        flex-direction: column;
        padding-top: 2rem;
        overflow: auto;
        margin-bottom: 2rem;
        display: block;

        .programme-type {
          margin-bottom: 3rem;
        }

        .image-and-text-wrapper {
          width: 100%;
          flex-basis: 100%;
          padding-right: 0;
          &.video {
            margin-bottom: -200px;
          }

          .image-wrapper {
            &.video {
              top: -200px;
            }
          }

          .text-wrapper {
            padding: 2rem;
            &.video {
              top: -200px;
              position: relative;
            }
          }
        }

        .profile-details {
          width: 100%;
          flex-basis: 100%;
          padding: 0 2rem 3rem;
        }

        .button-wrapper {
          max-width: none;
        }

        .pd-inner-wrapper {
          .overflow-container {
            position: static;
          }
        }
      }
    }

    &.three {
      .pagination {
        display: none;
      }
    }

    &.full-profile-container {
      .profile-wrapper {
        .pw-inner-wrapper {
          max-height: calc(100vh - 4rem);
          position: relative;
          margin-bottom: 0;

          .image-and-text-wrapper {
            .image-wrapper {
              width: calc(100% - 6rem);
            }
          }
        }
      }
    }
  }
}
