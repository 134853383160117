@use "../../styles/variables.scss";

.latest-blog-posts {
  .label {
    margin-bottom: 1rem;
  }

  h2 {
    margin: 0;
  }

  .latest-blogs-actions {
    text-align: center;
    margin-bottom: 3rem;
  }

  .external-link {
    font-size: 1.6rem;
    text-decoration: none;

    &:hover,
    &:focus {
      @include variables.underline;
    }

    i {
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }
}

.blog-post-tile {
  &.promo-article-news {
    .text {
      align-items: flex-start;
      display: inline;

      .blog-label {
        @include variables.line-height(20, 12);
        background-color: variables.$projectBlue;
        color: variables.$projectWhite;
        display: inline-block;
        font-size: 1.2rem;
        padding: 0.2rem 0.8rem 0.1rem;
        text-transform: uppercase;
      }

      .author {
        color: variables.$projectGrey;
        font-size: 1.4rem;
      }

      .copy {
        color: variables.$projectBlue;
      }
    }
  }
}
