@import "../../styles/variables.scss";

.programme-comparison-table {
  margin-top: 4rem;

  :global(.slick-list) {
    overflow: initial;
  }

  :global(.slick-slide) {
    .top-heading {
      border-left: 1px solid $projectLightGrey;
    }

    &:first-child {
      .top-heading {
        border-left: 0;
      }
    }
  }

  .d-invis {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .tns-ovh {
    overflow: visible;
  }

  .tns-outer {
    &.ms-touch {
      overflow-y: visible;
      overflow-x: visible;
    }
  }

  .slider {
    width: 100%;
    padding: 0 2rem;
  }

  .dots {
    ul {
      top: 0;
      position: relative;
      justify-content: center;
      margin-bottom: 2rem;
    }
  }

  .programme-items {
    padding-left: 16rem;

    .rich-text {
      > ul {
        display: block;
      }
    }

    ul {
      display: flex;

      &[data-block_length="1"] {
        .programme-column {
          width: 100%;
          flex-basis: 100%;
        }
      }

      &[data-block_length="2"] {
        .programme-column {
          width: 50%;
          flex-basis: 50%;
        }
      }

      &[data-block_length="3"] {
        .programme-column {
          width: 33.33%;
          flex-basis: 33.33%;
        }
      }

      &[data-block_length="4"] {
        .programme-column {
          width: 25%;
          flex-basis: 25%;
        }
      }

      .programme-column {
        text-align: center;
        width: 100%;
        outline: 0;

        // &:first-child {
        //     .bottom-ctas {
        //         border-left: 1px solid $projectLightGrey;
        //     }
        // }

        .top-heading {
          a {
            &:hover {
              h3 {
                text-decoration: underline;
              }
            }
          }

          h3 {
            font-size: 3rem;
            margin-bottom: 2rem;
            display: inline-block;
            width: 100%;
            height: 100%;
            border-left: 1px solid $projectLightGrey;
            padding: 0 1rem;
          }
        }

        .info {
          position: relative;

          .rich-text {
            li {
              text-align: left;
            }
          }

          [data-reference] {
            padding: 2rem 1rem;
            border-left: 1px solid $projectLightGrey;
            border-top: 1px solid $projectLightGrey;
            display: flex;
            justify-content: center;
            align-items: center;

            &:last-child {
              border-bottom: 1px solid $projectLightGrey;
            }
          }

          .labels-wrapper {
            position: absolute;
            top: 0;
            right: 100%;
            text-align: left;
            // border-top: 1px solid $projectLightGrey;
            min-width: 16rem;
            padding-left: 2.6rem;

            .labels {
              > span {
                span {
                  text-align: center;
                  width: 100%;
                }
              }
            }
          }

          > div {
            position: relative;
          }

          .message-wrapper {
            @include message-popup;
            position: relative;
            display: inline-block;
            bottom: 0;

            &::before {
              right: 0;
              top: 0.2rem;
            }

            .message {
              pointer-events: none;
              right: -0.5rem;
              text-align: left;
            }
          }
        }

        .bottom-ctas {
          border-left: 1px solid $projectLightGrey;
          padding: 3.2rem 2.5rem;

          .cta {
            margin: 0 auto;
            display: block;
            width: auto;
            max-width: 24rem;
          }
        }
      }

      .labels-wrapper {
        max-width: 16rem;
        font-size: 1.2rem;

        .labels {
          display: flex;
          flex-direction: column;
          position: relative;
          //border-right: 1px solid $projectLightGrey;

          > [data-reference] {
            border-left: 0;
            font-weight: 700;
            padding-right: 4rem;
            justify-content: left;
          }
        }
      }
    }
  }
}

// COMPACT WEB
@media #{$compact-web} {
  .programme-comparison-table {
  }
}

// TABLET PORTRAIT
@media #{$tablet} {
  .programme-comparison-table {
    .programme-items {
      ul {
        .programme-column {
          .top-heading {
            h3 {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

// MOBILE
@media #{$mobile} {
  .programme-comparison-table {
    .rich-text {
      > ul {
        display: block;
      }
    }

    .d-invis {
      display: block;
      position: static;
      left: auto;
      top: auto;
      width: auto;
      height: auto;
      overflow: auto;
    }

    .tns-ovh {
      overflow: hidden;
    }

    .tns-outer {
      &.ms-touch {
        overflow-y: hidden;
        overflow-x: scroll;
      }
    }

    .programme-items {
      padding-left: 0;

      .tns-outer {
        display: flex;
        flex-direction: column-reverse;

        .tns-nav {
          @include carousel-buttons;
          margin-top: 0;
          margin-bottom: 2rem;
        }

        .tns-inner {
          margin: 0 -2rem;
          padding: 0 2rem;
        }
      }

      ul {
        .programme-column {
          .inner-wrapper {
            > span {
              font-size: 1.2rem;
              font-weight: 700;
            }
          }

          &:first-child {
            [data-reference],
            .bottom-ctas,
            .top-heading {
              h3 {
                border-left: 0;
              }
            }
          }

          .bottom-ctas {
            padding: 3.2rem 1.4rem;

            .cta {
              max-width: none;
            }
          }
        }
      }

      .labels-wrapper {
        display: none;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .programme-comparison-table {
  }
}

// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .programme-comparison-table {
  }
}
