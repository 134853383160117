@use "../../styles/variables.scss";

.payment-confirmation {
  padding: 0 2rem 3.4rem 2rem;
  margin-bottom: 3.9rem;
  margin-top: 3.4rem;
  border-bottom: 0.1rem solid variables.$projectLightGrey;

  p {
    padding-bottom: 10px;
  }

  .btn-and-text-wrapper {
    margin-top: 20px;
  }
}

.c-description {
  max-width: 630px;
}

.c-description-phd {
  padding-bottom: 20px;

  p {
    padding-bottom: 0;
  }
}

.back-button {
  max-width: 300px;
}

.c-details {
  strong {
    padding-right: 8px;
  }
}

.vertical-padd {
  padding: 50px 0;
}
