@use "../../styles/variables.scss";

.brand-title {
  background: variables.$projectDarkBlue;
  color: variables.$projectWhite;
  margin: 0 -2rem;
  padding: 1.4rem 2rem 1.2rem;

  &--icon {
    background: variables.$projectWhite;
    height: 6.2rem;

    .wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      .section-title {
        color: variables.$projectBlue;
      }
    }

    .icon-think-logo-mobile {
      display: none;
    }
  }

  h1,
  .section-title,
  p {
    @include variables.line-height(30, 26);
    font-size: 2.6rem;
    font-family: var(--lbs-base-font-family), sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .visually-hidden {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: 0.1rem;
    height: 0.1rem;
    overflow: hidden;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .brand-title {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .brand-title {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .brand-title {
    &--icon {
      height: 4.7rem;

      .wrapper {
        .section-title {
          font-size: 2rem;
        }
      }

      .icon-think-logo {
        &-desktop {
          display: none;
        }

        &-mobile {
          display: block;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .brand-title {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .brand-title {
  }
}
