.base {
  background-color: #f8d306;
  color: #493f05;
  padding: 5px;
  display: flex;
  gap: 10px;
  font-size: 11px;
  &::before {
    content: "\26A0";
  }
}
