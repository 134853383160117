@use "../../styles/variables.scss";
.cTopicBanner {
  margin: -4rem -100rem 4rem;

  .banner {
    background: variables.$projectDarkBlue;
    padding: 4rem 0;

    .content-wrapper {
      width: 50%;
    }

    h1 {
      color: variables.$projectWhite;
      font-size: 6rem;
      line-height: 1.13158;
      margin-bottom: 0;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .cTopicBanner {
    margin: -4rem 0 4rem;
    .banner {
      margin: 0 -2rem;
      .content-wrapper {
        width: 100%;
      }
    }
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .cTopicBanner {
    margin: -4rem 0 4rem;
    .banner {
      margin: 0 -2rem;
      .content-wrapper {
        width: 100%;
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .cTopicBanner {
    margin: -4rem 0 4rem;
    .banner {
      margin: 0 -2rem;
      .content-wrapper {
        width: 100%;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .cTopicBanner {
    margin: -4rem 0 4rem;
    .banner {
      margin: 0 -2rem;
      .content-wrapper {
        width: 100%;
      }
    }
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .cTopicBanner {
    margin: -4rem 0 4rem;
    .banner {
      margin: 0 -2rem;
      .content-wrapper {
        width: 100%;
        padding: 0 2rem;

        h1 {
          font-size: 4rem;
        }
      }
    }
  }
}
