@use "../../styles/variables";
// COMPONENT - How to Apply Page

// DEFAULT CLASS - .apply-page

// CLASS VARIANTS -

// WIDE WEB
.mobile {
  display: none;
}

.mobile-section {
  min-height: 200px;
  padding: 2em 0;
}

.apply-page {
  padding: 6rem 0;
  .section-title {
    line-height: 1.08333;
    font-size: 6rem;
  }

  .sub-title {
    color: variables.$projectBlue;
    font-family: var(--lbs-heading-font-family), serif;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
  }

  .create-profile {
    p {
      color: variables.$projectBlue;
      line-height: 1.5;
      font-size: 1.6rem;
      margin-bottom: 2.4rem;
    }
  }

  .button-wrapper {
    width: 19.6rem;
  }
}

.hide {
  display: none;
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .mobile {
    display: none;
  }
  .apply-page {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .apply-page {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .apply-page {
    .create-profile {
      border-bottom: 0.1rem solid #d7d2cb;
      padding-bottom: 2rem;
      margin-bottom: 2.5rem;
    }

    .button-wrapper {
      width: 100%;
    }
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
    .apply-page {
    }
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
    .apply-page {
    }
  }
}
