@use "../../styles/variables";

.programme-benefits {
  padding: 4rem 0;

  .small-top-heading {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: var(--lbs-base-font-family), sans-serif;
    color: variables.$projectGrey;
  }

  .main-heading-with-intro {
    display: flex;

    > h1 {
      font-size: 6rem;
      width: 50%;
      padding-right: 6rem;
      line-height: 1;
      margin-bottom: 0;
    }

    > p {
      width: 50%;
      font-size: 2rem;
      padding-right: 10rem;
    }
  }

  .tabs {
    margin-top: 8rem;

    :global(.react-tabs) {
      -webkit-tap-highlight-color: transparent;
    }

    :global(.react-tabs__tab-list) {
      border-bottom: 0.1rem solid #001e62;
      display: flex;
    }

    :global(.react-tabs__tab-panel) {
      display: none;
    }
    :global(.react-tabs__tab-panel--selected) {
      display: block;
    }

    :global(.react-tabs__tab) {
      display: inline-block;
      line-height: 1;
      margin-right: 4.2rem;
      margin-left: 0;
      margin-bottom: 0;
      cursor: pointer;

      font-size: 3rem;
      font-family: var(--lbs-base-font-family), sans-serif;
      padding: 0 0 1rem;
      text-align: left;
      font-weight: 500;
      color: #001e62;

      &:first-child {
        margin-left: 0;
      }
    }

    :global(.react-tabs__tab--selected) {
      border-bottom: 0.2rem solid #001e62;
      &:focus-visible {
        outline: none;
      }
    }

    :global(.react-tabs__tab--selected):focus-visible {
      outline: none;
    }

    :global(.react-tabs__tab-panel) {
      padding: 4rem 0;
    }
  }

  .result-copy-a {
    color: variables.$projectWhite;
  }

  h4 a {
    color: variables.$projectWhite;
  }

  .result-heading {
    font-size: 3.6rem;
    margin: -0.2rem 0 2.1rem;
  }

  &.blue {
    margin: 0 -2rem;
    padding: 4rem 2rem;
    background-color: variables.$projectBlue;
    color: white;

    &.noContent {
      padding-bottom: 0;
    }

    .small-top-heading {
      color: variables.$projectInteractiveNavy;
    }

    :global(.react-tabs__tab-list) {
      border-bottom: 0.1rem solid #7f8eb0;
      display: flex;
    }

    .tabs {
      :global(.react-tabs__tab) {
        color: variables.$white;
      }

      :global(.react-tabs__tab--selected):focus-visible {
        outline: none;
      }
      :global(.react-tabs__tab--selected) {
        border-bottom: 0.3rem solid variables.$projectWhite;
        &:focus-visible {
          outline: none;
        }
      }

      :global(.react-tabs__tab--selected):focus-visible {
        outline: none;
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .programme-benefits {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait}, #{variables.$mobile} {
  .programme-benefits {
    .small-top-heading {
      margin-bottom: 1.3rem;
    }

    .main-heading-with-intro {
      flex-direction: column;

      > p,
      > h1 {
        width: auto;
        padding-right: 0;
      }

      > h1 {
        margin-bottom: 2.5rem;
        font-size: 4rem;
        line-height: 1.2;
      }
    }

    .tabs {
      margin: 5rem 0 0;

      :global(.react-tabs__tab-panel) {
        padding: 2rem 0;
      }

      :global(.react-tabs__tab-list) {
        flex-direction: unset;
        overflow: scroll;
        margin-right: -2rem;
      }

      :global(.react-tabs__tab) {
        margin-right: 10%;
        border-bottom: 0;
        font-size: 1.8rem;
        background-color: transparent;

        &:last-of-type {
          padding-right: 4rem;
        }
      }
    }
  }
}
// MOBILE
@media #{variables.$mobile} {
  .programme-benefits {
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .programme-benefits {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .programme-benefits {
  }
}

// COMPONENT - C61.3 Publication listing

// DEFAULT CLASS - .publication-listing

// CLASS VARIANTS -

// WIDE WEB

.publication-listing {
  margin-bottom: 3rem;

  &.limited-height {
    max-height: 48rem;
    overflow: hidden;

    &.display-all {
      max-height: none;

      + .see-more-toggle {
        display: none;
      }

      h4 {
        &.not-visible {
          visibility: visible;
        }
      }
    }

    + .see-more-toggle {
      .icon-chevron {
        svg {
          transform: rotate(90deg) translateY(-50%);
          transform-origin: top;
        }
      }
    }
  }

  .icon-external {
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
    svg {
      fill: variables.$projectWhite;
      width: 25px;
      height: 25px;
    }
  }

  .row {
    display: block;
    margin: 0;
    padding: 3rem 0 2rem;

    &:first-child {
      padding-top: 0;
    }

    &.result {
      border-bottom: 0.1rem solid variables.$projectBlue;
      padding: 3rem 0 2rem;

      &:first-of-type {
        border-top: 0.1rem solid variables.$projectBlue;
      }
    }

    h4 {
      font-size: 3.6rem;
      margin: -0.2rem 0 2.1rem;

      &.not-visible {
        visibility: hidden;
      }

      a {
        text-decoration: none;

        &:hover,
        &:focus {
          background-image: linear-gradient(
            90deg,
            variables.$projectBlue,
            variables.$projectBlue
          );
          background-repeat: no-repeat;
          background-position: 0 100%;
          background-size: 100% 0.1rem;
          padding-bottom: 0.1rem;
        }
      }
    }

    p {
      font-size: 1.6rem;

      &.result-copy-a {
        &:first-of-type {
          // color: variables.$projectGrey;
          font-size: 1.4rem;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .publication-listing {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .publication-listing {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .publication-listing {
    .row {
      &.result {
        padding: 2.5rem 0 1.4rem;
      }

      h4 {
        font-size: 2.8rem;
        margin: 0 0 1.3rem;
        padding-right: 1rem;
      }

      p {
        font-size: 1.6rem;

        &.result-copy-a {
          &:first-of-type {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
