@use "../../styles/variables.scss";

.popular-authors {
  h2 {
    @include variables.line-height(44, 36);
    font-size: 3.6rem;
  }

  .authors-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -1rem;

    &::after {
      content: "";
      flex: auto;
    }
  }

  .wrapper-image {
    position: relative;
    width: 100%;
    height: 240px;

    img {
      object-fit: cover;
    }

    @media (min-width: 480px) {
      height: 310px;
    }

    @media (min-width: 640px) {
      height: 380px;
    }

    @media (min-width: 768px) {
      height: 155px;
    }
  }

  .author {
    display: flex;
    flex-direction: column;
    width: calc(25% - 2rem);
    margin: 0 1rem 3.6rem;
    color: variables.$projectWhite;
    background-color: variables.$projectBlue;
    position: relative;

    h3 {
      @include variables.line-height(36, 28);
      font-size: 2.8rem;
      margin-bottom: 1.7rem;
      padding: 2.6rem 2.6rem 0;

      a {
        @include variables.underline(variables.$projectWhite);
        color: variables.$projectWhite;
        text-decoration: none;
      }
    }

    .description-copy {
      margin-bottom: 1.5rem;
      padding: 0 2.6rem;
    }

    .text-with-icon {
      padding: 0 2.6rem;

      a {
        @include variables.underline(variables.$projectWhite);
        color: variables.$projectWhite;
        text-decoration: none;
      }
    }

    .author-footer {
      margin-top: auto;
      padding: 3rem 3.3rem 1.3rem;
    }

    .cta {
      color: variables.$projectWhite;

      &:focus,
      &:hover {
        span {
          &:last-child {
            background-image: linear-gradient(
              90deg,
              variables.$projectWhite,
              variables.$projectWhite
            );
          }
        }
      }

      &.tertiary {
        svg {
          fill: variables.$projectWhite;
          width: 2.4rem;
          height: 2.4rem;
          left: -0.7rem;
        }
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .popular-authors {
    h2 {
      @include variables.line-height(36, 28);
      font-size: 2.8rem;
    }

    .authors-container {
      flex-direction: column;
      margin: 0;
    }

    .author {
      width: 100%;
      margin: 0 0 2.4rem;
    }
  }
}
