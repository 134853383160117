@use "../../styles/variables.scss";

.my-profile-saved-content {
  li {
    align-items: center;
    border-bottom: 0.1rem solid variables.$projectDarkBone;
    display: flex;
    flex-direction: row;
    margin-bottom: 6.2rem;
    padding-bottom: 2.4rem;

    &:first-child {
      border-top: 0.1rem solid variables.$projectDarkBone;
      padding-top: 3.6rem;
    }
  }

  h2 {
    font-size: 3rem;
    line-height: 3.8rem;

    a {
      text-decoration: none;
      border-bottom: 0.2rem solid transparent;
      transition: border 300ms ease;
      font-weight: normal;

      &:hover,
      &:focus {
        text-decoration: none;
        border-bottom-color: variables.$projectBlue;
      }
    }
  }

  .copy-cs {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }

  .remove {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    display: flex;
    justify-content: flex-end;

    p {
      margin: 0;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .my-profile-saved-content {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .my-profile-saved-content {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .my-profile-saved-content {
    li {
      flex-direction: column;
      margin-bottom: 2.4rem;
      padding-bottom: 2.2rem;

      &:first-child {
        padding-top: 2.3rem;
      }
    }

    .copy-cs,
    .remove {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
    }

    h2 {
      margin-bottom: 1.7rem;
    }

    .remove {
      margin-top: 0.8rem;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .my-profile-saved-content {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .my-profile-saved-content {
  }
}
