@use "../../styles/variables.scss";

.header-wrap {
  .embag {
    font-size: 0;
    margin: 0;
    display: flex;
    background-color: variables.$white;
    overflow: hidden;
    a:last-child {
      margin-top: -6px;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
}

// MOBILE
@media #{variables.$mobile} {
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
}
