@use "../../styles/variables.scss";
// COMPONENT - Country State Section

// DEFAULT CLASS - .country-section

// CLASS VARIANTS -

// WIDE WEB
.country-section {
  position: relative;
  .countries-wrapper {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 999;
    background: variables.$white;
    border: 1px solid #aaa;
    color: #222;
    max-height: 300px;
    overflow-y: auto;

    .countries-item {
      box-sizing: border-box;
      padding: 0.2rem 1.6rem 0.2rem;
      border: 1px solid transparent;
      font-size: 1.1rem;
      line-height: 24px;
      font-weight: bold;
      margin: 0;

      &:hover {
        border: 1px solid gray;
        cursor: default;
      }
    }
  }

  .country-state_wrapper {
    margin-top: 2.3rem;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .country-section {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .country-section {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .country-section {
    .fields-floated {
      .field-row {
        flex-direction: column;

        label {
          flex-basis: 100%;
          max-width: 100%;

          + p {
            flex-basis: 100%;
            max-width: 100%;
          }
        }

        &.edit-details {
          a {
            width: 100%;
          }
        }
      }
    }
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
    .country-section {
    }
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
    .country-section {
    }
  }
}
