@use "../../styles/variables";

// COMPONENT - C72.1 Programme details bar (Sticky)

// DEFAULT CLASS - .programme-details-bar-sticky

// CLASS VARIANTS -

// WIDE WEB

.programme-details-bar-sticky {
  .sticky-wrap {
    background-color: variables.$projectDarkBlue;
    margin-left: -2rem;
    width: calc(100% + 4rem);
    color: white;
    padding: 1.6rem 2rem 0;

    //border-bottom: 1px solid hsla(0,0%,100%,.25)!important;

    > .wrapper {
      padding-bottom: 1.6rem;

      > .toggle_available_sessions {
        display: none;
        margin: 0 auto;
        color: white;

        > .toggle-link-for-available-sessions {
          display: block;

          position: relative;
          color: white;
          font-weight: bold;
          text-decoration: none;
          transition: all 0.5s ease-out;
          text-align: center;
          font-size: 1.2rem;
          top: 2rem;
          left: 12rem;

          &::after,
          &::before {
            content: "";
            position: absolute;
            right: -4rem;
            top: 13%;
            width: 1.3rem;
            height: 2px;
            background: white;
            transform: translateY(-50%);
            transition: all 0.5s ease-out;
          }

          &::after {
            transform: translateY(-50%) rotate(90deg);
          }

          &[aria-expanded="true"] {
            color: white;

            &::before {
              opacity: 0;
            }

            &::after {
              transform: rotate(0) translateY(-50%);
            }
          }
        }

        > .sticky-download-brochure-button {
          position: relative;
          left: 375px;
        }
      }
    }

    > .options {
      background-color: variables.$projectBlue;
      color: variables.$projectWhite;
    }
  }

  &.single-option {
    .accordiondecc {
      display: none;
    }
  }

  &.stick {
    padding-bottom: 6.5rem !important;
    margin-bottom: -6.5rem !important;

    .sticky-wrap {
      position: relative;
      // width: 100%;
      // top: 0;
      // z-index: 3;

      > .wrapper {
        > .toggle_available_sessions {
          display: flex;
          text-align: center;
          justify-content: center;
          font-size: 1.2rem;

          &.sessionsOpen {
            border-bottom: 1px solid;
            padding-bottom: 2.5rem;
            margin-bottom: 2.5rem;
          }
        }
        > .row-middle {
          .select-container {
            > .accordiondecc {
              a {
              }
            }
          }
        }
      }

      > .options {
        .container {
          margin-left: 1rem;
        }
      }
    }

    .select-container {
      //pointer-events: none;

      .selection {
        > div {
          span {
            display: none;
          }
        }
      }
    }
  }

  &.single-option {
    .select-container {
      > .accordiondecc {
        display: none;
      }
      .selection {
        .icon-chevron {
          display: none;
        }
      }
    }
  }

  .message-wrapper {
    // @include message-popup;

    position: static;

    .message {
      right: -1rem;
    }
  }

  .select,
  .selection {
    outline: none;
    &:hover {
      cursor: default !important;
    }
  }

  .data {
    display: flex;
    position: relative;
    align-items: flex-end;

    p {
      &:nth-child(1) {
        width: 60%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }
    }
  }

  .select-container {
    &.mobile-only {
      display: none;
    }
    .selection {
      display: flex;
      position: relative;
      cursor: pointer;

      p {
        margin: 0;
      }

      > div {
        padding-right: 1rem;

        span {
          // @include line-height(20, 12);
          display: block;
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 0.8rem;
        }

        p {
          font-size: 1.2rem;
        }

        &:nth-child(1) {
          width: 34%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 40%;
        }
      }
    }

    .headers,
    .selection {
      .icon-chevron {
        margin-bottom: 0;
        position: absolute;
        right: 0;
        top: 0.2rem;

        svg {
          width: 1.2rem;
          height: 1.2rem;
          transform: rotate(90deg);
          right: 0;
          fill: white;
        }
      }
    }

    .headers {
      p {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .accordiondecc {
      margin-top: 1rem;

      p {
        display: none;
      }

      > .toggle-link {
        display: block;
        cursor: pointer;
        position: relative;
        color: white;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.5s ease-out;
        width: 20%;
        font-size: 12px;
        &[aria-expanded="true"] {
          color: white;

          &::before {
            opacity: 0;
          }

          &::after {
            transform: rotate(0) translateY(-50%);
          }
        }

        .more {
          position: relative;
          display: inline-block;
          padding-right: 30px;
          &::after,
          &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            width: 1.3rem;
            height: 2px;
            background: white;
            transform: translateY(-50%);
            transition: all 0.5s ease-out;
          }

          &::after {
            transform: translateY(-50%) rotate(90deg);
          }
        }

        .fever {
          position: relative;
          display: inline-block;
          padding-right: 30px;

          &::after,
          &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            width: 1.3rem;
            height: 2px;
            background: white;
            transform: translateY(-50%);
            transition: all 0.5s ease-out;
          }

          &::after {
            transform: translateY(-50%) rotate(0);
          }
        }
      }
    }
  }

  .options {
    background-color: variables.$projectLightGrey;
    color: variables.$projectBlue;
    margin-top: 3rem;
    pointer-events: none;
    visibility: hidden;
    width: 100vw;
    margin: 0 -2rem;
    padding: 0 2rem;
    height: 0;
    overflow: hidden;
    transition:
      height 0.5s,
      visibility 0.5s;
    transition-delay: visibility 0.5s;

    .option-inner {
      position: relative;
    }

    .option {
      position: relative;
      padding: 2.4rem 0;
      border-bottom: 1px solid variables.$projectInteractiveNavy;

      &:last-child {
        border-bottom: 0;
      }

      .buttons {
        display: none;
      }

      p {
        margin: 0;
        padding-right: 1rem;
      }

      &.full {
        p {
          color: #80899f;
          font-weight: normal;
        }
      }

      .data {
        .title {
          display: none;
        }
      }

      .availability {
        position: absolute;
        right: 0;
        transform: translateX(100%) translateX(4rem) translateY(-2.4rem);

        p {
          &:last-child {
            display: none;
          }
        }
      }

      &.selected {
        display: none;
      }

      &:hover {
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          width: 100vw;
          background-color: rgba(255, 255, 255, 0.1);
          height: calc(100% + 2px);
          top: -1px;
          left: calc(((1084px - 100vw) / 2) + 30px);
        }
      }
    }
  }

  p {
    &.note {
      font-size: 1.2rem;
      padding: 2rem 0 1rem;
    }
  }

  .buttons {
    display: flex;
    margin: 0 0 0 2rem;
    align-items: center;
    justify-content: space-between;

    &.mobile-only {
      display: none;
    }

    > p {
      display: none;
    }

    &.full {
      > p {
        display: block;
      }

      > .cta {
        &:first-of-type {
          display: none;
        }
      }
    }

    .cta {
      width: auto;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .information {
      margin-right: 0;
      position: relative;
    }

    svg {
      fill: white;
      cursor: pointer;
    }
  }

  &.show {
    .options {
      opacity: 1;
      overflow: auto;
      overflow-x: hidden;
      pointer-events: auto;
      visibility: visible;
    }
  }

  &.two {
    .buttons {
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      a {
        min-width: fit-content;
      }

      > * {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .programme-details-bar-sticky {
  }
}

@media all and (min-width: 1024px) {
  .buttons {
    // sass-lint:disable-block no-important
    transform: none !important;
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet} {
  .programme-details-bar-sticky {
    .buttons {
      // sass-lint:disable-block no-important
      transform: none !important;
    }

    .sticky-wrap {
      > .wrapper {
        > .toggle_available_sessions {
          > .sticky-download-brochure-button {
            position: relative;
            left: 202px;
          }
        }
      }
    }

    .select-container {
      &.mobile-only {
        display: none;
      }
      .selection {
        display: flex;
        position: relative;
        cursor: pointer;

        p {
          margin: 0;
        }

        > div {
          padding-right: 1rem;

          span {
            // @include line-height(20, 12);
            display: block;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 0.8rem;
          }

          p {
            font-size: 1.2rem;
          }

          &:nth-child(1) {
            width: 34%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 48%;
          }
        }
      }

      .headers,
      .selection {
        .icon-chevron {
          margin-bottom: 0;
          position: absolute;
          right: 0;
          top: 0.2rem;

          svg {
            width: 1.2rem;
            height: 1.2rem;
            transform: rotate(90deg);
            right: 0;
            fill: white;
          }
        }
      }

      .headers {
        p {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }

      .accordiondecc {
        margin-top: 1rem;

        p {
          display: none;
        }

        > .toggle-link {
          display: block;
          cursor: pointer;
          position: relative;
          color: white;
          font-weight: bold;
          text-decoration: none;
          transition: all 0.5s ease-out;
          width: 20%;
          font-size: 12px;
          &[aria-expanded="true"] {
            color: white;

            &::before {
              opacity: 0;
            }

            &::after {
              transform: rotate(0) translateY(-50%);
            }
          }

          .more {
            position: relative;
            display: inline-block;
            padding-right: 30px;
            &::after,
            &::before {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              width: 1.3rem;
              height: 2px;
              background: white;
              transform: translateY(-50%);
              transition: all 0.5s ease-out;
            }

            &::after {
              transform: translateY(-50%) rotate(90deg);
            }
          }

          .fever {
            position: relative;
            display: inline-block;
            padding-right: 30px;

            &::after,
            &::before {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              width: 1.3rem;
              height: 2px;
              background: white;
              transform: translateY(-50%);
              transition: all 0.5s ease-out;
            }

            &::after {
              transform: translateY(-50%) rotate(0);
            }
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .programme-details-bar-sticky {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile} {
  .programme-details-bar-sticky {
    &.stick {
      .sticky-wrap {
        padding-top: 0;

        > .wrapper {
          display: none;
        }
      }
    }

    .toggle-link {
      width: auto;
      min-width: 100%;
    }
    .select-container {
      position: relative;
      z-index: 2;
    }
    .options {
      position: relative;
      z-index: 2;
    }

    .sticky-wrap {
      position: relative;

      > .wrapper {
        padding-bottom: 0;

        > .row-middle {
          .select-container {
            pointer-events: none;
            position: relative;
            z-index: 999999;

            > .accordiondecc {
              display: none !important;
            }
          }
        }
      }
    }

    .options {
      .option {
        padding: 1.6rem 0;

        &:hover {
          &:after {
            left: -20px;
          }
        }

        .data {
          align-items: center;
          flex-wrap: wrap;

          p {
            flex-basis: 50%;
            font-size: 1.2rem;
            margin-bottom: 1.6rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .select-container {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);

      &.desktop-only {
        display: none;
      }

      &.mobile-only {
        display: block;
      }

      .selection {
        flex-wrap: wrap;
        padding-top: 1rem;

        > div {
          flex-basis: 50%;
          margin-bottom: 1.6rem;

          span {
            display: inline-block;
            margin-bottom: 0;
            line-height: 1.65;
          }

          p {
            font-size: 1.2rem;
            line-height: 2;
          }

          &:last-child {
            .icon-chevron {
              margin-left: 0.5rem;
              position: static;
            }
          }
        }
      }
    }

    &.two {
      .buttons {
        justify-content: space-between;
        margin: 0 -2rem;
        padding: 1.35rem 2rem;
        position: relative;

        a {
          border: none;
          text-align: left;
          padding: 0 2.5rem;
          font-size: 1.8rem;
          font-weight: 700;

          &:hover {
            background: transparent;
            color: variables.$projectWhite;
            padding: 0 2.5rem;
            span {
              border-bottom: 1px solid variables.$projectWhite;
            }
          }
        }

        .download-icon {
          position: absolute;
          left: 2rem;
          top: calc(50% - 0.8rem);
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    .buttons {
      &.desktop-only {
        display: none;
      }

      &.mobile-only {
        display: flex;
      }

      .tertiary {
        // @include line-height(22, 18);
        font-size: 1.8rem;
      }

      .information {
        margin: 0;
      }
    }

    &.show {
      .select-container {
        border-bottom: 0;
      }

      &.two {
        .buttons {
          background: variables.$projectDarkBlue;
          bottom: 0;
        }
      }
    }
  }
}
