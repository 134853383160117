@use "../../styles/variables.scss";

.primary-nav {
  position: absolute;
  width: 100%;
  background-color: white;
  color: variables.$projectBlue;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.08);
  z-index: 4;
  opacity: 0;
  transition:
    opacity 0.5s,
    height 0.5s;
  pointer-events: none;
  visibility: hidden;

  &.show {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  a[href*="/"] {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
    list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  }

  a {
    color: variables.$projectBlue !important;
    cursor: auto;
  }

  .back {
    display: none;
  }

  .list-head {
    margin: 2rem;
    font-size: 1.6rem;
    line-height: 20px;
    font-weight: bold;
    font-family: var(--lbs-base-font-family), sans-serif;
    border-bottom: 2px solid #7f8eb0;
    padding-bottom: 1rem;
    margin-bottom: 0;

    &.mobile {
      display: none;
    }
  }

  > nav {
    display: none;

    &.open {
      display: flex;
    }

    > .panels {
      &.secondary {
        .panel {
          &.secondary {
            display: block;

            .list-head {
              margin-left: 0;
              margin-top: 0;
            }

            .listing {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: row;

              ul {
                li {
                  a {
                    font-weight: normal;
                  }

                  .list-head {
                    a {
                      font-weight: bold;
                    }
                  }

                  &.indented {
                    padding: 1.3rem 2rem 1.3rem 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .tabs {
      width: 25%;
      flex-shrink: 0;

      ul {
        flex-direction: column;

        .subtitle {
          border-bottom: 2px solid #7f8eb0;
          margin: 2rem 2rem 0;
          padding-bottom: 1rem;
          font-weight: bold;
          font-size: 1.6rem;
          a {
            margin: 0;
            padding-bottom: 1rem;
            font-weight: bold;
            font-size: 1.6rem;
          }
        }

        li:not(.subtitle) {
          position: relative;
          margin: 0;
          display: block;
          background: transparent;
          svg {
            position: absolute;
            width: 3.2rem;
            height: 3.2rem;
            right: 1rem;
            padding: 1rem;
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
            fill: variables.$projectDarkBlue;
          }

          a {
            font-size: 1.6rem;
            line-height: 18px;
            position: relative;
            padding: 2rem 2.5rem 1.5rem 4.5rem;
            margin: 0;
            overflow: hidden;
            z-index: 2;
            display: flex;
            width: calc(100% - 6rem);
            transition: padding 300ms;

            &.tab {
              font-weight: normal;
            }
          }

          &.active {
            overflow: visible;
            z-index: 1;

            a {
              font-weight: bold;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: -1rem;
              width: 23px;
              height: calc(100% - 0.1rem);
              z-index: 2;
            }
          }
        }
      }
    }
  }

  nav {
    &.secondary {
      display: flex;
      height: 100%;
      width: 100%;

      > ul,
      .listing {
        width: 50%;
        flex-shrink: 0;
        flex-direction: column;
        background-color: white;
        transition:
          opacity 0.5s,
          height 0.5s,
          display 0.5s;
        // box-shadow: 0 2px 10px 5px rgba(0,0,0,0.08);
        box-shadow: -9px 3px 10px -4px rgba(0, 0, 0, 0.08);
        border-left: 1px solid rgba(0, 0, 0, 0.05);

        .third-level-wrapper {
          display: flex;
          flex-direction: row;
        }
        li {
          margin: 1rem 1rem 1rem 2rem;
          padding-top: 0.7rem;
          position: relative;

          svg {
            position: absolute;
            width: 3.2rem;
            height: 3.2rem;
            right: 0;
            padding: 1rem;
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
            fill: variables.$projectDarkBlue;
          }

          a {
            font-size: 1.6rem;
            line-height: 17px;
            display: block;
            font-weight: 400;
            padding-right: 3rem;
            transition: padding 300ms;
            position: initial;

            &:hover {
              font-weight: 700;
              text-decoration: underline;
            }

            &:focus {
              text-decoration: underline;
            }

            &.active {
              font-weight: 700;
            }
            &.tab {
              font-weight: normal;
              text-decoration: none;
            }
          }

          &:last-child {
            padding-bottom: 1rem;
          }

          &.active {
            overflow: visible;
            z-index: 1;

            a {
              padding-left: 2rem;
              font-weight: bold;
            }
          }
        }
      }

      .listing {
        flex-grow: 1;
        padding-bottom: 1rem;

        ul.left-part,
        ul.right-part {
          width: 50%;
          li {
            &:hover {
              text-decoration: underline;
            }

            &:first-child {
              padding: 1.7rem 4rem 1rem 2rem;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  .panels {
    flex-grow: 1;
    width: 100%;

    &.secondary {
      min-width: 50%;
      ul {
        flex-grow: 1;
        flex-direction: column;
        width: 100%;

        li {
          padding: 0.8rem 2rem;
          margin: 0;
          position: relative;

          &:first-child {
            padding: 1rem 4rem 1rem 2rem;
            margin: 0;
          }

          .new {
            position: absolute;
            right: 5px;
            top: 2rem;
            background-color: #7d8cb3;
            padding: 0.1rem 0.5rem 0;
            font-size: 10px;
            color: white;
          }
        }
      }
    }

    .panel {
      display: none;

      &.open {
        display: flex;
        height: 100%;
      }
    }
  }

  aside {
    width: 50%;
    max-width: 281px;
    margin-left: 4.2rem;
    padding: 3rem 3rem 3rem 0;
    min-width: 20%;

    img {
      width: 100%;
      margin-bottom: 1rem;
    }

    .item {
      margin-bottom: 3rem;
    }

    .imageWrapper {
      display: inline-block;
      width: 255px;
      height: 130px;
    }

    :global(.cta) {
      font-weight: bold;
    }
  }

  .bottom {
    display: flex;
    background-color: variables.$projectBlue;
    align-items: center;
    padding: 2rem 4rem;
    border-bottom: 0.418rem solid variables.$projectRed;

    p {
      color: white;
      flex-shrink: 0;
      margin: 0;
    }

    a.ctaBottom {
      span {
        color: variables.$projectWhite;
        font-weight: bold;
      }
      &:hover {
        span {
          color: variables.$projectBlue;
        }
      }
    }

    :global(.cta) {
      margin-left: 4rem;
      font-size: 1.6rem;
      color: white;
    }
    .tertiary svg {
      fill: white;
    }

    &.mobile {
      display: none;
    }

    &.desktop {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      width: 100%;
    }
  }
}

// IE 11 HACK
@media all and (-ms-high-contrast: none) {
  .primary-nav {
    > nav {
      .tabs {
        ul {
          li {
            a {
              display: inline-block;
            }

            &.active {
              &::after {
                height: calc(100% - 1rem);
              }
            }
          }
        }
      }
    }

    .panels {
      &.secondary {
        aside {
          max-width: 300px;

          img {
            width: auto;
            max-width: 100%;
          }
        }
      }

      .panel {
        display: none;

        &.open {
          display: flex;
          height: 100%;
        }
      }
    }
  }
}

@media #{variables.$mobile} {
  .primary-nav {
    left: -2rem;
    transform: translateX(-100%);
    opacity: 1;
    transition:
      transform 0.5s,
      height 0.5s,
      visibility 0.5s;
    transition-delay: visibility 0.5s;
    visibility: hidden;

    &.show {
      transform: translateX(0);
      visibility: visible;
      height: auto !important;
    }

    .back {
      width: 100%;
      display: block;

      .link,
      a {
        padding: 2rem 4rem;
        display: block;
        border-bottom: 1px solid grey;
        width: 100%;
        font-size: 1.2rem;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAxZTYyIiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjAwMDAwMCwgLTYuMDAwMDAwKSI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIxMCA2IDguNTkgNy40MSAxMy4xNyAxMiA4LjU5IDE2LjU5IDEwIDE4IDE2IDEyIj48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
          right: 0;
          background-repeat: no-repeat;
          left: 1.5rem;
          top: 50%;
          transform: translateY(-63%) rotate(180deg);
        }
      }
    }

    .list-head {
      &.mobile {
        display: block;
        font-weight: normal;

        a {
          font-size: 1.6rem;
          display: block;
        }
      }
    }

    > nav {
      display: flex;
      visibility: hidden;
      transition: visibility 0.5s;
      height: 0;

      .tabs {
        pointer-events: none;
        width: 100%;

        ul {
          li {
            a {
              box-shadow: none;
              border-bottom: 0;
            }

            &.active {
              &::after {
                content: none;
              }

              a {
                padding-left: 2rem;

                &::before {
                  content: none;
                }
              }
            }
          }
        }
      }

      &.open {
        visibility: visible;
        height: auto;

        > .panels {
          &.secondary {
            .panel {
              &.secondary {
                position: static;

                .list-head {
                  margin: 0;
                  border-bottom: 2px solid #7f8eb0;
                  padding-bottom: 1rem;

                  &::after {
                    content: none;
                  }

                  &.mobile {
                    margin: 2rem;
                    margin-bottom: 0;
                  }
                }

                .listing {
                  height: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: column;

                  ul {
                    li {
                      &.indented {
                        padding: 1.3rem 2rem 1.3rem 4rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .tabs {
          pointer-events: all;
          overflow: hidden;

          &.hide {
            opacity: 0;
            pointer-events: none;
            height: 0;
          }
        }
      }
    }

    nav {
      &.secondary {
        flex-direction: column;

        .listing {
          width: 100%;
        }
      }
    }

    .panels {
      &.secondary {
        ul {
          li {
            &:first-child {
              padding: 0.8rem 2rem;
            }
          }
        }
      }
      .panel {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 2;
        transform: translateX(-100%);
        transition:
          transform 0.5s,
          visibility 0.5s;
        transition-delay: visibility 0.5s;
        display: flex;

        .secondary {
          ul {
            width: 100%;

            li {
              a {
                padding-left: 0;
              }

              .new {
                right: 10px;
              }

              &.active {
                a {
                  &::before {
                    content: none;
                  }
                }
              }
            }
          }
        }

        &.open {
          transform: translateX(0);
          height: auto;
          visibility: visible;

          > nav {
            > ul {
              &.hide {
                opacity: 0;
                pointer-events: none;
                height: 0;
              }
            }
          }
        }

        &.secondary {
          .list-head {
            border-bottom: 0;
            position: relative;
            margin-bottom: 1rem;

            &.list-head-secondary {
              border-bottom: 0.2rem solid variables.$projectInteractiveNavy;
              margin-bottom: 0;

              &::after {
                content: none;
              }
            }

            &::after {
              content: "";
              position: absolute;
              width: 1.2rem;
              height: 1.2rem;
              background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAxZTYyIiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjAwMDAwMCwgLTYuMDAwMDAwKSI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIxMCA2IDguNTkgNy40MSAxMy4xNyAxMiA4LjU5IDE2LjU5IDEwIDE4IDE2IDEyIj48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
              right: 0;
              top: 50%;
              background-repeat: no-repeat;
              transform: translateY(-50%) rotate(90deg);
            }

            &.open {
              &::after {
                transform: translateY(-50%) rotate(-90deg);
              }
            }
          }

          ul {
            overflow: hidden;
            transition: height 0.5s;

            &[data-height] {
              background-color: rgba(127, 143, 176, 0.2);
            }
          }
        }
      }
    }

    aside {
      display: none;
    }

    .bottom {
      display: none;

      &.mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2rem;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        width: 100%;
        z-index: 2;

        a.ctaBottom {
          margin-left: 0;
          font-size: 1.8rem;
          font-weight: bold;
          margin-top: 1.5rem;

          &:global(.tertiary) {
            &:hover {
              span {
                color: variables.$projectWhite;
              }
            }
          }
        }
      }
    }
  }
}
.cu-second-col {
  max-width: 260px;
  width: 270px !important;
}

.third-level-wrapper {
  > ul {
    li {
      a {
        padding-right: 0 !important;
      }
    }
  }
}
