@use "../../styles/variables.scss";

// COMPONENT - C270 Cta container
// DEFAULT CLASS - .cta-container
// CLASS VARIANTS -
// WIDE WEB
.subnav-header {
  margin-top: 4rem;

  .top-heading {
    h2 {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: var(--lbs-base-font-family), sans-serif;
    }
  }

  .title-with-intro {
    display: flex;
    margin-bottom: 4rem;

    .left-section {
      width: 50%;
      padding-right: 4.5rem;

      h3 {
        font-size: 4.8rem;
      }
    }

    .right-section {
      width: 50%;
      padding-left: 4.5rem;

      p {
        font-size: 2rem;
        margin-bottom: 3.5rem;
      }
    }
  }
}

@media #{variables.$mobile} {
  .subnav-header {
    .title-with-intro {
      flex-direction: column;
      margin-bottom: 0;

      > .right-section,
      > .left-section {
        width: 100%;
        padding: 0;
      }
    }
  }
}
