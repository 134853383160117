@import "../../styles/variables.scss";

.notification-banner {
  position: relative;
  left: 0;
  right: 0;
  background: $projectLightGrey;
  color: $projectBlue;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 1rem 1rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  p {
    margin-top: 1rem;
    margin-right: 4rem;
  }
}

.close {
  position: absolute;
  right: 2rem;
  top: 40%;

  svg {
    fill: $projectBlue;
  }

  &:hover,
  &:focus {
    svg {
      fill: $projectInteractiveNavy;
    }
  }
}

// COMPACT WEB
@media #{$compact-web} {
}

// TABLET PORTRAIT
@media #{$tablet} {
}

// MOBILE
@media #{$mobile} {
}

// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
}

// MOBILE PORTRAIT
@media #{$mobile-portrait} {
}
