@import "../../styles/variables.scss";

.cta-container {
  //margin: 0 -2rem;
  margin: 50px 0;
  overflow: hidden;

  .cta-section {
    text-align: center;
    padding: 11rem 2rem;

    h3 {
      margin-bottom: 4.7rem;
    }

    p {
      margin-bottom: 4rem;
      max-width: 74.9rem;
      margin-left: auto;
      margin-right: auto;
    }

    .button-wrapper {
      max-width: 22rem;
      margin: 0 auto;
    }
  }

  &.blue {
    background: $projectBlue;
    color: white;
  }

  &.white {
    background: white;
  }
}
// COMPACT WEB
@media #{$compact-web} {
  .cta-container {
  }
}
// TABLET PORTRAIT
@media #{$tablet-portrait} {
  .cta-container {
  }
}
// MOBILE
@media #{$mobile} {
  .cta-container {
    padding: 0;
    margin: 4rem 0;

    .cta-section {
      padding: 3rem 2rem;

      p {
        margin-bottom: 2rem;
      }

      h3 {
        margin-bottom: 4rem;
        max-width: 25rem;
        margin-left: auto;
        margin-right: auto;

        + .row {
          .base4 {
            &:first-child {
              .cta-content {
                margin-top: 0;
              }
            }
          }
        }

        + .cta-content {
          margin-top: 3rem;
        }
      }

      .cta-content {
        margin-top: 5rem;

        &:first-child {
          margin-top: 3rem;
        }
      }

      .button-wrapper {
        max-width: none;
      }
    }
  }
}
// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .cta-container {
  }
}
// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .cta-container {
  }
}
