@use "../../styles/variables";

.swiper-navigation {
  display: inline-flex;
  background-color: variables.$projectDarkBlue;
  color: variables.$white;
  border: 1px solid variables.$projectDarkBlue;
  padding: 0 1.5rem;
  font-size: 2.4rem;
  height: 7rem;
  font-family: var(--lbs-heading-font-family), serif;
  cursor: pointer;
  z-index: 2;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  vertical-align: top;

  &--arrow {
    width: 5rem;
  }

  &--square {
    height: 6rem;
    width: 6rem;
    justify-content: center;

    .swiper-navigation__arrow {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &--number {
    width: 9.5rem;
  }

  &--fraction {
    width: 13rem;
  }

  &__numbers {
    transform: translateY(-3px);

    strong {
      color: variables.$projectRed;
    }
  }

  &__arrow {
    fill: variables.$white;
    height: 2rem;
    width: 2rem;
    flex-shrink: 0;
  }

  &--next {
    flex-direction: row;
  }

  &--prev {
    flex-direction: row-reverse;

    .swiper-navigation__arrow {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background-color: variables.$white;
    color: variables.$projectDarkBlue;

    .swiper-navigation__arrow {
      fill: variables.$projectDarkBlue;
    }
  }

  &--light {
    background-color: variables.$white;
    color: variables.$projectDarkBlue;

    .swiper-navigation__arrow {
      fill: variables.$projectDarkBlue;
    }

    &:hover {
      border-color: variables.$projectRed;
    }
  }
}

@media #{variables.$mobile} {
  .swiper-navigation {
    height: 6rem;
    padding: 0 1rem;

    &--arrow {
      width: auto;
    }
  }
}
