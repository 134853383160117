@use "../../styles/variables.scss";

.component {
  margin-top: 4rem;
}

.spinner {
  padding-bottom: 50px;
}

.sub-title {
  margin-top: 75px;
}

.event-registration-form {
  .section-heading,
  .sub-heading {
    color: variables.$projectBlue;
    font-family: var(--lbs-heading-font-family), serif;
    font-size: 3rem;
    line-height: 3.8rem;
    margin-bottom: 2.4rem;
  }

  .fields-floated {
    .field-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.6rem;

      &.edit-details {
        padding-bottom: 3.4rem;
        margin-bottom: 3.9rem;
        margin-top: 3.4rem;
        border-bottom: 0.1rem solid variables.$projectLightGrey;

        a {
          width: 22.1rem;
        }
      }

      label {
        flex-basis: 25%;
        max-width: 25%;
        color: variables.$projectBlue;
        font-family: var(--lbs-base-font-family);
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 700;
        padding-right: 1rem;

        + p {
          flex-basis: 75%;
          max-width: 75%;
          margin-bottom: 0;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .event-registration {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .event-registration {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .event-registration {
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .event-registration {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .event-registration {
  }
}
