@use "../../styles/variables.scss";

.promotional-related-content {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.two {
    .article-panel,
    .promo-article-news,
    .promo-events {
      width: calc(50% - 2rem);
    }
  }
}

.base4 {
  .promotional-related-content {
    .wrapper {
      flex-direction: column;
    }

    .article-panel,
    .promo-article-news,
    .promo-events {
      width: 100%;
    }
  }
}

@media #{variables.$tablet-portrait} {
  .promotional-related-content {
  }
}

@media #{variables.$mobile} {
  .promotional-related-content {
    .wrapper {
      flex-direction: column;
    }

    &.two {
      .article-panel,
      .promo-article-news,
      .promo-events {
        width: 100%;
      }
    }
  }
}

@media #{variables.$mobile-landscape} {
  .promotional-related-content {
  }
}

@media #{variables.$mobile-portrait} {
  .promotional-related-content {
  }
}

.article-panel {
  position: relative;
  width: calc(33.3% - 2rem);
  margin-bottom: 2.4rem;

  .article-panel-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .wrapper-image {
    position: relative;
    min-height: 0.1rem;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      transition: transform 0.3s;
    }

    &:hover {
      .icon-on-image {
        + img {
          transform: scale(1.1);
        }
      }

      > a {
        img {
          transform: scale(1.1);
        }
      }
    }

    a {
      display: block;
      min-height: 1px;
      overflow: hidden;
      position: relative;
      width: inherit;
      height: inherit;

      // sass-lint:disable-block class-name-format
      &.CoveoResultLink {
        display: block;

        &:hover {
          background-image: none;
        }
      }
    }
  }

  .icon-on-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: 0;
    height: 100%;
    width: 100%;

    span {
      height: 3rem;
      width: 3rem;

      &.icon-play-two {
        svg {
          position: absolute;
          right: 50%;
          left: 50%;
          top: 0;
          bottom: 0;
          margin: auto;
          transform: translateX(-45%);
        }
      }
    }

    svg {
      height: 100%;
      width: 100%;
      fill: variables.$projectWhite;
    }

    .video-podcast-lightbox {
      height: 100%;
      width: 100%;

      .play-media {
        background: none;
        border-radius: 0;
        height: 100%;
        position: relative;
        transition: opacity 0.2s ease-in-out;
        width: 100%;
        z-index: 2;
        display: flex;
        padding-top: 0;

        &:hover,
        &:focus {
          opacity: 0.75;
        }

        .icon {
          background-color: variables.$projectBlue;
          border-radius: 50%;
          height: 6.8rem;
          position: relative;
          width: 6.8rem;

          svg {
            left: 50%;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &[data-youtube],
        &[data-vimeo] {
          svg {
            left: 55%;
          }
        }
      }
    }
  }

  .text {
    padding: 2.6rem 2.6rem 3rem;
    background-color: variables.$projectWhite;
    color: variables.$projectBlue;
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    .primary-tag {
      @include variables.line-height(22, 14);
      @include variables.letter-spacing(88);
      font-size: 1.4rem;
      font-weight: normal;
      font-family: var(--lbs-base-font-family), sans-serif;
      color: variables.$projectGrey;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    h2 {
      @include variables.line-height(38, 30);
      font-size: 3rem;
      margin-bottom: 1.7rem;

      a {
        @include variables.underline(variables.$projectBlue);
        color: inherit;
        text-decoration: none;
        font-weight: 400;
      }
    }

    .sub-heading {
      color: variables.$projectBlue;
    }

    .copy {
      @include variables.line-height(24);
      color: variables.$projectDarkBlue;
      margin-bottom: 1.5rem;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .info-field {
      @include variables.line-height(24, 14);
      color: variables.$projectGrey;
      font-size: 1.4rem;
    }

    footer {
      border-top: 0.1rem solid #d8d8d8;
      display: flex;
      flex-wrap: wrap;
      padding-top: 3rem;
      margin-top: auto;

      :global(.social-share) {
        align-self: flex-end;
        margin-left: auto;
      }
    }
  }
}

@media #{variables.$mobile} {
  .article-panel {
    width: 100%;
  }
}

// events

.promotional-related-content {
  .promo-events {
    width: calc(33.3% - 2rem);

    @media #{variables.$mobile} {
      width: 100%;
    }
  }
}
.promo-events {
  position: relative;

  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;

  > a {
    min-height: 1px;
    overflow: hidden;
    padding-top: 56.26%;
    position: relative;

    &:hover {
      // sass-lint:disable-block no-important
      background-image: none !important;
    }
  }

  img {
    height: auto;
    left: 0;
    min-height: 100%;
    position: absolute;
    top: 50%;
    transition: transform 0.3s;
    width: 100%;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  .icon-on-image {
    position: absolute;
    height: 5rem;
    width: 5rem;
    right: 2.1rem;
    top: 0;
    background-color: variables.$projectBlue;
    color: variables.$projectWhite;
    font-family: var(--lbs-base-font-family), sans-serif;
    font-weight: normal;
    text-align: center;
    padding: 0.5rem 0.7rem;
    z-index: 2;

    .month-on-image {
      @include variables.line-height(12, 10);
      display: block;
      font-size: 1rem;
    }

    .day-on-image {
      @include variables.line-height(30, 30);
      font-size: 3rem;
    }
  }

  .details {
    padding: 2.6rem 2.6rem 3rem;
    background-color: variables.$projectWhite;
    color: variables.$projectBlue;
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    .primary-tag {
      @include variables.line-height(22, 14);
      @include variables.letter-spacing(88);
      font-size: 1.4rem;
      font-family: var(--lbs-base-font-family), sans-serif;
      color: variables.$projectGrey;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    h2 {
      @include variables.line-height(38, 30);
      font-size: 3rem;
      margin-bottom: 1.7rem;

      a {
        @include variables.underline(variables.$projectBlue);
        color: inherit;
        text-decoration: none;
        font-weight: 400;
      }
    }

    .text-with-icon {
      @include variables.line-height(24);
      display: flex;
      margin-bottom: 2rem;
      font-size: 1.4rem;

      > i {
        display: flex;
        align-items: center;

        svg {
          height: 2.5rem;
          width: 2.5rem;
          margin-right: 1rem;
          fill: variables.$projectInteractiveNavy;
        }
      }
    }

    .summary-text {
      @include variables.line-height(24);
      margin-bottom: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: variables.$projectDarkBlue;
    }

    footer {
      border-top: 0.1rem solid #d8d8d8;
      display: flex;
      flex-wrap: wrap;
      margin-top: auto;
      padding-top: 3rem;

      .tag {
        flex-shrink: 0;
      }

      :global(.social-share) {
        align-self: flex-end;
        margin-left: auto;
      }
    }
  }
}

@media #{variables.$mobile} {
  .promo-events {
    width: 100%;
  }
}
