@use "../../styles/variables";
@use "../../styles/variables/colors" as colors;

.paddingTop {
  padding-top: 1.5rem;
}

.programme-benefits {
  padding: 4rem 0;

  .small-top-heading {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: var(--lbs-base-font-family), sans-serif;
    color: variables.$projectGrey;
  }

  .main-heading-with-intro {
    h1 {
      font-size: 6rem;
      line-height: 1;
      margin-bottom: 0;
    }

    p {
      font-size: 2rem;
    }
  }

  .tabs {
    margin-top: 8rem;

    :global(.react-tabs) {
      -webkit-tap-highlight-color: transparent;
    }

    :global(.react-tabs__tab-list) {
      border-bottom: 0.1rem solid #001e62;
      display: flex;
    }

    :global(.react-tabs__tab-panel) {
      display: none;
    }
    :global(.react-tabs__tab-panel--selected) {
      display: block;
    }

    :global(.react-tabs__tab) {
      display: inline-block;
      line-height: 1;
      margin-right: 4.2rem;
      margin-left: 0;
      margin-bottom: 0;
      cursor: pointer;

      font-size: 3rem;
      font-family: var(--lbs-base-font-family), sans-serif;
      padding: 0 0 1rem;
      text-align: left;
      font-weight: 500;
      color: #001e62;

      &:first-child {
        margin-left: 0;
      }
    }

    :global(.react-tabs__tab--selected) {
      border-bottom: 0.2rem solid #001e62;
    }

    :global(.react-tabs__tab-panel) {
      padding: 4rem 0;
    }

    :global(.cta) {
      width: auto;
      display: inline-flex;
    }
  }

  //   .js-tabs {
  //     .left-arrow-el {
  //       &::before {
  //         border: solid variables.$projectBlue;
  //         border-width: 0 0.4rem 0.4rem 0;
  //         left: -1.4rem;
  //       }
  //     }

  //     .right-arrow-el {
  //       &::after {
  //         border: solid variables.$projectBlue;
  //         border-width: 0 0.4rem 0.4rem 0;
  //         right: -1.4rem;
  //       }
  //     }
  //   }

  &.blue {
    margin: 0 -2rem;
    padding: 4rem 2rem;
    background-color: variables.$projectBlue;
    color: white;

    a {
      color: white;
    }

    .small-top-heading {
      color: variables.$projectInteractiveNavy;
    }

    .tabs {
      :global(.react-tabs__tab) {
        color: variables.$white;
      }
      :global(.react-tabs__tab--selected) {
        border-bottom: 0.3rem solid #7f8eb0;
      }
    }

    :global(.cta.download.secondary) {
      background-color: transparent;
      color: colors.$projectBone;
      border: 1px solid colors.$projectBone;

      &:focus,
      &:hover {
        background-color: colors.$projectBone;
        color: colors.$projectBlue;

        > span {
          &:first-child {
            svg {
              fill: colors.$projectBlue;
            }
          }

          &:last-child {
            border-left: 1px solid colors.$projectBlue;
          }
        }
      }

      span {
        &:first-child {
          svg {
            fill: colors.$projectBone;
          }
        }
        &:last-child {
          border-left: 1px solid colors.$projectBone;
        }
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .programme-benefits {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait}, #{variables.$mobile} {
  .programme-benefits {
    .small-top-heading {
      margin-bottom: 1.3rem;
    }

    .main-heading-with-intro {
      flex-direction: column;

      > p,
      > h1 {
        width: auto;
        padding-right: 0;
      }

      > h1 {
        margin-bottom: 2.5rem;
        font-size: 4rem;
        line-height: 1.2;
      }
    }

    .tabs {
      margin: 5rem 0 0;

      :global(.react-tabs__tab-panel) {
        padding: 2rem 0;
      }

      :global(.react-tabs__tab-list) {
        flex-direction: unset;
        overflow: scroll;
        margin-right: -2rem;
      }

      :global(.react-tabs__tab) {
        margin-right: 10%;
        border-bottom: 0;
        font-size: 1.8rem;
        background-color: transparent;

        &:last-of-type {
          padding-right: 4rem;
        }
      }
    }
  }
}
// MOBILE
@media #{variables.$mobile} {
  .programme-benefits {
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .programme-benefits {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .programme-benefits {
  }
}
