@import "../../styles/variables.scss";
// COMPONENT - C256 Dynamic pie

// DEFAULT CLASS - .dynamic-pie

// CLASS VARIANTS -

// WIDE WEB

.dynamic-pie {
  .container {
    display: flex;
  }

  .chart {
    flex-grow: 1;
    margin: 0 1rem;

    .circle {
      stroke: #7f8eb0;
      fill: none;
      stroke-width: 0.6;
      stroke-linecap: butt;
      stroke-dasharray: 0, 100;
      transition: stroke-array 1s;
      // animation: progress 1s ease-out forwards;
    }

    .two {
      stroke: $projectBlue;
      fill: none;
      stroke-width: 1.2;
      stroke-linecap: butt;
      stroke-dasharray: 0, 100;
    }

    &.animate {
      .circle {
        stroke-dasharray: 100, 100;
        animation: progress 1s ease-out forwards;
      }

      .two {
        //   stroke-dasharray: 80,100;
        animation: progress 1s ease-out forwards;
      }
    }
  }

  h4,
  h3,
  h2,
  p {
    text-align: center;
  }

  .graphic {
    position: relative;
  }

  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 26rem;
    max-height: 25rem;
    height: 25rem;
  }

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: 3.5rem;
      margin: 0;

      span {
        font-size: 5rem;
      }
    }
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  &[data-items="4"],
  &[data-items="5"] {
    .circular-chart {
      max-width: 16.5rem;
      max-height: 16.5rem;
    }

    .number {
      p {
        font-size: 2.5rem;
        line-height: 1;

        span {
          font-size: 3.5rem;
        }
      }
    }
  }

  &[data-items="5"] {
    .chart {
      max-width: 20.8rem;
    }
  }

  &[data-items="4"] {
    .chart {
      max-width: 26.5rem;
    }
  }

  &[data-items="3"] {
    .chart {
      max-width: 36rem;
    }
  }

  &[data-items="2"] {
    .chart {
      max-width: 55rem;
    }
  }

  .tns-outer {
    display: flex;
    flex-direction: column-reverse;

    .tns-nav {
      margin: 0 0 4rem;
      text-align: center;

      button {
        position: relative;
        width: 1.2em;
        height: 1.2rem;
        padding: 2.2rem;
        background: none;
        border: 0;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: 1px solid $projectBlue;
          background-color: transparent;
          width: 1.2em;
          height: 1.2rem;
          padding: 0;
        }

        &.tns-nav-active {
          &::after {
            background-color: $projectBlue;
          }
        }
      }
    }
  }

  &.multiple {
    margin-bottom: 4rem;

    .circular-chart {
      max-height: 44rem;
      max-width: 44rem;
      height: 44rem;
    }

    .hover {
      transition: opacity 0.3s;
      position: absolute;
      // background: url(/assets2019/img/caret-with-shadow.png) no-repeat;
      opacity: 0;
      z-index: 4;

      .inner {
        text-align: center;
        font-size: 1.2rem;
        background-color: white;
        z-index: 1;
        padding: 2rem 1.5rem;
        box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.14);
        color: #001e62;
        pointer-events: none;
        left: 3rem;
        top: 50%;
      }

      span {
        display: block;
        font-size: 3rem;
        margin-top: 0.5rem;
      }

      &.active {
        opacity: 1;
      }

      &::before {
        content: "";
        position: absolute;
        background: url("../../public/assets2019/img/caret-with-shadow.png")
          no-repeat;
        width: 3rem;
        height: 2rem;
        z-index: 5;
      }

      &.up {
        &::before {
          transform: translate(-50%, -40%) rotate(180deg);
          top: -1rem;
          left: 3rem;
        }

        .inner {
          left: 5.5rem;
          top: 4.5rem;
        }
      }

      &.left {
        &::before {
          left: -2.5rem;
          transform: rotate(90deg);
        }

        .inner {
          transform: translateY(-50%) translateY(1rem);
        }
      }

      &.right {
        transform: translateX(-100%);

        &::before {
          right: -2.5rem;
          transform: rotate(-90deg);
        }

        .inner {
          transform: translateY(-50%) translateY(1rem);
        }
      }

      &.down {
        transform: translate(-50%, -100%);

        &::before {
          bottom: -2rem;
          left: 50%;
          transform: translateX(-50%);
        }

        .inner {
          left: 56px;
          top: -2.5rem;
        }
      }
    }

    &[data-items="1"] {
      .chart {
        display: flex;

        .list {
          align-items: center;
          margin-top: 0;
          margin-left: 5rem;
        }

        .number {
          p,
          h3 {
            font-size: 3.6rem;
            max-width: 30rem;
          }
        }
      }

      .circular-chart {
        max-height: 58rem;
        max-width: 58rem;
        height: 58rem;
        width: 58rem;
        margin-left: 0;
      }

      .number {
        left: 29rem;
      }
    }

    .chart {
      flex-grow: 1;
      margin: 0 1rem;

      .number {
        p,
        h3 {
          font-size: 3rem;
          font-family: var(--lbs-heading-font-family), serif;
          max-width: 25rem;
          margin-bottom: 0;
        }
      }

      .list {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
      }

      ul {
        li {
          position: relative;
          padding-left: 3rem;

          i {
            width: 2rem;
            height: 2rem;
            background-color: grey;
            position: absolute;
            border-radius: 50%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .circle {
        stroke: #e6f1fa;
        stroke-width: 6;
        stroke-dasharray: 0, 100;

        &.blank {
          stroke: $projectBone;
        }

        &.hov {
          stroke: transparent;
          stroke-dasharray: 100, 100;
        }

        &.two {
          stroke: #cce3f5;
        }

        &.three {
          stroke: #b3d5f0;
        }

        &.four {
          stroke: #99c7eb;
        }

        &.five {
          stroke: #80b9e7;
        }

        &.six {
          stroke: #66aae2;
        }

        &.seven {
          stroke: #4d9cdd;
        }

        &.eight {
          stroke: #338ed8;
        }

        &.nine {
          stroke: #1a80d3;
        }

        &.ten {
          stroke: #0072ce;
        }
      }

      &.purple {
        .circle {
          stroke: #eeecf3;

          &.hov {
            stroke: transparent;
            stroke-dasharray: 100, 100;
          }

          &.two {
            stroke: #dcdae7;
          }

          &.three {
            stroke: #cbc7dc;
          }

          &.four {
            stroke: #b9b5d0;
          }

          &.five {
            stroke: #a8a2c4;
          }

          &.six {
            stroke: #9790b8;
          }

          &.seven {
            stroke: #857dac;
          }

          &.eight {
            stroke: #746ba1;
          }

          &.nine {
            stroke: #625895;
          }

          &.ten {
            stroke: #514689;
          }
        }
      }

      &.amber {
        .circle {
          stroke: rgba(#dc582a, 0.1);

          &.blank {
            stroke: $projectBone;
          }

          &.hov {
            stroke: transparent;
            stroke-dasharray: 100, 100;
          }

          &.two {
            stroke: rgba(#dc582a, 0.2);
          }

          &.three {
            stroke: rgba(#dc582a, 0.3);
          }

          &.four {
            stroke: rgba(#dc582a, 0.4);
          }

          &.five {
            stroke: rgba(#dc582a, 0.5);
          }

          &.six {
            stroke: rgba(#dc582a, 0.6);
          }

          &.seven {
            stroke: rgba(#dc582a, 0.7);
          }

          &.eight {
            stroke: rgba(#dc582a, 0.8);
          }

          &.nine {
            stroke: rgba(#dc582a, 0.9);
          }

          &.ten {
            stroke: rgba(#dc582a, 1);
          }
        }
      }

      &.green {
        .circle {
          stroke: rgba(#00685e, 0.1);

          &.blank {
            stroke: $projectBone;
          }

          &.hov {
            stroke: transparent;
            stroke-dasharray: 100, 100;
          }

          &.two {
            stroke: rgba(#00685e, 0.2);
          }

          &.three {
            stroke: rgba(#00685e, 0.3);
          }

          &.four {
            stroke: rgba(#00685e, 0.4);
          }

          &.five {
            stroke: rgba(#00685e, 0.5);
          }

          &.six {
            stroke: rgba(#00685e, 0.6);
          }

          &.seven {
            stroke: rgba(#00685e, 0.7);
          }

          &.eight {
            stroke: rgba(#00685e, 0.8);
          }

          &.nine {
            stroke: rgba(#00685e, 0.9);
          }

          &.ten {
            stroke: rgba(#00685e, 1);
          }
        }
      }
    }
  }
}

:global(.blue) {
  .dynamic-pie {
    h2,
    h3,
    h4,
    p {
      color: white;
    }

    .two {
      stroke: white;
    }

    .tns-outer {
      .tns-nav {
        button {
          &::after {
            border: 1px solid white;
            background-color: transparent;
          }

          &.tns-nav-active {
            &::after {
              background-color: white;
            }
          }
        }
      }
    }
  }
}

.base6 {
  > .dynamic-pie {
    &.multiple {
      &[data-items="1"] {
        .number {
          left: 50%;
        }

        .chart {
          flex-direction: column;
        }

        .circular-chart {
          max-width: 100%;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{$compact-web} {
  .dynamic-pie {
  }
}

// TABLET PORTRAIT
@media #{$tablet} {
  .dynamic-pie {
    &.multiple {
      .hover {
        span {
          display: block;
          font-size: 2rem;
          margin-top: 0.5rem;
          font-weight: bold;
        }

        &.active {
          opacity: 1;
        }

        &::before {
          content: "";
          position: absolute;
          background: url("../../public/assets2019/img/caret-with-shadow.png")
            no-repeat;
          width: 3rem;
          height: 2rem;
          z-index: 5;
        }

        &.left {
          transform: translateX(-100%);

          &::before {
            left: auto;
            right: -2.5rem;
            transform: rotate(-90deg);
          }

          .inner {
            transform: translateY(-50%) translateY(1rem);
          }
        }

        &.right {
          transform: none;

          &::before {
            right: auto;
            left: -2.5rem;
            transform: rotate(90deg);
          }

          .inner {
            transform: translateY(-50%) translateY(1rem);
          }
        }
      }
    }
  }
}

@media #{$tablet-portrait} {
  .dynamic-pie {
    &.multiple {
      .container {
        flex-direction: column;
      }

      &[data-items="2"] {
        .chart {
          max-width: 100%;
        }
      }

      &[data-items="1"] {
        .chart {
          display: block;

          .list {
            align-items: center;
            margin-top: 3rem;
            margin-left: 0;
          }

          .number {
            p,
            h3 {
              font-size: 2.4rem;
              max-width: 21rem;
            }
          }
        }

        .circular-chart {
          margin-left: auto;
        }

        .number {
          left: 50%;
        }
      }
    }
  }
}

// MOBILE
@media #{$mobile} {
  .dynamic-pie {
    .container {
      display: block;

      .chart {
        margin: 0;
      }
    }

    &[data-items="2"] {
      .chart {
        &:first-child {
          margin-bottom: 4rem;
        }
      }
    }

    &[data-items="4"],
    &[data-items="5"] {
      .circular-chart {
        max-width: 26rem;
      }

      .number {
        p {
          font-size: 3.5rem;
          line-height: 1;

          span {
            font-size: 5rem;
          }
        }
      }
    }

    &[data-items="2"],
    &[data-items="3"],
    &[data-items="4"],
    &[data-items="5"] {
      .chart {
        max-width: 100%;
      }
    }

    &.multiple {
      .circular-chart {
        height: 33rem;
      }

      &[data-items="1"] {
        .chart {
          display: block;

          .list {
            align-items: center;
            margin-top: 3rem;
            margin-left: 0;
          }

          .number {
            p,
            h3 {
              font-size: 2.4rem;
              max-width: 21rem;
            }
          }
        }

        .circular-chart {
          margin-left: auto;
          height: 33rem;
          width: 33rem;
        }

        .number {
          left: 50%;
        }
      }

      .chart {
        .list {
          ul {
            width: 100%;
            margin-left: 2rem;
          }
        }

        .number {
          p,
          h3 {
            font-size: 2.4rem;
            max-width: 21rem;
          }
        }
      }

      .hover {
        transition: opacity 0.3s;
        position: absolute;
        // background: url(/assets2019/img/caret-with-shadow.png) no-repeat;
        opacity: 0;
        z-index: 4;

        .inner {
          text-align: center;
          font-size: 1.2rem;
          background-color: white;
          z-index: 1;
          padding: 2rem 1.5rem;
          box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.14);
          color: #001e62;
          pointer-events: none;
          left: 3rem;
          top: 50%;
        }

        span {
          display: block;
          font-size: 2rem;
          margin-top: 0.5rem;
          font-weight: bold;
        }

        &.active {
          opacity: 1;
        }

        &::before {
          content: "";
          position: absolute;
          background: url("../../public/assets2019/img/caret-with-shadow.png")
            no-repeat;
          width: 3rem;
          height: 2rem;
          z-index: 5;
        }

        &.left {
          transform: translateX(-100%);

          &::before {
            left: auto;
            right: -2.5rem;
            transform: rotate(-90deg);
          }

          .inner {
            transform: translateY(-50%) translateY(1rem);
          }
        }

        &.right {
          transform: none;

          &::before {
            right: auto;
            left: -2.5rem;
            transform: rotate(90deg);
          }

          .inner {
            transform: translateY(-50%) translateY(1rem);
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .dynamic-pie {
  }
}

// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .dynamic-pie {
  }
}
