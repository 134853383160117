@use "../../styles/variables.scss";

.student-details {
  h1 {
    @include variables.line-height(68, 60);
    font-size: 6rem;
  }

  .alumni-list-details {
    border-bottom: solid 0.1rem variables.$projectDarkBone;
    list-style: none;
    margin: 0 0 2.4rem;
    padding-bottom: 3.6rem;

    .scholarship {
      a {
        text-decoration: underline;
        text-decoration-thickness: 0.1rem;

        &:hover {
          text-decoration-thickness: 0.2rem;
        }
      }
    }

    a {
      border-bottom: 0;
      font-weight: normal;
      text-decoration: none;

      &:hover {
        border-bottom: 0;
        text-decoration: underline;
      }
    }

    li {
      margin-bottom: 0.8rem;
      padding-left: 3.5rem;
      position: relative;

      &::before {
        display: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    i {
      left: 0;
      position: absolute;
      top: -0.2rem;
      background-image: none;
      width: 24px;
      height: 24px;

      svg {
        fill: variables.$projectInteractiveNavy;
        vertical-align: middle;
      }
    }
  }

  .rich-text {
    img {
      float: none;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .student-details {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .student-details {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .student-details {
    .alumni-list-details {
      padding-top: 0;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .student-details {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .student-details {
  }
}
