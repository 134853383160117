@import "../../../styles/variables.scss";

.publication-individual-page {
  padding: 4rem 0 8rem;

  h1 {
    @include line-height(60, 48);
    font-size: 4.8rem;
    margin-bottom: 3.6rem;
  }

  .tertiary {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

// COMPACT WEB
@media #{$compact-web} {
  .publication-individual-page {
  }
}

// TABLET PORTRAIT
@media #{$tablet-portrait} {
  .publication-individual-page {
  }
}

// MOBILE
@media #{$mobile} {
  .publication-individual-page {
    padding-bottom: 2rem;

    h1 {
      @include line-height(38, 30);
      font-size: 3rem;
      margin-bottom: 0;
    }
  }
}

// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .publication-individual-page {
  }
}

// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .publication-individual-page {
  }
}
