@use "../../styles/variables.scss";

.profile-photo-link-box {
  background: variables.$projectWhite;
  margin-bottom: 2.4rem;
  padding: 0;
  max-width: 768px;

  picture,
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .link-list {
    padding: 2.4rem;

    a {
      font-weight: normal;
      text-decoration: none;
      word-wrap: break-word;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }

    li {
      margin-bottom: 2.4rem;
      padding-left: 3rem;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }
    }

    i {
      left: 0;
      position: absolute;
      top: -0.3rem;
      width: 24px;
      height: 24px;

      svg {
        fill: variables.$projectInteractiveNavy;
        vertical-align: middle;
      }
    }
  }
}

.lightbox {
  display: block;
  box-sizing: border-box;
  width: 80%;
  max-width: 944px;
  padding: 6rem 12rem 4rem;
  background-color: variables.$projectWhite;
  height: 90vh;
  max-height: 460px;

  > div {
    height: 100%;
  }

  :global(.close) {
    z-index: 1000;
    top: 20px;
    right: 20px;
  }

  picture,
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .profile-photo-link-box {
    .link-list {
      padding: 2.4rem 1rem;

      li {
        padding-left: 2rem;
        font-size: 1.3rem;
      }

      i {
        width: 16px;
        height: 16px;
        top: 0;
      }
    }
  }
}
