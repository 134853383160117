@use "../../styles/variables.scss";

.profile-applications {
  .application {
    border-bottom: 0.1rem solid variables.$projectDarkBone;
    margin-bottom: 5.5rem;
    padding-bottom: 4rem;

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .section-title {
    font-size: 3rem;
    line-height: 3.8rem;
  }

  .details {
    margin-bottom: 4rem;

    li {
      margin-bottom: 0.8rem;
    }
  }

  .action {
    margin-top: 3.8rem;
    width: 25.6rem;
  }

  a {
    width: 22.1rem;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .profile-applications {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .profile-applications {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .profile-applications {
    .application {
      margin-bottom: 3.5rem;
      padding-bottom: 1.9rem;
    }

    .section-title {
      margin-bottom: 1.3rem;
    }

    .details {
      margin-bottom: 2rem;

      li {
        margin-bottom: 2.4rem;

        strong {
          display: block;
          width: 100%;
        }
      }

      + p {
        strong {
          display: block;
          width: 100%;
        }
      }
    }

    .action {
      margin-top: 3rem;
      width: 100%;
    }

    a {
      width: 22.1rem;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .profile-applications {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .profile-applications {
  }
}
