@use "../../styles/variables.scss";
// COMPONENT - C44 Contact finder

// DEFAULT CLASS - .contact-us

// CLASS VARIANTS -

// WIDE WEB
.job-board {
  margin-bottom: 8rem;

  &.form {
    .section-heading,
    .sub-heading {
      color: variables.$projectBlue;
      font-family: var(--lbs-heading-font-family), serif;
      font-size: 3rem;
      line-height: 3.8rem;
      margin-bottom: 2.4rem;
    }

    .fields-floated {
      .field-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.6rem;

        &.edit-details {
          padding-bottom: 3.4rem;
          margin-bottom: 3.9rem;
          margin-top: 3.4rem;
          border-bottom: 0.1rem solid variables.$projectLightGrey;

          a {
            width: 22.1rem;
          }
        }

        label {
          flex-basis: 25%;
          max-width: 25%;
          color: variables.$projectBlue;
          font-family: var(--lbs-base-font-family), sans-serif;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 700;
          padding-right: 1rem;

          + p {
            flex-basis: 75%;
            max-width: 75%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .btn-and-text-wrapper {
    margin-top: 30px;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .job-board {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .job-board {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .job-board {
    &.form {
      .fields-floated {
        .field-row {
          flex-direction: column;

          label {
            flex-basis: 100%;
            max-width: 100%;

            + p {
              flex-basis: 100%;
              max-width: 100%;
            }
          }

          &.edit-details {
            a {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .job-board {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .job-board {
  }
}
