@use "../../styles/variables";
// COMPONENT - C72 Programme details bar

// DEFAULT CLASS - .programme-details-bar

// CLASS VARIANTS -

// WIDE WEB

.programme-details-bar {
  background-color: variables.$projectBlue;
  margin-left: -2rem;
  width: calc(100% + 4rem);
  color: white;
  padding: 3rem 2rem 0;
  //margin-bottom: 4rem;

  > .mobile-toggler {
    display: none;
  }

  :global(.slick-track) {
    min-height: fit-content;
  }

  :global(.slick-list) {
    padding-bottom: 5rem;
  }

  :global(.slick-dots) {
    bottom: 5px;
  }

  > .wrapper {
    padding-bottom: 3rem;

    &.sticky {
      > .toggle_available_sessions {
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 1.2rem;

        &.sessionsOpen {
          border-bottom: 1px solid;
          padding-bottom: 2.5rem;
          margin-bottom: 2.5rem;
        }
      }

      > .row-middle {
        .select-container {
          .headers,
          .selection {
            display: flex;
            position: relative;

            p {
              &:nth-child(1) {
                width: 57%;

                &.hybrid {
                  width: 52%;
                }
              }

              &:nth-child(2) {
                width: 25%;
                padding-left: 6rem;

                &.hybrid {
                  padding-left: 6.3rem;
                }
              }

              &:nth-child(3) {
                width: 20%;

                &.hybrid {
                  width: 20%;
                  padding-left: 1rem;
                }
              }
            }
          }

          .selection-second-variant {
            div {
              display: flex;
              position: relative;
              align-items: flex-end;
            }

            p {
              &:nth-child(1) {
                width: 60%;
              }

              &:nth-child(2) {
                width: 20%;
                padding-left: 2.5rem;
              }

              &:nth-child(3) {
                width: 20%;
              }
            }
          }
        }
      }
    }

    > .toggle_available_sessions {
      display: none;

      > .toggle-link-for-available-sessions {
        display: block;

        position: relative;
        color: white;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.5s ease-out;

        &::after,
        &::before {
          content: "";
          position: absolute;
          right: -4rem;
          top: 50%;
          width: 1.3rem;
          height: 2px;
          background: white;
          transform: translateY(-50%);
          transition: all 0.5s ease-out;
        }

        &::after {
          transform: translateY(-50%) rotate(90deg);
        }

        &[aria-expanded="true"] {
          color: white;

          &::before {
            opacity: 0;
          }

          &::after {
            transform: rotate(0) translateY(-50%);
          }
        }
      }
    }
  }

  .message-wrapper {
    // @include message-popup;

    position: static;

    .message {
      right: -1rem;
    }
  }

  .select,
  .selection {
    outline: none;
  }

  .select {
    &:focus {
      .selection {
        box-shadow: 0 0 2px 2px #51a7e8;
      }
    }
  }

  .headers,
  .selection,
  .data {
    display: flex;
    position: relative;
    align-items: flex-end;

    &.mobile {
      display: none;
      align-items: stretch;
    }

    p {
      &:nth-child(1) {
        width: 60%;

        &.hybrid {
          width: 55%;
        }
      }

      &:nth-child(2) {
        width: 18%;

        &.hybrid {
          margin-left: 0rem !important;
          padding-left: 0.5rem;
        }
      }

      &:nth-child(3) {
        width: 20%;

        &.hybrid {
          width: 28%;
        }
      }
    }
  }

  .selection-second-variant {
    div {
      display: flex;
      position: relative;
      align-items: flex-end;
    }

    p {
      &:nth-child(1) {
        width: 60%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }
    }
  }

  .data-second-variant {
    &.mobile {
      display: none;
    }

    div {
      display: flex;
      position: relative;
      //align-items: flex-end;
    }

    p {
      &:nth-child(1) {
        width: 55%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }
    }
  }

  .select-container {
    .headers,
    .selection,
    .data {
      display: flex;
      position: relative;

      p {
        &:nth-child(1) {
          width: 60%;

          &.hybrid {
            //width: 30%;
          }
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 20%;

          &.hybrid {
            //width: 40%;
          }
        }
      }
    }

    .accordioneed {
      margin-top: 15px;

      p {
        display: none;
      }

      > .toggle-link {
        display: block;
        width: 29%;
        position: relative;
        color: white;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        transition: all 0.5s ease-out;

        &::after,
        &::before {
          content: "";
          position: absolute;
          right: 3rem;
          top: 50%;
          width: 1.3rem;
          height: 2px;
          background: white;
          transform: translateY(-50%);
          transition: all 0.5s ease-out;
        }

        &::after {
          transform: translateY(-50%) rotate(90deg);
        }

        &[aria-expanded="true"] {
          color: white;

          &::before {
            opacity: 0;
          }

          &::after {
            transform: rotate(0) translateY(-50%);
          }
        }
      }
    }

    .headers,
    .selection {
      span {
        position: absolute;
        right: 0;
        bottom: 0.8rem;

        svg {
          width: 1.2rem;
          height: 1.2rem;
          transform: rotate(90deg);
          right: 0;
          fill: white;
        }
      }
    }

    .headers {
      p {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .selection {
      padding-bottom: 0.5rem;

      cursor: pointer;

      p {
        margin: 0;
      }
    }
  }

  .sticky.options {
    margin: 0;
  }

  .options {
    background-color: variables.$projectDarkBlue;
    margin-top: 3rem;
    pointer-events: none;
    visibility: hidden;
    width: 100vw;
    margin: 0 -3rem;
    padding: 0 2rem;
    height: 0;
    overflow: hidden;
    transition:
      height 0.5s,
      visibility 0.5s;
    transition-delay: visibility 0.5s;

    .option-inner {
      position: relative;

      .desktop-only {
        width: 100%;
      }
    }

    .module-paragraph {
      width: 100%;
    }

    .inner-border {
      width: 50%;
      position: relative;
      padding: 1rem 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }

    .option {
      position: relative;
      padding: 2rem 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);

      &:only-child {
        border-bottom: 0;
      }

      &.hybrid {
        // width:56%;

        &.notsame {
          width: 90%;
        }
      }

      .buttons {
        display: none;
      }

      p {
        margin: 0 0 1rem 0;
      }

      &.full {
        p {
          color: #80899f;
          font-weight: normal;
        }
      }

      .data {
        .title {
          display: none;
        }

        p:nth-child(2) {
          margin-left: 10rem;
        }
      }

      .data-second-variant {
        .title {
          display: none;
        }

        p:nth-child(2) {
        }
      }

      .availability {
        position: absolute;
        right: 0;
        transform: translateX(100%) translateX(4rem) translateY(-2.4rem);

        p {
          &:last-child {
            display: none;
          }
        }
      }

      &.selected {
        display: none;
      }

      // &:first-child {
      //     border-top: 1px solid transparent;

      //     &.selected {
      //         + .option {
      //             border-top: 1px solid transparent;
      //         }
      //     }
      // }

      // &:not(.full) {
      &:hover {
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          width: 100vw;
          background-color: rgba(255, 255, 255, 0.1);
          height: calc(100% + 2px);
          top: -1px;
          left: calc(((1084px - 100vw) / 2) + 30px);
        }
      }
      // }
    }
  }

  p {
    &.note {
      font-size: 1.2rem;
      padding: 2rem 0 1rem;
    }
  }

  .buttons {
    display: flex;
    margin: 0 0 0 2rem;
    align-items: center;
    justify-content: space-between;

    > p {
      display: none;
    }

    &.full {
      > p {
        display: block;
      }

      > .cta {
        &:first-of-type {
          display: none;
        }
      }
    }

    .cta {
      width: auto;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .information {
      position: relative;
    }

    svg {
      fill: white;
      cursor: pointer;
    }
  }

  &.show {
    .options {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }
  }

  .link-switch {
    display: none;
  }

  .tns-outer {
    margin-left: -1rem;
  }

  // sass-lint:disable no-important
  .tns-item {
    padding-right: 1rem !important;
    padding-left: 1.2rem !important;
  }

  .tns-ovh {
    overflow: visible;
  }

  &.two {
    .buttons {
      justify-content: flex-start;
      height: 100%;

      a {
        &:first-child {
          margin-bottom: 3rem;
        }
      }

      > * {
        margin-right: 2rem;
      }
    }
  }
  .smallText {
    font-size: 1.2rem;
  }

  :global(.cta.tertiary) {
    margin-left: 0;
    margin-left: 10px;
    text-align: left;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .programme-details-bar {
  }
}

@media all and (min-width: 1024px) {
  // sass-lint:disable no-important
  .buttons {
    transform: none !important;
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet} {
  .programme-details-bar {
    .select-container {
      .select {
        //border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        position: relative;
      }

      .headers,
      .selection {
        max-width: calc(75% + -1rem);
        border-bottom: 0;
        position: static;
      }
    }

    .buttons {
      margin: 2rem 0 0;
      transition: transform 0.5s;

      .cta,
      .information {
        margin-right: 2rem;

        &.tertiary {
          margin-left: auto;
        }
      }
    }

    .options {
      margin: 0 -2rem;
      // transform: translateY(-98px);
    }

    &.show {
      .select {
        border-bottom: 0;
        position: relative;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .programme-details-bar {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile} {
  .programme-details-bar {
    background-color: variables.$projectBone;
    padding: 0 0 0 1rem;
    margin-top: -8rem;
    margin-bottom: -4rem;

    .options {
    }

    .accordioneed {
      display: none;
    }

    &.sticky-mobile {
      padding-left: 0;

      &.show {
        .options {
          display: block;
          transform: translateY(-98px);
        }

        > .mobile-toggler {
          > .tick {
            svg {
              transform: rotate(270deg);
            }
          }
        }
      }

      .options {
        display: none;
      }
    }

    > .mobile-toggler {
      &.sticky-mobile {
        background-color: #001e62;
        display: block;
        height: 65px;
        margin-bottom: 40px !important;
        margin-top: 37px;
        font-weight: 900;

        padding-top: 2.5rem;
        padding-left: 2rem;
        font-size: 1.5rem;
      }

      > .tick {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;

        svg {
          transform: rotate(90deg);
          fill: white;
          width: 0.8rem;
          margin-right: 4rem;
          margin-top: 2.25rem;
        }
      }
    }

    > .wrapper {
      > .toggle_available_sessions {
        display: none;
      }

      > .row-middle {
        display: none !important;
      }
    }

    .tns-ovh {
      display: flex;
      flex-direction: column-reverse;
      overflow: hidden;

      .tns-nav {
        margin-top: -6rem;
        margin-bottom: 0.5rem;
        text-align: center;
        width: 100%;

        button {
          position: relative;
          width: 1.2em;
          height: 1.2rem;
          padding: 1rem;
          background: none;
          border: 0;
          pointer-events: none;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 1px solid variables.$projectBlue;
            background-color: transparent;
            width: 1.2em;
            height: 1.2rem;
            padding: 0;
          }

          &.tns-nav-active {
            &::after {
              background-color: variables.$projectBlue;
            }
          }
        }
      }
    }

    .select-container {
      .headers,
      .selection {
        display: none;
      }
    }

    // sass-lint:disable no-important
    .options {
      height: auto !important;
      position: static;
      opacity: 1;
      pointer-events: auto;
      background-color: transparent;
      padding: 0 3rem 0 0;
      visibility: visible;
      margin: 0;
      overflow: visible;

      .row {
        margin-bottom: 8rem;
      }

      .option {
        padding: 0;
        background-color: transparent;

        &:hover {
          &::after {
            content: none;
          }
        }

        &.full {
          .cta {
            &:not(.tertiary) {
              display: none;
            }
          }
        }

        .option-inner {
          background-color: variables.$projectBlue;
          padding: 2rem;
          min-height: 346px;
        }

        .data {
          p {
            display: none;
          }

          &.mobile {
            display: block;
            flex-wrap: wrap;
            display: flex;

            p {
              display: block;
            }

            p {
              //width: 50%;
              font-size: 1.2rem;

              &:nth-child(1) {
                width: 100%;
                font-size: 1.2rem;

                .title,
                .value-mobile {
                  display: inline-block;
                }

                .value-mobile {
                  margin-left: 4px;
                }
              }

              &:nth-child(2) {
                width: 50%;
                padding-right: 10px;
                margin-left: 0;
              }

              &:nth-child(3) {
                width: 50%;
                font-size: 1.2rem;
              }

              &.inner-border {
                width: 50%;
                position: relative;
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid rgba(255, 255, 255, 0.25);
              }
            }

            .title {
              display: block;
              font-weight: bold;
            }

            .value-mobile {
              display: block;
            }
          }
        }

        .data-second-variant {
          div {
            &:nth-child(1) {
              display: none;
            }

            &:nth-child(2) {
              display: none;
            }

            &:nth-child(3) {
              display: none;
            }
            &:nth-child(4) {
              display: none;
            }
            &:nth-child(5) {
              display: none;
            }
          }

          &.mobile {
            display: block;

            flex-wrap: wrap;
            display: flex;
            position: relative;
            align-items: stretch;

            p {
              //width: 50%;
              font-size: 1.2rem;

              &:nth-child(1) {
                width: 100%;
                font-size: 1.2rem;
              }

              &:nth-child(2) {
                width: 50%;
                margin-left: 0;
              }

              &:nth-child(3) {
                width: 50%;
                font-size: 1.2rem;
              }
            }

            .title {
              display: block;
              font-weight: bold;
            }

            .value-mobile {
              display: block;
            }
          }
        }

        .availability {
          position: static;
          transform: none;
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          padding-top: 1.5rem;

          p {
            &:last-child {
              font-size: 1.2rem;
              margin-top: 1.5rem;
              display: block;
            }
          }
        }

        .buttons {
          display: flex;
          flex-wrap: wrap;
          margin: 1rem 0 0;

          .cta {
            &:not(.tertiary) {
              width: 100%;
              margin-bottom: 2rem;
            }
          }
        }
      }
    }

    p {
      &.note {
        display: none;
      }
    }

    .buttons {
      display: none;
    }

    .link-switch {
      display: block;
      text-align: center;
      margin: 1rem 0 3rem;
    }
  }
}

//iphone plus
@media #{variables.$mobile} {
  .programme-details-bar {
    background-color: variables.$projectBone;
    padding: 0 0 0 1rem;
    margin-top: -8rem;

    &.sticky-mobile {
      padding-left: 0;

      &.show {
        .options {
          display: block;
          padding: 4rem 0rem 0rem 3rem;
        }

        > .mobile-toggler {
          > .tick {
            svg {
              transform: rotate(270deg);
            }
          }
        }
      }

      .options {
        display: none;
      }
    }

    > .mobile-toggler {
      &.sticky-mobile {
        background-color: #001e62;
        display: block;
        height: 65px;
        margin-bottom: 40px;
        margin-top: 37px;
        font-weight: 900;

        padding-top: 2.5rem;
        padding-left: 2rem;
        font-size: 1.5rem;
      }

      > .tick {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;

        svg {
          transform: rotate(90deg);
          fill: white;
          width: 0.8rem;
          margin-right: 4rem;
          margin-top: 2.25rem;
        }
      }
    }

    > .wrapper {
      > .toggle_available_sessions {
        display: none;
      }

      > .row-middle {
        display: none !important;
      }
    }

    .tns-ovh {
      display: flex;
      flex-direction: column-reverse;
      overflow: hidden;

      .tns-nav {
        margin-top: -6rem;
        margin-bottom: 0.5rem;
        text-align: center;
        width: 100%;

        button {
          position: relative;
          width: 1.2em;
          height: 1.2rem;
          padding: 1rem;
          background: none;
          border: 0;
          pointer-events: none;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 1px solid variables.$projectBlue;
            background-color: transparent;
            width: 1.2em;
            height: 1.2rem;
            padding: 0;
          }

          &.tns-nav-active {
            &::after {
              background-color: variables.$projectBlue;
            }
          }
        }
      }
    }

    .select-container {
      .headers,
      .selection {
        display: none;
      }
    }

    // sass-lint:disable no-important
    .options {
      height: auto !important;
      position: static;
      opacity: 1;
      pointer-events: auto;
      background-color: transparent;
      padding: 0 3rem 0 0;
      visibility: visible;
      margin: 0;
      overflow: visible;

      .row {
        margin-bottom: 8rem;
      }

      &.sticky {
        margin-top: 9rem;
        margin-bottom: -19rem;
      }

      .option {
        padding: 0;
        background-color: transparent;

        &:hover {
          &::after {
            content: none;
          }
        }

        &.full {
          .cta {
            &:not(.tertiary) {
              display: none;
            }
          }
        }

        .option-inner {
          background-color: variables.$projectBlue;
          padding: 2rem;
          min-height: 346px;
        }

        .data {
          p {
            display: none;
          }

          &.mobile {
            display: block;
            flex-wrap: wrap;
            display: flex;

            p {
              display: block;
            }

            p {
              //width: 50%;
              font-size: 1.2rem;

              &:nth-child(1) {
                width: 100%;
                font-size: 1.2rem;
              }

              &:nth-child(2) {
                width: 50%;
                margin-left: 0;
              }

              &:nth-child(3) {
                width: 50%;
                font-size: 1.2rem;
              }
            }

            .title {
              display: block;
              font-weight: bold;
            }

            .value-mobile {
              display: block;
            }
          }
        }

        .data-second-variant {
          div {
            &:nth-child(1) {
              display: none;
            }

            &:nth-child(2) {
              display: none;
            }

            &:nth-child(3) {
              display: none;
            }

            &:nth-child(4) {
              display: none;
            }

            &:nth-child(5) {
              display: none;
            }
          }

          &.mobile {
            display: block;

            flex-wrap: wrap;
            display: flex;
            position: relative;
            align-items: flex-end;

            p {
              //width: 50%;
              font-size: 1.2rem;

              &:nth-child(1) {
                width: 100%;
                font-size: 1.2rem;
              }

              &:nth-child(2) {
                width: 50%;
                margin-left: 0;
              }

              &:nth-child(3) {
                width: 50%;
                font-size: 1.2rem;
              }
            }

            .title {
              display: block;
              font-weight: bold;
            }

            .value-mobile {
              display: block;
            }
          }
        }

        .availability {
          position: static;
          transform: none;
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          padding-top: 1.5rem;

          p {
            &:last-child {
              font-size: 1.2rem;
              margin-top: 1.5rem;
              display: block;
            }
          }
        }

        .buttons {
          display: flex;
          flex-wrap: wrap;
          margin: 3rem 0 0;

          .cta {
            &:not(.tertiary) {
              width: 100%;
              margin-bottom: 3rem;
            }
          }
        }
      }
    }

    p {
      &.note {
        display: none;
      }
    }

    .buttons {
      display: none;
    }

    .link-switch {
      display: block;
      text-align: center;
      margin: 1rem 0 3rem;
    }
  }
}
