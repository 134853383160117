@use "../../styles/variables.scss";
// COMPONENT - C01.2 Search

// DEFAULT CLASS - .search

// CLASS VARIANTS -

// WIDE WEB

.search {
  background: #001440;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  visibility: hidden;

  &.show {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  .inside {
    width: 100%;
    max-width: 1140px;
    padding: 2rem;
  }

  .helper {
    color: variables.$projectInteractiveNavy;
    font-size: 1.2rem;
  }

  .input {
    padding-bottom: 1rem;
    border-bottom: 1px solid variables.$projectInteractiveNavy;
    margin-bottom: 6rem;
    position: relative;
    margin-top: 3rem;
  }

  input {
    &[type="text"] {
      background: 0;
      border: 0;
      color: variables.$projectInteractiveNavy;
      font-size: 4.8rem;
      font-family: var(--lbs-heading-font-family), serif;

      &::placeholder {
        color: variables.$projectInteractiveNavy;
      }
    }
  }

  button {
    border: 0;
    background: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding-right: 0.1rem;

    svg {
      fill: white;
      width: 3rem;
      height: 3rem;
    }

    &:hover,
    &:focus {
      svg {
        fill: variables.$projectInteractiveNavy;
      }
    }
  }

  .links {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;

      li {
        margin-right: 10rem;
        margin-bottom: 2rem;
      }
    }

    .cta {
      font-weight: bold;
    }
  }

  .close {
    width: 2rem;
    margin-left: 4rem;
    cursor: pointer;
    font-size: 0;
    height: 1.6rem;
    right: 3rem;
    top: 2rem;
    position: absolute;

    &:active,
    &:focus,
    &:hover {
      svg {
        fill: variables.$projectInteractiveNavy;
      }
    }

    svg {
      fill: #fff;
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .search {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .search {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .search {
    .inside {
      max-width: 100%;
      padding: 2rem;

      input {
        &[type="text"] {
          font-size: 2.8rem;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .search {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .search {
  }
}
