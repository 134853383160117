@use "../../styles/variables.scss";

.donate-tool-vertical-accordion,
.vertical-accordion {
  background: variables.$projectBone;
  padding: 0;

  @at-root .white-grey-split & {
    background: variables.$projectWhite;
    margin: 0 5rem 0 0.7rem;

    h4 {
      font-size: 3rem;
    }
  }

  .donate-tool-accordion,
  .accordion {
    > li {
      overflow: hidden;
      width: auto;
      border-width: 0;
      margin: 0;
      display: block;
      clear: both;
      box-sizing: border-box;
      border-top: 1px solid #9b9baf;

      &:last-child {
        border-bottom: 1px solid #9b9baf;
      }

      > .toggle-link {
        display: block;
        padding: 2rem 5rem 2rem 2rem;
        position: relative;
        color: variables.$projectBlue;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.5s ease-out;

        &::after,
        &::before {
          content: "";
          position: absolute;
          right: 3rem;
          top: 50%;
          width: 1.3rem;
          height: 2px;
          background: variables.$projectBlue;
          transform: translateY(-50%);
          transition: all 0.5s ease-out;
        }

        &::after {
          transform: translateY(-50%) rotate(90deg);
        }

        &:active,
        &:hover {
          color: variables.$projectBlue;
          background-color: #d7d2cb;
        }

        &[aria-expanded="true"] {
          color: variables.$projectBlue;

          &::before {
            opacity: 0;
          }

          &::after {
            transform: rotate(0) translateY(-50%);
          }
        }
      }

      .donate-tool-accordion-drop,
      .accordion-drop {
        height: 0;
        transition: height 0.3s;
        padding: 0 2rem;

        &[aria-hidden="true"] {
          visibility: hidden;
        }

        .content {
          overflow: hidden;
          padding-left: 0.2rem; // stops heading text inside from being cut off
        }
      }
    }
  }
}

:global(.outer-wrap):global(.white) {
  .vertical-accordion {
    background-color: variables.$projectWhite;
  }

  .accordion {
    > li {
      > .toggle-link {
        &:hover {
          background: #e5e8ef;
        }
      }
    }
  }
}

:global(.outer-wrap):global(.dark-blue) {
  .vertical-accordion {
    background-color: variables.$projectDarkBlue;
    color: white;

    a {
      color: white;
    }
  }

  .accordion {
    > li {
      > .toggle-link {
        color: white;

        &:active,
        &:focus {
          background: transparent;
        }

        &:hover {
          background: #334367;
        }

        &::after,
        &::before {
          background: white;
        }
      }
    }
  }
}

:global(.outer-wrap):global(.white-blue) {
  .vertical-accordion {
    background-color: variables.$projectBlue;
    color: white;

    a {
      color: white;
    }

    .accordion {
      > li {
        > .toggle-link {
          color: white;

          &:active,
          &:focus {
            background: transparent;
          }

          &:hover {
            background: #334367;
          }

          &::after,
          &::before {
            background: white;
          }
        }
      }
    }
  }
}

// Navigational Menu
:global(.navigation-manual) .accordion {
  .full-width & {
    padding: 2rem 8rem;
    background: white;
    padding-top: 5rem;
  }

  .toggle-link {
    display: flex;
    text-decoration: none;

    :global(.chevron) {
      &::after {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &[aria-expanded="true"] {
      :global(.chevron) {
        transform: rotate(-90deg);
      }
    }
  }

  :global(.chevron) {
    margin-right: 2rem;
    transform: rotate(90deg);
  }

  .accordion-drop {
    transition: height 0.3s ease 0s;
    overflow: hidden;
    height: 0;
  }

  .content {
    display: block;
  }

  .text-placeholder {
    border-top: 0.1rem solid variables.$projectInteractiveNavy;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;

    > :global(.tertiary) {
      border: none;

      span:last-child {
        margin-right: 1rem;
        border-bottom: 0.2rem solid transparent;
      }

      &:hover,
      &:focus,
      &:active {
        span:last-child {
          border-bottom: 0.2rem solid #fff;
        }
      }
    }

    > :global(.cta) {
      margin-bottom: 1.5rem;
      text-align: left;
      position: relative;
      left: 0;
      bottom: 0;

      .icon-arrow {
        svg {
          top: 1.3rem;
        }

        &::after {
          top: 1.3rem;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Dropdown
:global(.outer-wrap):global(.blue) .vertical-accordion.dropdown {
  .accordion {
    li {
      background: variables.$projectBlue;
      border-color: rgba(250, 250, 250, 0.5);

      .toggle-link {
        background: variables.$projectBlue;
        color: white;

        &:hover {
          background: variables.$projectBlue;
        }

        :global(.chevron) {
          &::after {
            border-color: white;
          }
        }
      }

      .accordion-drop {
        :global(.form-row) {
          &:hover {
            background: variables.$projectDarkBlue;
          }
        }
      }
    }
  }

  :global(.checkbox) {
    :global(.tick) {
      border-color: white;
    }
  }
}

:global(.outer-wrap) .vertical-accordion.dropdown {
  .accordion {
    li {
      margin: 0;
      border: 1px solid rgba(0, 30, 98, 0.5);
      background: variables.$projectBone;

      .toggle-link {
        padding: 1.5rem 3rem 1.5rem 1rem;
        font-weight: 400;
      }

      .accordion-drop {
        padding: 0;

        .content {
          padding-left: 0;

          :global(.form-row) {
            margin: 1rem 0 0;
            padding: 0.75rem 1rem;
            display: flex;

            &:hover {
              background: variables.$projectDarkBone;

              label {
                font-weight: 400;
              }
            }
          }
        }

        :global(.checkbox) {
          width: 100%;

          :global(.tick) {
            top: 0.3rem;
          }
        }
      }
    }
  }
  .toggle-link {
    &::after,
    &::before {
      display: none;
    }

    :global(.chevron) {
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translate(0, -50%);

      &::after {
        width: 100%;
        height: 100%;
        transform: translate(0, -50%) rotate(135deg);
      }
    }

    &[aria-expanded="true"] {
      :global(.chevron) {
        &::after {
          transform: translate(0) rotate(315deg);
        }
      }
    }
  }
}

// Donate Tool
:global(.donate-tool-vertical-accordion) {
  .vertical-accordion {
    background-color: variables.$projectWhite;

    .donate-tool-accordion {
      > li {
        .donate-tool-accordion-drop {
          .content {
            padding: 0;
          }
        }
      }
    }
  }
}

// Programme
:global(.outer-wrap) .vertical-accordion.programme {
  .accordion {
    li {
      min-height: 9.2rem;

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        a {
          &.toggle-link {
            padding: 0;
            min-height: 9.2rem;
            display: flex;

            :global(.tick) {
              background-image: none;
              bottom: 50%;
              transform: translate(0, 50%) rotate(90deg);
              right: 3rem;
            }

            &[aria-expanded="true"] {
              :global(.tick) {
                transform: translate(0, 50%) rotate(270deg);
              }
            }

            .top-section {
              width: 100%;
              display: flex;
              align-items: self-start;
            }
          }

          h4 {
            font-size: 2rem;
            line-height: 1.3;
            padding-right: 4rem;
            color: variables.$projectBlue;
          }
        }
      }

      .accordion-drop {
        .section-row-wrapper {
          .section-row {
            .message-wrapper {
              &.light {
                &:focus,
                &:hover {
                  &::before {
                    background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
                    transform: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

:global(.outer-wrap) .vertical-accordion.programme {
  max-width: 35.5rem;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  background: transparent;

  .accordion {
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.41);
    background: white;
  }

  .compare-option {
    padding: 1.5rem 1.9rem 0.6rem;
    margin-top: -0.2rem;
    border-top: 0.1rem solid variables.$projectLightGrey;
    background-color: variables.$projectWhite;

    :global(.checkbox) {
      .label {
        padding-left: 3.5rem;
        font-size: 1.6rem;
      }
    }
  }

  li {
    &:last-child {
      border: 0;
      margin: 0;

      .top-section {
        position: relative;
        padding: 2rem 2.5rem;

        .padded-wrapper {
          display: flex;
        }
      }

      a {
        &:active,
        &:focus,
        &:hover {
          background: transparent;
          text-decoration: none;
        }

        &.title-link {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &.toggle-link {
          position: relative;
          padding: 0 2rem 0 0;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          &::after,
          &::before {
            display: none;
          }

          :global(.tick) {
            position: absolute;
            right: 0.5rem;
            bottom: 0.5rem;
            display: inline-flex;
            transform: rotate(90deg);
            width: 1rem;
            height: 1.7rem;
          }

          &[aria-expanded="true"] {
            :global(.tick) {
              transform: rotate(270deg);
            }
          }
        }

        h4 {
          font-size: 2.6rem;
          margin: 0;
        }
      }
    }

    .accordion-drop {
      padding: 0 2.5rem;

      .content {
        padding-top: 1.5rem;
        border-top: 1px solid rgba(151, 151, 151, 0.5);
        overflow: visible;
      }

      .description {
        p {
          font-size: 1.6rem;
          margin-bottom: 1.2rem;
        }
      }

      .section-row-wrapper {
        margin: 0 -2.7rem;
        color: white;
        background: variables.$projectBlue;
        padding: 2.3rem 2.7rem;
        display: block;

        .section-row {
          @include variables.section-row;

          .info {
            position: relative;
            padding-right: 3.5rem;
            font-size: 1.2rem;
            padding-top: 0.35rem;
            display: flex;
            flex-direction: column;
          }

          .message-wrapper {
            @include variables.message-popup;
            display: inline-block;
            margin-left: 2rem;
            bottom: -0.5rem;

            &::before {
              background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%237F8EB0%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
            }

            &:focus,
            &:hover {
              &::before {
                background: variables.$lighInfoIcon;
              }
            }

            .message {
              color: variables.$projectBlue;
              width: auto;
              min-width: 10.3rem;
              right: -1rem;
            }
          }
        }

        .button-wrapper {
          display: inline-block;
          margin: 1rem 0 0;
        }
      }
    }
  }
}

:global(.electives) {
  .vertical-accordion {
    background-color: variables.$projectWhite;
    color: variables.$projectInteractiveNavy;
    position: relative;

    .accordion {
      > li {
        border-color: variables.$projectSantaGray;

        .toggle-link {
          &:hover {
            background-color: variables.$projectAthensGray;
          }
        }

        .accordion-drop {
          .content {
            padding: 1.8rem 0 2.4rem;
          }
        }
      }
    }
  }
}

:global(.outer-wrap):global(.blue) .vertical-accordion.programme {
  .accordion {
    .compare-option {
      :global(.checkbox) {
        label {
          color: variables.$projectBlue;
        }
      }
    }

    li {
      .accordion-drop {
        height: 0;
        transition: height 0.3s;

        .description {
          p {
            color: variables.$projectBlue;
          }
        }

        .section-row-wrapper {
          background: variables.$projectBone;
          color: variables.$projectBlue;

          .section-row {
            .info {
              .sub-text {
                color: variables.$projectBlue;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .vertical-accordion {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  :global(.outer-wrap) .vertical-accordion.programme {
    .compare-option {
      padding-bottom: 1.5rem;

      :global(.checkbox) {
        margin-right: 0;
      }
    }
  }
}
// MOBILE
@media #{variables.$mobile} {
  :global(.outer-wrap) .vertical-accordion.programme {
    .compare-option {
      padding-bottom: 1.5rem;

      :global(.checkbox) {
        margin-right: 0;
      }
    }
  }

  .vertical-accordion {
    margin-bottom: 2rem;

    @at-root .white-grey-split & {
      background: variables.$projectWhite;
      margin: 0;
    }

    ul {
      &.accordion {
        margin: 0 -2rem;
      }
    }

    &.programme {
      ul {
        &.accordion {
          margin: 0 -1rem;
        }
      }
    }
  }

  :global(.outer-wrap) .vertical-accordion.dropdown {
    max-width: none;
    margin-bottom: 4rem;
    max-height: none;

    ul {
      li {
        .accordion-drop {
          .content {
            .form-row {
              max-width: none;
            }
          }
        }
      }
    }
  }

  :global(.outer-wrap) .vertical-accordion.programme {
    margin: 0 0 1.4rem;
    max-width: none;
  }

  :global(.electives) {
    .vertical-accordion {
      margin-bottom: 0;

      > li {
        > .toggle-link {
          font-weight: 500;
        }
      }
    }
  }

  // Donate Tool
  :global(.donate-tool-vertical-accordion) {
    .vertical-accordion {
      margin-bottom: 0;

      .accordion {
        margin: 0;
      }
    }
  }
}
