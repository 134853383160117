@use "../../styles/variables";

.image-carousel {
  margin-left: -2rem;
  margin-bottom: 35rem;
  width: calc(100% + 4rem);
  color: #fff;

  > .block-container {
    display: flex;
    max-width: 1445px;
    margin: 0 auto;

    h2 {
      margin-bottom: 5rem;
    }

    .text-content {
      width: 85%;
      background-color: #001e62;
      padding: 4rem 2rem 35rem 15rem;
      position: relative;
      transform: translateY(2.5rem);
      transition: transform 1s;

      p {
        margin-right: 40%;
      }
    }

    .label {
      color: #9b9baf;
      margin-bottom: 2rem;
    }
  }

  > .wrapper {
    min-height: 600px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, -50%);
    max-width: 1445px;
    overflow: hidden;
  }

  .image-carousel {
    &-next,
    &-prev {
      position: absolute;
      top: 50%;
      z-index: 2;
      margin-top: -3rem;
    }

    &-next {
      right: 0;
      transform: translateY(-50%);
    }
    &-prev {
      transform: translateY(-50%);
    }
  }

  :global(.swiper-wrapper) {
    align-items: center;
  }

  :global(.swiper-slide) {
    width: max-content;
  }

  :global(.swiper-slide) img {
    width: 100%;
    height: 550px;
    object-fit: contain;
  }
}

// MOBILE
@media #{variables.$mobile} {
  .image-carousel {
    margin-bottom: 25rem;

    > .block-container {
      display: block;

      h2 {
        margin-bottom: 1.5rem;
      }

      .text-content {
        width: auto;
        padding: 4rem 0 25rem 4rem;

        .label {
          margin-bottom: 1.5rem;
        }

        p {
          margin-right: auto;
        }
      }
    }

    > .wrapper {
      padding-top: 10rem;
    }

    :global(.swiper-slide) img {
      height: 300px;
    }
  }
}
