@use "../../styles/variables";

.responsive-image {
  margin: 4rem 0;

  img {
    width: 100%;
    height: auto;
  }
}

:global(.wrapper-wide) {
  .responsive-image {
    margin: 0 -2rem;
    width: 100vw;

    img {
      width: 100%;
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .responsive-image {
    margin-bottom: 2rem;
  }
}
