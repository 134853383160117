@use "../../styles/variables.scss";

.flex {
  display: flex;
  width: auto;
  justify-content: center;
  padding: 12px 32px !important;
  font-size: 16px;
  position: relative;
  margin-bottom: 40px;

  @media #{variables.$mobile} {
    width: 100% !important;
  }
}

.spinner {
  position: absolute;
  left: 5px;
  top: 14px;
}

.disabled {
  background: #bcc0cb;
}
