@use "../../styles/variables.scss";

.search-input {
  background-color: variables.$projectBlue;
  margin: 0 -2rem;

  > .wrapper {
  }

  .magic-box {
    border: 0 !important;

    .magic-box-input {
      height: 80px;
      border-radius: 0 !important;
    }

    .magic-box-clear {
      transform: translateY(-50%);
      top: 50%;

      &-svg {
        g {
          fill: variables.$projectBlue;
        }
      }
    }
  }

  .CoveoSearchInterface {
    padding-top: 0;
  }

  .CoveoSearchButton {
    height: 80px;
    width: 100px;
    background: white;
    border: 0;
    border-radius: 0 !important;

    .coveo-search-button-svg {
      width: 30px;
      height: 30px;

      g {
        fill: variables.$projectBlue;
      }
    }

    &:hover,
    &:focus {
      .coveo-search-button-svg {
        g {
          fill: #7f8eb0;
        }
      }

      .coveo-magnifier-circle-svg {
        fill: #7f8eb0;
      }
    }
  }

  .magic-box .magic-box-input .magic-box-underlay,
  .magic-box .magic-box-input > input {
    padding: 2rem 2rem;
    border-radius: 0;
    height: auto;
    color: variables.$projectBlue;
    font-family: var(--lbs-base-font-family), sans-serif;
    font-size: 17px;
    border: 0;
  }

  .base7 {
    position: relative;
    height: 80px;

    &::after {
      content: "";
      position: absolute;
      width: 40rem;
      background-color: white;
      height: 100%;
      right: 0;
      top: 0;
      transform: translateX(100%) translateX(-2rem);
    }
  }

  p {
    color: white;
    padding: 2.8rem 0 0;
  }

  .CoveoSearchInterface .coveo-main-section .coveo-search-section {
    margin: 0;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .search-input {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet} {
  .search-input {
    p {
      color: white;
      padding: 3.5rem 2rem 0;
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .search-input {
    p {
      padding: 3.5rem 2rem 2.5rem;
      margin: 0;
    }

    .CoveoSearchInterface {
      padding: 0 2rem 4rem;

      .CoveoSearchButton {
        height: 80px;
        width: 100px;
        background: white;
        border: 0;

        .coveo-search-button-svg {
          width: 30px;
          height: 30px;

          g {
            fill: variables.$projectBlue;
          }
        }

        &:hover,
        &:focus {
          .coveo-search-button-svg {
            g {
              fill: #7f8eb0;
            }
          }

          .coveo-magnifier-circle-svg {
            fill: #7f8eb0;
          }
        }
      }

      .magic-box {
        border: 0;

        .magic-box-input {
          height: 80px;
          border-radius: 0;
        }
      }

      .magic-box .magic-box-input .magic-box-underlay,
      .magic-box .magic-box-input > input {
        padding: 2rem 2rem;
        border-radius: 0;
        height: auto;
        color: variables.$projectBlue;
        font-family: var(--lbs-base-font-family), sans-serif;
        font-size: 17px;
      }
    }
    .CoveoSearchInterface .magic-box .magic-box-input .magic-box-underlay,
    .coveo-search-section .magic-box .magic-box-input > input {
      padding: 1.5rem 1rem;
    }

    .CoveoSearchInterface .magic-box .magic-box-input {
      height: 70px;
    }

    .CoveoSearchInterface .CoveoSearchButton {
      height: 70px;
      border-radius: 0;
    }

    .base7 {
      &::after {
        content: none;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .search-input {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .search-input {
  }
}
