@use "../../styles/variables.scss";
// COMPONENT - Download Brochure Form

// DEFAULT CLASS - .brochure-form

// CLASS VARIANTS -

// WIDE WEB
.component {
  margin-top: 4rem;
}

.failure-section {
  margin: 5rem 0;
}

.back {
  max-width: 300px;
}

.spinner {
  display: flex;
  justify-content: center;
  max-width: 66.66667%;
  margin-bottom: 50px;
}

.introLoggedInText {
  line-height: 1.5;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.formTitle {
  text-size-adjust: 100%;
  letter-spacing: 0;
  text-align: left;
  color: #001e62;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-family: var(--lbs-heading-font-family), serif;
  font-weight: 400;
  line-height: 1.25;
  font-size: 6rem;
  margin-bottom: 2.4rem;
  margin-top: -6rem;
}

.riRedBulletListTitle {
  text-size-adjust: 100%;
  letter-spacing: 0;
  text-align: left;
  color: #001e62;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.riRedBulletList {
  list-style-type: none;
  margin-top: 2rem;
  margin-left: 2rem;
  padding-left: 0;
}

.riRedBulletListItem {
  position: relative;
  text-size-adjust: 100%;
  letter-spacing: 0;
  line-height: 1.5;
  font-size: 1.6rem;
  color: #002060;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
}

.riRedBulletListItem::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 2px solid #c8103e;
  border-radius: 50%;
  left: -1.4rem;
  top: 0.5rem;
}

.brochure-form {
  .fields-floated {
    .field-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.6rem;

      label {
        flex-basis: 25%;
        max-width: 25%;
        color: variables.$projectBlue;
        font-family: var(--lbs-base-font-family), sans-serif;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 700;
        padding-right: 1rem;

        + p {
          flex-basis: 75%;
          max-width: 75%;
          margin-bottom: 0;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .brochure-form {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .brochure-form {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .brochure-form {
    .fields-floated {
      .field-row {
        flex-direction: column;

        label {
          flex-basis: 100%;
          max-width: 100%;

          + p {
            flex-basis: 100%;
            max-width: 100%;
          }
        }

        &.edit-details {
          a {
            width: 100%;
          }
        }
      }
    }
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
    .brochure-form {
    }
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
    .brochure-form {
    }
  }
}
