@import "../../styles/variables.scss";

.wrapper {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.promotional-articles {
  padding: 12rem 0 8rem;

  margin: 50px 1px !important;

  .container {
    display: flex;

    article {
      margin: 0 1.9rem;

      > a {
        display: block;
        overflow: hidden;
      }

      picture {
        img {
          transition: transform 0.3s;

          &:hover,
          &:focus {
            transform: scale(1.1);
          }
        }
      }

      .label {
        margin: 2rem 0 1rem;
      }

      h4 {
        margin-bottom: 1rem;

        a {
          text-decoration: none;

          &:hover,
          &:focus {
            background-image: linear-gradient(
              90deg,
              $projectBlue,
              $projectBlue
            );
            background-repeat: no-repeat;
            background-position: 0 100%;
            background-size: 100% 0.1rem;
            padding-bottom: 0.1rem;
          }
        }
      }

      p {
        margin-bottom: 2rem;
      }

      &:first-child,
      &[id$="item0"] {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.hover {
        h4 {
          a {
            background-image: linear-gradient(
              90deg,
              $projectBlue,
              $projectBlue
            );
            background-repeat: no-repeat;
            background-position: 0 100%;
            background-size: 100% 0.1rem;
            padding-bottom: 0.1rem;
          }
        }

        .cta {
          &.secondary {
            color: white;
            background-color: #001e62;

            &.light {
              color: #001e62;
              background-color: white;
            }
          }

          &.tertiary {
            &:not(.cal) {
              span {
                &.icon-arrow {
                  svg {
                    left: 0.5rem;
                  }

                  &::after {
                    position: absolute;
                    left: 0;
                    top: calc(50% - 0.1rem);
                    transform: translateY(-50%);
                    content: "";
                    width: 0.5rem;
                    height: 0.2rem;
                    background-color: $projectBlue;
                  }
                }

                &:last-child {
                  transform: none;
                  margin-right: 0;
                  margin-left: 0;
                  background-image: linear-gradient(
                    90deg,
                    $projectBlue,
                    $projectBlue
                  );
                  background-repeat: no-repeat;
                  background-position: 0 100%;
                  background-size: 100% 0.1rem;
                }
              }
            }
          }
        }
      }
    }
  }

  &[data-items="3"] {
    article {
      width: 33.3%;

      h4 {
        font-size: 3rem;
      }

      &[id$="item2"] {
        margin-right: 0;
      }
    }
  }

  &[data-items="2"] {
    article {
      width: 50%;

      &[id$="item1"] {
        margin-right: 0;
      }
    }
  }

  &.dark {
    margin: 0 -2rem;
    padding: 12rem 2rem 8rem;
    background-color: $projectBone;
  }

  &.solo {
    padding: 4rem 0 19rem;

    article {
      width: 100%;
      display: flex;

      > a {
        min-width: 77.7%;
        flex-shrink: 0;
        max-width: 77.7%;

        picture {
          width: 100%;
          height: 100%;

          img {
            height: 100%;
          }
        }
      }

      .text {
        transform: translate(-61%, 25rem);
        position: relative;
        flex-shrink: 0;
        width: 57%;
        background-color: $projectBlue;
        color: white;
        padding: 6rem 10rem;
        transition: transform 1s;

        .line {
          position: absolute;
          width: 0;
          height: 0.5rem;
          background-color: $projectRed;
          z-index: 2;
          transform: translateX(-100%) translateX(-3rem) translateY(8rem);
          transition: width 1s;
          transition-delay: 0.5s;
        }

        .label {
          color: #9b9baf;
        }

        h4 {
          @include line-height(60, 48);
          font-size: 4.8rem;

          a {
            color: white;

            &:hover,
            &:focus {
              background-image: linear-gradient(90deg, white, white);
            }
          }
        }

        .cta {
          width: auto;
          margin-top: 2rem;
        }
      }

      &.hover {
        h4 {
          a {
            background-image: linear-gradient(90deg, white, white);
            background-repeat: no-repeat;
            background-position: 0 100%;
            background-size: 100% 0.1rem;
            padding-bottom: 0.1rem;
          }
        }
      }
    }

    &.animate {
      article {
        .text {
          transform: translate(-61%, 13rem);
        }

        .line {
          width: 13rem;
        }
      }
    }

    &.dark {
      padding: 12rem 2rem 19rem;
    }

    &.reverse {
      article {
        flex-direction: row-reverse;

        .text {
          transform: translate(61%, 25rem);

          .line {
            transition: width 1s, transform 1s;
            transform: translateX(-6.5rem) translateY(8rem);
            right: 0;
          }
        }
      }

      &.animate {
        article {
          .text {
            transform: translate(61%, 11rem);

            .line {
              transform: translate(6.5rem, 8rem);
            }
          }
        }
      }

      &.small-width {
        article {
          .text {
            transform: translate(61%, 11rem);
          }

          .line {
            transition-delay: 0.5s;
          }
        }
      }
    }

    &.wide {
      padding: 12rem 0 6rem;
      margin-top: 8rem;
      position: relative;
      overflow: hidden;

      .container {
        padding-top: 24.5%;
      }

      article {
        > a {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          max-width: 100%;
        }

        picture {
          img {
            width: 100%;
          }
        }

        .text {
          transform: translate(75.4%, 0);
        }
      }

      &.reverse {
        article {
          .text {
            transform: translate(-75.4%, 0);
          }
        }
      }

      &.dark {
        padding: 12rem 2rem 6rem;
      }
    }

    &.alt {
      article {
        a {
          min-width: 48.3%;
          z-index: 1;

          picture {
            width: 100%;
          }
        }

        .text {
          width: 77.7%;
          background-color: transparent;
          // border: 5px solid $projectRed;
          transform: translate(-33.4%, 3rem);
          padding: 6rem 8rem 6rem 28.5%;
          color: $projectBlue;
          position: relative;

          &::after,
          &::before {
            content: "";
            position: absolute;
            border: 5px solid $projectRed;
            width: 100%;
            height: 100%;
            pointer-events: none;
          }

          h4,
          p,
          .cta {
            overflow: hidden;
            transition: opacity 1s;
            transition-delay: 0.5s;
          }

          .label {
            color: #6e6259;
          }

          h4 {
            a {
              color: $projectBlue;

              &:hover,
              &:focus {
                background-image: linear-gradient(
                  90deg,
                  $projectBlue,
                  $projectBlue
                );
              }
            }
          }
        }
      }

      &.animate {
        article {
          .text {
            h4,
            p,
            .cta {
              opacity: 1;
            }
          }
        }
      }

      &:not(.reverse) {
        .text {
          &::before {
            top: 0;
            left: 0;
          }

          // And this the bottom & left borders (expands left, then up)
          &::after {
            bottom: 0;
            right: 0;
          }
        }

        &.animate {
          article {
            .text {
              transform: translate(-33.4%, 11rem);
            }
          }

          .text {
            &::before,
            &::after {
              width: 100%;
              height: 100%;
            }

            &::before {
              border-top-color: $projectRed; // Make borders visible
              border-right-color: $projectRed;
              transition: width 0.15s linear,
                // Width expands first
                height 0.15s linear 0.15s; // And then height
            }

            &::after {
              border-bottom-color: $projectRed; // Make borders visible
              border-left-color: $projectRed;
              transition: border-color 0s ease-out 0.3s,
                // Wait for ::before to finish before showing border
                width 0.15s linear 0.3s,
                // And then exanding width
                height 0.15s linear 0.45s; // And finally height
            }
          }
        }
      }

      &.reverse {
        article {
          .text {
            transform: translate(33.4%, 3rem);
            padding: 6rem 28.5% 6rem 8rem;

            &::before {
              top: 0;
              right: 0;
            }

            // And this the bottom & left borders (expands left, then up)
            &::after {
              bottom: 0;
              left: 0;
            }
          }
        }

        &.animate {
          article {
            .text {
              transform: translate(33.4%, 11rem);

              &::before,
              &::after {
                width: 100%;
                height: 100%;
              }

              &::before {
                border-bottom-color: $projectRed; // Make borders visible
                border-right-color: $projectRed;
                transition: height 0.15s linear,
                  // Width expands first
                  width 0.15s linear 0.15s; // And then height
              }

              &::after {
                border-top-color: $projectRed; // Make borders visible
                border-left-color: $projectRed;
                transition: border-color 0s ease-out 0.3s,
                  // Wait for ::before to finish before showing border
                  height 0.15s linear 0.3s,
                  // And then exanding width
                  width 0.15s linear 0.45s; // And finally height
              }
            }
          }
        }
      }
    }

    &.white {
      article {
        .text {
          background-color: white;
          color: $projectBlue;

          h4 {
            a {
              color: $projectBlue;
            }
          }
        }
      }
    }

    &.small-width {
      padding-top: 4rem;
    }
  }

  .tns-outer {
    display: flex;
    flex-direction: column-reverse;

    .tns-nav {
      margin-top: 2rem;
      text-align: center;
      width: 100%;

      button {
        position: relative;
        width: 1.2em;
        height: 1.2rem;
        padding: 1rem;
        background: none;
        border: 0;
        pointer-events: none;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: 1px solid $projectBlue;
          background-color: transparent;
          width: 1.2em;
          height: 1.2rem;
          padding: 0;
        }

        &.tns-nav-active {
          &::after {
            background-color: $projectBlue;
          }
        }
      }
    }
  }

  &.blue {
    &:not(.solo) {
      margin: 0 -2rem;
      padding: 6rem 2rem 8rem;
      background-color: $projectBlue;
      color: white;

      p {
        &.label {
          color: $projectInteractiveNavy;
        }
      }

      h4 {
        a {
          color: white;

          &:hover,
          &:focus {
            background-image: linear-gradient(90deg, white, white);
          }
        }
      }

      .cta {
        // sass-lint:disable placeholder-in-extend
        // @extend .cta.light;
      }

      .tns-outer {
        .tns-nav {
          button {
            &::after {
              border: 1px solid white;
            }

            &.tns-nav-active {
              &::after {
                background: white;
              }
            }
          }
        }
      }
    }
  }

  &.small-width {
    padding: 12rem 0 10rem;

    article {
      width: 100%;
      display: flex;

      > a {
        picture {
          width: auto;
          height: auto;
          overflow: hidden;

          img {
            height: auto;
          }
        }
      }

      .text {
        transform: translate(-61%, 13rem);
        padding: 5.6rem 6rem;

        .line {
          transform: translateX(-100%) translateX(-2rem) translateY(8rem);
        }

        h4 {
          @include line-height(38, 30);
          font-size: 3rem;
        }

        .cta {
          &.secondary {
            display: inline-block;
          }

          &.tertiary {
            display: none;
            color: $projectWhite;

            &:focus,
            &:hover {
              span {
                &:last-child {
                  background-image: linear-gradient(
                    90deg,
                    $projectWhite,
                    $projectWhite
                  );
                }
              }
            }

            svg {
              fill: $projectWhite;
            }
          }
        }
      }
    }

    &.animate {
      article {
        .text {
          transform: translate(-61%, 7rem);
        }

        .line {
          width: 8rem;
        }
      }
    }

    &.reverse {
      article {
        .text {
          transform: translate(61%, 7rem);
        }
      }

      &.animate {
        article {
          .text {
            transform: translate(61%, 7rem);

            .line {
              transform: translate(4.5rem, 8rem);
            }
          }
        }
      }
    }

    &.white {
      article {
        .text {
          h4 {
            a {
              &:hover,
              &:focus {
                background-image: linear-gradient(
                  90deg,
                  $projectBlue,
                  $projectBlue
                );
              }
            }
          }

          .cta {
            &.tertiary {
              color: $projectBlue;

              &:focus,
              &:hover {
                span {
                  &:last-child {
                    background-image: linear-gradient(
                      90deg,
                      $projectBlue,
                      $projectBlue
                    );
                  }
                }
              }

              svg {
                fill: $projectBlue;
              }
            }
          }
        }
      }
    }
  }
}

.base4 > {
  .promotional-articles {
    padding: 0;
  }
}

// COMPACT WEB
@media #{$compact-web} {
  .promotional-articles {
    &.solo {
      &.wide {
        .container {
          padding-top: 15.5%;
        }
      }
    }
  }
}

// TABLET PORTRAIT
@media #{$tablet} {
  .promotional-articles {
    &.solo {
      article {
        .text {
          transform: translate(-100%, 25rem);

          h4 {
            @include line-height(40, 28);
            font-size: 2.8rem;
          }
        }

        > a {
          max-width: 100%;
          width: 100%;

          picture {
            width: 100%;

            img {
              width: 100%;
            }
          }
        }
      }

      .text {
        transform: translate(-100%, 25rem);

        h4 {
          @include line-height(40, 28);
          font-size: 2.8rem;
        }
      }

      &.animate {
        article {
          .text {
            transform: translate(-100%, 11rem);
          }
        }
      }

      &.reverse {
        article {
          .text {
            transform: translate(100%, 25rem);
          }
        }

        &.animate {
          article {
            .text {
              transform: translate(100%, 11rem);
            }
          }
        }
      }

      &.wide {
        .container {
          padding-top: 12.5%;
        }

        &.animate {
          article {
            .text {
              transform: translate(75.4%, 0);
            }
          }
        }

        &.reverse {
          &.animate {
            article {
              .text {
                transform: translate(-75.4%, 0);
              }
            }
          }
        }
      }
    }
  }
}

// MOBILE
@media #{$mobile} {
  .promotional-articles {
    padding: 4rem 0;

    // sass-lint:disable no-important
    .tns-item {
      padding-right: 1rem !important;
    }

    img {
      width: 100%;
    }

    .container {
      // flex-direction: column;
      display: block;

      article {
        margin: 0;
        width: 100%;
      }
    }

    &.dark {
      padding: 0 2rem;
      margin: 4rem 0;
    }

    &[data-items="3"] {
      margin: 0 -2rem;
      padding: 4rem 0 4rem 1rem;

      article {
        h4 {
          font-size: 2.8rem;
        }
      }
    }

    &.solo {
      margin: 0 -2rem 2.4rem;
      padding: 0;

      article {
        > a {
          max-width: 100%;
        }
      }

      .container {
        article {
          display: block;

          picture {
            width: 100%;
          }

          .text {
            padding: 2rem;
            transform: none;
            width: 100%;

            .line {
              transform: translate(2.1rem, -9.4rem);
              left: 0;
              width: 0.3rem;
              height: 11rem;
            }

            h4,
            p {
              // margin-left: 2.5rem;
            }

            h4 {
              @include line-height(40, 28);
              font-size: 2.8rem;
            }

            p {
              &.label {
                margin-top: 0;
                margin-left: 2rem;
              }

              + p {
                margin-bottom: 3rem;
              }
            }

            .cta {
              margin-bottom: 2rem;
              width: 100%;

              &.tertiary {
                width: auto;
                margin-left: 2.5rem;
              }
            }
          }
        }
      }

      &.reverse {
        .container {
          article {
            .text {
              .line {
                transform: translate(2.1rem, -9.4rem);
              }
            }
          }
        }
      }

      &.dark {
        padding: 0;
      }

      &.wide {
        margin-top: 4rem;

        .container {
          padding-top: 0;

          article {
            > a {
              position: static;
            }

            picture {
              position: static;
            }
          }
        }

        &.reverse {
          article {
            .text {
              transform: none;
            }
          }
        }

        &.dark {
          padding: 2rem 0;
        }
      }

      &.alt {
        article {
          .text {
            p,
            h4 {
              opacity: 1;
              margin-left: 0;

              &.label {
                margin-left: 0;
              }
            }

            .cta {
              opacity: 1;
            }

            &::before,
            &::after {
              border-color: transparent;
            }
          }
        }

        &.reverse {
          article {
            .text {
              transform: none;
              padding: 2rem;
            }
          }
        }

        &.animate {
          &.reverse,
          &:not(.reverse) {
            article {
              .text {
                transform: none;
              }
            }
          }
        }
      }

      &.animate {
        &.reverse {
          article {
            .text {
              transform: none;
            }
          }
        }
      }
    }

    &[data-items="2"] {
      article {
        &:first-child {
          border-bottom: 1px solid $projectBlue;
          padding-bottom: 2rem;
          margin-bottom: 4rem;
        }
      }
    }

    &.small-width {
      .container {
        article {
          .text {
            padding: 4.2rem 2.1rem 2.1rem;

            .line {
              transform: translate(2.1rem, -7.4rem);
              height: 7rem;
            }

            h4 {
              @include line-height(36, 28);
            }

            .cta {
              &.secondary {
                display: none;
              }

              &.tertiary {
                display: inline-block;
                margin-left: 0;
              }
            }
          }
        }
      }

      &.reverse {
        .container {
          article {
            .text {
              .line {
                transform: translate(2.1rem, -7.4rem);
              }
            }
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .promotional-articles {
  }
}

// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .promotional-articles {
  }
}
