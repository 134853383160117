@use "../../styles/variables.scss";

.phd-form {
  padding: 0 2rem 3.4rem 2rem;
  margin-bottom: 3.9rem;
  margin-top: 3.4rem;
  border-bottom: 0.1rem solid variables.$projectLightGrey;

  a {
    width: 22.1rem;
  }
}
