@use "../../styles/variables.scss";

.promotional-related-content {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.two {
    .article-panel,
    .promo-article-news,
    .promo-events {
      width: calc(50% - 2rem);
    }
  }
}

.base4 {
  .promotional-related-content {
    .wrapper {
      flex-direction: column;
    }

    .article-panel,
    .promo-article-news,
    .promo-events {
      width: 100%;
    }
  }
}

@media #{variables.$tablet-portrait} {
  .promotional-related-content {
  }
}

@media #{variables.$mobile} {
  .promotional-related-content {
    .wrapper {
      flex-direction: column;
    }

    &.two {
      .article-panel,
      .promo-article-news,
      .promo-events {
        width: 100%;
      }
    }
  }
}

@media #{variables.$mobile-landscape} {
  .promotional-related-content {
  }
}

@media #{variables.$mobile-portrait} {
  .promotional-related-content {
  }
}

// events

.promotional-related-content {
  .promo-events {
    width: calc(33.3% - 2rem);

    @media #{variables.$mobile} {
      width: 100%;
    }
  }
}
