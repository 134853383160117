@use "../../styles/variables";

// COMPONENT - C15 Social sharing
// DEFAULT CLASS - .social-sharing
// CLASS VARIANTS -
// WIDE WEB

.social-sharing {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.9rem 0;

  .icon {
    display: inline-flex;
    opacity: 1;
    width: 4rem;
    height: 4rem;
    margin-left: 1rem;
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    & img {
      max-width: 100%;
      display: block;
    }
  }

  &.small {
    .icon {
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .social-links {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    li {
      margin-bottom: 0;
      display: inline-flex;

      &::before {
        display: none;
      }

      &:first-child a {
        margin-left: 0;
      }
    }
  }
  svg {
    fill: variables.$projectBlue;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .social-sharing {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .social-sharing {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .social-sharing {
    &.mobile {
      padding-top: 2.3rem;
      padding-left: 0;
      justify-content: flex-start;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .social-sharing {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .social-sharing {
  }
}
