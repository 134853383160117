@use "../../styles/variables";

.socialshare {
  > .wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0;
    min-height: 4.4rem;
  }

  .social-sharing {
    padding: 0;

    > a {
      color: variables.$projectBlue;
      font-size: 1.2rem;
    }

    .social-links {
      li {
        a {
          width: 2.3rem;

          img,
          .icon {
            width: 100%;
            height: auto;
            align-self: center;
            opacity: 1;
          }
        }
      }
    }
  }

  .breadcrumb {
    display: flex;
    align-items: center;

    .breadcrumb-links {
      padding: 0;
    }
  }

  &.blue {
    background: variables.$projectDarkBlue;
    color: white;

    a {
      color: white;
    }

    .social-sharing {
      > a {
        color: #7f82b0;
      }
    }

    .breadcrumb-links {
      li {
        a {
          color: white;
        }

        &::after {
          background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22\a%20%20%20viewBox%3D%220%200%206%209.5%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M1.2%209.3C1%209.3.7%209.2.5%209c-.4-.4-.4-1%200-1.4l2.8-2.8L.5%201.9c-.2-.2-.3-.4-.3-.7C.2.9.3.7.5.5c.2-.2.5-.3.7-.3.3%200%20.5.1.7.3L5.5%204c.4.4.4%201%200%201.4L1.9%209c-.1.2-.4.3-.7.3z%22%2F%3E\a%3C%2Fsvg%3E")
            no-repeat;
        }
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .socialshare {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .socialshare {
  }
}
// MOBILE
@media #{variables.$mobile} {
  .socialshare {
    > .wrapper {
      .social-sharing {
        flex-direction: column;

        > a {
          margin-left: 0;
          display: block;
          margin-top: 1rem;
        }

        .social-links {
          display: flex;
          align-self: flex-end;
        }
      }

      .breadcrumb {
        .breadcrumb-links {
          li {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .socialshare {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .socialshare {
  }
}

.social-sharing {
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  svg {
    fill: variables.$projectBlue;
  }
}

.social-share {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 30px;

  .visually-hidden {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: 0.1rem;
    height: 0.1rem;
    overflow: hidden;
  }

  i {
    svg {
      fill: variables.$projectBlue;
      height: 100%;
      transition: all 0.2s linear;
      width: 100%;
    }
  }

  a {
    @media (hover: hover) {
      &:hover {
        i {
          svg {
            fill: #5f739b;
          }
        }
      }
    }
  }

  .share-links {
    align-items: center;
    background: variables.$projectWhite;
    bottom: calc(100% + 1.5rem);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
    display: none;
    justify-content: space-between;
    padding: 1rem;
    position: absolute;
    right: -0.4rem;
    width: 13rem;
    z-index: 2;

    &::before {
      background: variables.$projectWhite;
      bottom: -7px;
      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
      display: block;
      content: " ";
      height: 18px;
      position: absolute;
      pointer-events: none;
      right: 10px;
      transform: rotate(45deg);
      width: 18px;
    }

    &::after {
      background: variables.$projectWhite;
      bottom: 0;
      content: " ";
      display: block;
      height: 25px;
      pointer-events: none;
      position: absolute;
      right: 0;
      width: 40px;
    }

    li {
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      height: 3rem;
      width: 3rem;
      z-index: 3;

      a {
        display: block;
        height: 3rem;
        width: 3rem;
      }

      i {
        display: block;
        height: 3rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 3rem;

        &.icon-facebook {
          height: 3.5rem;
          left: -0.2rem;
          width: 3.5rem;
          top: -0.3rem;
        }
      }
    }
  }

  &.show {
    .share-toggle {
      svg {
        fill: #5f739b;
        transform: rotate(90deg);
      }
    }

    .share-links {
      display: flex;
    }
  }
}

.social-sharing {
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  svg {
    fill: variables.$projectBlue;
  }
}

.social-sharing {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0;
}

.social-sharing > a {
  color: #001e62;
  font-size: 1.6rem;
  text-decoration: none;
  margin-left: 2rem;
}

.social-sharing > a:hover {
  text-decoration: underline;
}

.social-sharing .social-links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.social-sharing .social-links li {
  margin-bottom: 0;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.social-sharing .social-links li:first-child a {
  margin-left: 0;
}

.social-sharing .social-links li a {
  margin-left: 1rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  opacity: 1;
  padding: 1rem 0;
}

.social-sharing .social-links li a:hover {
  opacity: 0.8;
}

.social-sharing .social-links li a svg {
  width: 4.2rem;
  height: 4.2rem;
}

.breadcrumb {
  .breadcrumb-links {
    padding: 1rem 0;

    li {
      display: inline-block;
      position: relative;
      padding-left: 3.4rem;
      font-size: 1.2rem;
      margin: 0;

      a {
        text-decoration: none;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }

      &:first-child {
        padding-left: 0;

        &::after {
          display: none;
        }
      }

      &::after {
        background: url("data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%206%209.5%22%20focusable%3D%22false%22%3E%3Cpath%20d%3D%22M1.2%209.3C1%209.3.7%209.2.5%209c-.4-.4-.4-1%200-1.4l2.8-2.8L.5%201.9c-.2-.2-.3-.4-.3-.7C.2.9.3.7.5.5c.2-.2.5-.3.7-.3.3%200%20.5.1.7.3L5.5%204c.4.4.4%201%200%201.4L1.9%209c-.1.2-.4.3-.7.3z%22%2F%3E%3C%2Fsvg%3E")
          no-repeat;
        height: 1rem;
        width: 0.8rem;
        top: 50%;
        left: 1.4rem;
        position: absolute;
        content: "";
        transform: translateY(-50%);
      }
    }
  }

  &.blue {
    background: variables.$projectBlue;
    color: white;
    margin: 0 -2rem;
    padding: 0 2rem;

    &.dark {
      background: variables.$projectDarkBlue;
    }

    a {
      color: white;
    }

    li {
      &::after {
        background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22\a%20%20%20viewBox%3D%220%200%206%209.5%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M1.2%209.3C1%209.3.7%209.2.5%209c-.4-.4-.4-1%200-1.4l2.8-2.8L.5%201.9c-.2-.2-.3-.4-.3-.7C.2.9.3.7.5.5c.2-.2.5-.3.7-.3.3%200%20.5.1.7.3L5.5%204c.4.4.4%201%200%201.4L1.9%209c-.1.2-.4.3-.7.3z%22%2F%3E\a%3C%2Fsvg%3E")
          no-repeat;
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .breadcrumb {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .breadcrumb {
  }
}
// MOBILE
@media #{variables.$mobile} {
  .breadcrumb {
    .breadcrumb-links {
      li {
        display: none;
        padding-left: 2rem;

        &:nth-last-child(2) {
          display: inline-block;

          &::before {
            content: attr(data-back);
            display: inline-block;
          }

          &::after {
            transform: translateY(-50%) rotate(180deg);
            left: 0;
          }
        }
      }
    }
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .breadcrumb {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .breadcrumb {
  }
}
