@use "../../styles/variables";

// COMPONENT - C03 Sub navigation
// DEFAULT CLASS - .sub-navigation
// CLASS VARIANTS -
// WIDE WEB

.animated-numbers {
  margin: 8rem 0;

  > :global(.wrapper) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .item {
    width: 33.333%;
    border-left: 0.5rem solid variables.$projectRed;
    padding-left: 2rem;
    margin-bottom: 4rem;

    .odometer-digit-spacer {
      width: 47.75px;
    }

    p {
      font-size: 1.8rem;
      margin-bottom: 0;

      &.number {
        font-size: 8rem;
        font-weight: normal;
      }

      &.heading {
        font-family: var(--lbs-heading-font-family), serif;
        font-size: 3rem;
        margin-bottom: 1rem;
      }

      .odo {
        display: inline-block;
      }
    }

    &:first-child {
      &:last-child {
        transform: translateX(100%);
      }
    }

    @for $i from 1 through 4 {
      $n: 4 - ($i - 1);

      &:nth-child(#{$i}) {
        &:nth-last-child(#{$n}) {
          width: 34%;
          @media #{variables.$mobile} {
            width: 100%;
          }
        }
      }
    }
  }

  &.solo {
    margin: 0;

    .item {
      width: 100%;

      &:first-child {
        &:last-child {
          transform: none;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .animated-numbers {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet} {
  .animated-numbers {
    .item {
      p {
        &.number {
          font-size: 6rem;
        }
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .animated-numbers {
    > .wrapper {
      flex-direction: column;
    }

    .item {
      width: 100%;
      margin-top: 4rem;
      margin-bottom: 0;

      &:first-child {
        margin-top: 0;

        &:last-child {
          transform: none;
        }
      }
    }

    &.solo {
      margin: 4rem 0;
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .animated-numbers {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .animated-numbers {
  }
}
