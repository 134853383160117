.error-message {
  padding: 20px 0;
  border-top: 1px solid #e2ded9;
  border-bottom: 1px solid #e2ded9;
  padding-left: 30px;
  position: relative;
  margin-bottom: 30px;

  p {
    margin: 0;
  }

  &::before {
    content: "";
    width: 2rem;
    height: 2rem;
    display: inline-block;
    left: 0px;
    top: 21px;

    position: absolute;
    background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
  }
}
