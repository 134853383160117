@use "../../../styles/variables";
// COMPONENT - Success Download Brochure Page

// DEFAULT CLASS - .success-brochure

// CLASS VARIANTS -

// WIDE WEB
.success-common {
  margin: 3rem 0 4rem;

  .section-title {
    margin: 4rem 0;
  }

  .success-text {
    p {
      margin: 2.5rem 0;
    }
  }

  .userLink-section {
    p {
      margin: 2rem 0;
      text-decoration: underline;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .success-common {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .success-common {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .success-common {
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
    .success-common {
    }
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
    .success-common {
    }
  }
}
