@use "../../../styles/variables.scss";
// COMPONENT - C159 Dpo key details

// DEFAULT CLASS - .dpo-key-details

// CLASS VARIANTS -

// WIDE WEB

.dpo-key-details {
  .section-title {
    margin-bottom: 4.8rem;
    padding-top: 4rem;
  }

  .top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.5rem;
    border-bottom: 0.1rem solid variables.$projectLightGrey;
    margin-bottom: 2.3rem;
  }

  .key-details-section {
    p {
      margin-bottom: 2rem;
    }
  }

  .sub-title {
    color: variables.$projectBlue;
    font-family: var(--lbs-heading-font-family), serif;
    font-size: 3.6rem;
    line-height: 4.4rem;
    margin-bottom: 0;
  }

  .outer-wrapper {
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 4rem);
    margin-top: 1.7rem;
    padding-left: -2rem;
    padding-right: -2rem;

    &.blue {
      background-color: variables.$projectBlue;

      p {
        a {
          color: variables.$projectWhite;
          text-decoration: none;
          border-bottom: 0.1rem solid variables.$projectWhite;
          transition:
            color 300ms ease,
            border 300ms ease;

          &:focus,
          &:hover {
            border-bottom: 0.2rem solid variables.$projectWhite;
          }
        }
      }
    }

    .eligibility-section {
      padding: 4.5rem 0 3.2rem;

      .sub-title {
        color: variables.$projectWhite;
        margin-bottom: 1.4rem;
      }

      p {
        color: variables.$projectWhite;
      }

      .cta {
        margin-top: 1.5rem;
        padding-left: 4rem;

        svg {
          width: 2.3rem;
          height: 2.3rem;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .dpo-key-details {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .dpo-key-details {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .dpo-key-details {
    .section-title {
      margin-bottom: 2.1rem;
    }

    .top-section {
      padding-bottom: 1.2rem;
      margin-bottom: 1.6rem;
    }

    .key-details-section {
      p {
        margin-bottom: 2.5rem;
      }
    }

    .sub-title {
      font-size: 2.8rem;
      line-height: 3.6rem;
      margin-bottom: 0;
    }

    .outer-wrapper {
      margin-top: 0.5rem;

      .eligibility-section {
        padding: 3.9rem 2rem 2.4rem;

        .sub-title {
          margin-bottom: 1.4rem;
        }

        .cta {
          margin-top: 0.8rem;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .dpo-key-details {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .dpo-key-details {
  }
}
